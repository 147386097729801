import Http from '@/utils/http'
import type { ICartAddItem, ICartPayOrder } from '../types/Cart'

export class CartApi {
  public getCart() {
    return Http.get('/cart')
  }

  public addCart(params: Array<ICartAddItem>) {
    return Http.post('/cart/add', params)
  }

  public clearCart() {
    return Http.post(`/cart/clear`)
  }

  public payCart(params: ICartPayOrder) {
    return Http.post('/cart/pay', params)
  }
}
