import { defineStore } from 'pinia'
import api from '@/utils/api/user'
import type { ISSOLoginData, IAuthToken, IUserWarranty, IProfile, IUserRepair, IUserOrder, IUserOrderFilter, IEvent, IFilter, IUserRepairsFilter } from '@/utils/types'

interface StoreState {
  isLogin: boolean
  token: IAuthToken | null
  warranties: Array<IUserWarranty>
  profile: IProfile
  repairs: Array<IUserRepair>
  orders: Array<IUserOrder>
  order: IUserOrder
  favoriteEvents: Array<IEvent>
  refundForm: {
    accept: boolean
    reason: Array<string>
    otherReason: string
  }
  paymentTypeDisplay: { [key: string]: string }
}

export const useUserStore = defineStore('userStore', {
  state: (): StoreState => {
    return {
      isLogin: false,
      token: null,
      warranties: [],
      profile: {} as IProfile,
      repairs: [],
      orders: [],
      order: {} as IUserOrder,
      favoriteEvents: [],
      refundForm: {
        accept: false,
        reason: [],
        otherReason: ''
      },
      paymentTypeDisplay: {
        STREET_KOU: '街口支付',
        CREDIT_CARD_ONE_TIME: '信用卡一次付款',
        LINE_PAY: 'LINE Pay',
        FREE_PAYMENT: '免付費'
      }
    }
  },
  actions: {
    async fetchLogin({ outerid, token }: { outerid: string; token: string }): Promise<string> {
      const params = {
        outer_id: outerid,
        access_token: token
      } as ISSOLoginData
      const res = await api.postLogin(params)
      this.token = res.data

      return objectToBase64(res.data)
    },
    async fetchUserWarranties(type?: string, params: IFilter = {}) {
      try {
        const res = await api.getWarranties(type, params)
        this.warranties = res.data
        return res
      } catch (error) {
        console.log('取得保固資訊失敗', error)
      }
    },
    async fetchUserOrders(params: IUserOrderFilter = {}) {
      try {
        const res = await api.getOrders(params)
        this.orders = res.data
        return res
      } catch (error) {
        console.error('取得已報名活動失敗:', error)
      }
    },
    async fetchUserOrder(no: string) {
      try {
        const res = await api.getOrder(no)
        this.order = res.data
        return res.data
      } catch (error) {
        console.log('取得會員訂單資料失敗:', error)
      }
    },
    async fetchUserRepairs(f: IUserRepairsFilter = {}) {
      try {
        const res = await api.getRepairs(f)
        this.repairs = res.data
        return res
      } catch (error) {
        console.error('取得維修紀錄列表失敗:', error)
      }
    },
    async fetchUserRepair(id: string) {
      try {
        const res = await api.getRepair(id)
        return res.data
      } catch (error) {
        console.error('取得維修紀錄失敗:', error)
      }
    },
    async fetchUserProfile() {
      try {
        const res = await api.getProfile()
        console.log(res)
        if (res.code === 200) {
          const base64String = res.data
          this.profile = base64ToObject(base64String)
        } else {
          this.token = null
          this.profile = {} as IProfile
        }
      } catch (error) {
        console.log('取得會員錯誤:', error)
      }
    },
    async fetchUserFavoriteEvents(params: IFilter = {}) {
      try {
        const res = await api.getFavoriteEvent()
        return res
      } catch (error) {
        console.error('取得收藏活動失敗:', error)
      }
    },
    async fetchToggleFavoriteEvent(id: string): Promise<boolean> {
      const res = await api.toggleFavoriteEvent(id)
      return res.code === 200
    },
    async fetchCancelOrder(
      id: string,
      params: {
        reason: Array<string>
      }
    ): Promise<boolean> {
      const res = await api.cancelOrder(id, params)
      return res.code === 200
    },

    setToken(base64String: string | null): boolean {
      if (base64String !== null) {
        const token = base64ToObject(base64String)
        this.token = token
        return true
      }
      return false
    },
    resetRefundForm(): void {
      this.refundForm = {
        accept: false,
        reason: [],
        otherReason: ''
      }
    },
    logout(): void {
      const t = useCookie('t')
      t.value = null
      this.token = null
      this.profile = {} as IProfile
    }
  },
  getters: {
    getOrders: (state): Array<any> =>
      state.orders.map(order => {
        let status = 1
        if (!order.event_info.event_status || !order.event_info.session_status) {
          status = 3
        } else if (!order.can_refund) {
          status = 2
        }
        return {
          orderNumber: order.order_number,
          status, // 1：申請退票
          count: order.count,
          price: order.total,
          link: `/member/refund/${order.order_number}`, // 退票連結
          linkView: `/events/${order.event_info.event_id}`,
          refundDate: order.refund_at,
          credit_info: order.credit_info,
          data: {
            title: order.event_info.event_name,
            dateRange: {
              start: {
                top: dateFormat(`${order.event_info.event_started_at}`, 'YYYY-MM-DD'),
                bottom: dateFormat(`${order.event_info.event_started_at}`, '(dd) HH:mm')
              },
              end: {
                top: dateFormat(`${order.event_info.event_ended_at}`, 'YYYY-MM-DD'),
                bottom: dateFormat(`${order.event_info.event_ended_at}`, '(dd) HH:mm')
              }
            },
            date: dateFormat(`${order.event_info.session_started_at}`, 'MM/DD HH:mm') + ' ~ ' + dateFormat(`${order.event_info.session_ended_at}`, 'HH:mm'),
            store: order.event_info.place_name,
            toggleData: {
              no: order.order_number,
              payMethod: state.paymentTypeDisplay[order.pay_type],
              text: '如有代親友購買活動票券，可將票券 QRcode 畫面截圖傳給參加者，以利活動日出示 QRcode 進行報到',
              data: order.items.map(item => {
                return {
                  registration_number: item.registration_number,
                  price: item.ticket_price,
                  name: item.user_name,
                  deadline: `${order.event_info.session_started_at} ~ ${order.event_info.session_ended_at}`
                }
              })
            }
          }
        }
      }),
    getOrder: (state): any => {
      let status = 1
      if (!state.order.event_info.event_status || !state.order.event_info.session_status) {
        status = 3
      } else if (!state.order.can_refund) {
        status = 2
      }
      return {
        status, // 1：申請退票
        count: state.order.count,
        price: state.order.total,
        link: `/member/refund/${state.order.order_number}`, // 退票連結
        linkView: `/events/${state.order.event_info.event_id}`,
        refundDate: state.order.refund_at,
        credit_info: state.order.credit_info,
        data: {
          title: state.order.event_info.event_name,
          dateRange: {
            start: {
              top: dateFormat(`${state.order.event_info.event_started_at}`, 'YYYY-MM-DD'),
              bottom: dateFormat(`${state.order.event_info.event_started_at}`, '(dd) HH:mm')
            },
            end: {
              top: dateFormat(`${state.order.event_info.event_ended_at}`, 'YYYY-MM-DD'),
              bottom: dateFormat(`${state.order.event_info.event_ended_at}`, '(dd) HH:mm')
            }
          },
          date: dateFormat(`${state.order.event_info.session_started_at}`, 'MM/DD HH:mm') + ' ~ ' + dateFormat(`${state.order.event_info.session_ended_at}`, 'HH:mm'),
          store: state.order.event_info.place_name,
          toggleData: {
            no: state.order.order_number,
            payMethod: state.paymentTypeDisplay[state.order.pay_type],
            text: '如有代親友購買活動票券，可將票券 QRcode 畫面截圖傳給參加者，以利活動日出示 QRcode 進行報到',
            data: state.order.items.map(item => {
              return {
                registration_number: item.registration_number,
                price: item.ticket_price,
                name: item.user_name,
                deadline: `${state.order.event_info.session_started_at} ~ ${state.order.event_info.session_ended_at}`
              }
            })
          }
        }
      }
    },
    getRepairs: (state): Array<any> =>
      state.repairs.map(repair => {
        const type = repairTypeDisplay(repair.type)
        return {
          id: repair.id,
          cards: [
            {
              main: {
                title: type,
                code: '#' + repair.id.toString().padStart(10, '0'),
                popup: true
              },
              col: 24
            },
            {
              title: '品牌/型號',
              text: `${repair.brand_name}/${repair.model_name}`,
              col: 8
            },
            {
              title: '機碼/序號',
              text: repair.serial,
              col: 8
            },
            {
              title: '處理狀態',
              text: repair.status,
              hovertext: repair.status_description,
              col: 8
            },
            {
              title: '取件查貨號',
              text: repair.delivery_serial,
              link: repair.delivery_url,
              col: 8
            },
            {
              title: '報修日期',
              text: dateFormat(repair.created_at, 'YYYY/MM/DD'),
              col: 8
            },
            {
              title: '預約日期',
              text: repair.book_date != 'N/A' ? dateFormat(repair.book_date, 'YYYY/MM/DD') : repair.book_date,
              col: 8
            },
            {
              title: '發票查詢',
              text: repair.invoice_info.invoice_num,
              link: `https://sinv.551.com.tw/${repair.invoice_info.invoice_num}`,
              col: 8
            },
            {
              title: '折讓單',
              text: repair.credit_info ? ' ' : '無',
              show_credit_note: repair.credit_info,
              col: 8
            }
          ]
        }
      })
  },
  persist: true
})
