import { defineStore } from 'pinia'
import { CartApi } from '@/utils/api'
import type { ICartAddItem, ICartPayOrder, ICartList } from '@/utils/types/Cart'
const cartApi = new CartApi()

interface StoreState {
  cartList: ICartList
}

export const useCartStore = defineStore('CartStore', {
  state: (): StoreState => {
    return {
      cartList: {
        items: [],
        subtotal: 0,
        total: 0
      }
    }
  },
  actions: {
    async fetchCart() {
      const res = await cartApi.getCart()
      this.cartList = res.data
      return res.code === 200
    },
    async postAddCart(params: Array<ICartAddItem>): Promise<boolean> {
      const res = await cartApi.addCart(params)
      return res.code === 200
    },
    async postPayCart(params: ICartPayOrder) {
      const res = await cartApi.payCart(params)
      return res.data
    }
  },
  getters: {}
})
