import Http from '../http'
export class OptionApi {
  // 取得地址下拉
  public getAddress() {
    const queryParams = { page: 1, per_page: 100 }

    return Http.get('/option/address', queryParams)
  }

  // 取得品牌下拉
  public getBrands(type: string = '') {
    const queryParams = { page: 1, per_page: 100, type: type }

    return Http.get('/option/brands', queryParams)
  }

  // 取得聯絡類別
  public getContactClasses() {
    const queryParams = { page: 1, per_page: 100 }

    return Http.get('/contact_classes', queryParams)
  }

  // 取得產品下拉
  public getProductInfos(type?: string) {
    const queryParams = { page: 1, per_page: 100, type: type }

    return Http.get('/option/products', queryParams)
  }

  // 取得速修預約時間
  public getReserveTimes() {
    const queryParams = { page: 1, per_page: 100 }

    return Http.get('/option/reserve_times', queryParams)
  }

  // 取得通路下拉
  public getChannels() {
    const queryParams = { page: 1, per_page: 100 }

    return Http.get('/option/channels', queryParams)
  }
}
