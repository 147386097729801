import { defineStore } from 'pinia'
import { OptionApi, SubscribeApi } from '@/utils/api'
import _ from 'lodash'
import { type AddressOption, type IChannelOption, type Option, type ContactClassOption, type BrandOption, type IProductInfoOption, type IReserveTime, type IOptionSubject } from '@/utils/types'
const optionApi = new OptionApi()
const subscribeApi = new SubscribeApi()

interface StoreState {
  subscribeTopics: Array<IOptionSubject>
  address: Array<AddressOption>
  contactClasses: Array<ContactClassOption>
  brands: Array<BrandOption>
  giftBrands: Array<BrandOption>
  productInfos: {
    all: Array<IProductInfoOption>
    general: Array<IProductInfoOption>
    fast: Array<IProductInfoOption>
    faq: Array<IProductInfoOption>
    deep: Array<IProductInfoOption>
  }
  reserveTimes: Array<IReserveTime>
  channels: Array<IChannelOption>
  invoiceType: Array<Option>
}

export const useOptionStore = defineStore('optionStore', {
  state: (): StoreState => {
    return {
      subscribeTopics: [],
      address: [],
      contactClasses: [],
      brands: [],
      giftBrands: [],
      productInfos: {
        all: [],
        general: [],
        fast: [],
        faq: [],
        deep: []
      },
      reserveTimes: [],
      channels: [],
      invoiceType: [
        {
          id: 'B2C',
          name: '會員電子發票'
        },
        {
          id: 'PHONE',
          name: '手機載具'
        },
        {
          id: 'B2B',
          name: '公司戶電子發票'
        }
      ]
    }
  },
  actions: {
    async fetchSubscribeTopics() {
      if (this.subscribeTopics.length === 0) {
        try {
          const res = await subscribeApi.getSubscribeTopics({ page: 1, per_page: 999 })
          this.subscribeTopics = res.data
        } catch (error) {
          console.log('取得訂閱主題失敗:', error)
        }
      }
    },
    async fetchAddress() {
      if (this.address.length === 0) {
        try {
          const res = await optionApi.getAddress()
          this.address = res.data
        } catch (error) {
          console.log('取得地址資訊失敗:', error)
        }
      }
    },
    async fetchProductInofs(type?: 'fast' | 'faq' | 'deep' | 'general') {
      let options = []
      const typeName = type || 'all'
      options = this.productInfos[typeName]
      if (options.length === 0) {
        try {
          const res = await optionApi.getProductInfos(type)
          this.productInfos[typeName] = res.data
        } catch (error) {
          console.log('取得產品下拉資料失敗:', error)
        }
      }
    },
    async fetchBrands() {
      try {
        const res = await optionApi.getBrands()
        this.brands = res.data
        return res.data
      } catch (error) {
        console.log('取得品牌下拉資料失敗:', error)
      }
    },
    async fetchGiftBrands() {
      try {
        const res = await optionApi.getBrands('gift')
        this.giftBrands = res.data
        return res.data
      } catch (error) {
        console.log('取得贈品品牌下拉資料失敗:', error)
      }
    },
    async fetchContactClasses() {
      const res = await optionApi.getContactClasses()
      this.contactClasses = res.data
    },
    async fetchReserveTimes() {
      try {
        const res = await optionApi.getReserveTimes()
        this.reserveTimes = res.data
      } catch (error) {
        console.log('取得速修預約時間失敗', error)
      }
    },
    async fetchChannels() {
      try {
        if (this.channels.length === 0) {
          const res = await optionApi.getChannels()
          this.channels = res.data
        }
      } catch (error) {
        console.log('取得通路下拉資料失敗:', error)
      }
    },
    cityOptions() {
      return this.address.map(o => {
        return {
          id: o.id,
          name: o.name
        }
      })
    },
    townOptions(cityId: string) {
      const city = this.address.find(o => o.id == cityId || o.name == cityId)
      if (city) {
        return city.towns.map(o => {
          return {
            id: o.id,
            name: o.name
          }
        })
      } else {
        return []
      }
    },
    zipcode(cityId: string, townId: string) {
      const city = this.address.find(o => o.id == cityId || o.name == cityId)
      if (city) {
        const town = city.towns.find(o => o.id == townId || o.name == townId)
        if (town) {
          return town.zipcode
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    productBrandOptions(type: 'general' | 'fast' | 'faq' | 'deep' | 'all') {
      return this.productInfos[type].map(o => {
        return {
          id: o.id,
          subject: o.subject,
          is_enable_warranty: o.is_enable_warranty,
          is_verify_model: o.is_verify_model,
          is_verify_code: o.is_verify_code,
          is_show_notes: o.is_show_notes,
          notes: o.notes,
          link: `/services/faq?brand_id=${o.id}`
        }
      })
    },
    productItemOptions(type: 'general' | 'fast' | 'faq' | 'deep' | 'all', brandId: string) {
      const brand = this.productInfos[type].find(o => o.id == brandId)
      if (brand) {
        const models = _.flatMap(brand.models, o => {
          return o.items
        })
        return _.uniqBy(models, 'id')
      } else {
        return []
      }
    },
    productModelOptions(type: 'general' | 'fast' | 'faq' | 'deep' | 'all', brandId: string, itemId?: string) {
      const brand = this.productInfos[type].find(o => o.id == brandId)
      if (brand) {
        if (!itemId) {
          return brand.models.map(o => {
            return {
              id: o.id,
              is_verify_code: o.is_verify_code,
              subject: o.subject,
              image: o?.image
            }
          })
        }
        const models = brand.models.filter(o => _.findIndex(o.items, { id: itemId }))
        return models.map(o => {
          return {
            id: o.id,
            is_verify_code: o.is_verify_code,
            subject: o.subject,
            image: o?.image
          }
        })
      } else {
        return []
      }
    }
  },
  getters: {
    getAddressOption: (state): Array<AddressOption> => state.address,
    // 給locations用
    cityOption: (state): Array<Option> => {
      let citys: Option[] = []
      citys.push({
        id: '',
        name: '全部地區'
      } as Option)
      state.address.forEach((o: AddressOption) => {
        citys.push({ id: o.id.toString(), name: o.name })
      })
      return citys
    },
    getContactClassesOption: (state): Array<ContactClassOption> => state.contactClasses
  }
})
