import Http from '@/utils/http'
import type { IEventFilter, IEventSubscribe, IEventAnswer } from '../types/Event'

export class EventApi {
  public getEventCategories(type: 'unit' | 'theme') {
    return Http.get(`/event_categories`, { type })
  }

  public getEvents(f?: IEventFilter) {
    return Http.get('/events', f)
  }

  public getEvent(id: string | number) {
    return Http.get(`/events/${id}`)
  }

  public getEventRecommends(id: string | number) {
    return Http.get(`/event_recommends/${id}`)
  }

  public getEventCalendar(yearMonth: string) {
    return Http.get(`/calendar_events/${yearMonth}`)
  }

  public getEventSurvey(id: string | number) {
    return Http.get(`/event_survey/${id}`)
  }

  public submitEventSurvey(id: string | number, params: Array<IEventAnswer>) {
    return Http.post(`/event_survey/${id}`, {
      answers: params
    })
  }

  public submitSubscribeEvent(params: IEventSubscribe) {
    return Http.post('/submit_subscribe_event', params)
  }
}
