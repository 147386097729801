import { defineStore } from 'pinia'
import { EventApi } from '@/utils/api'
import type { IEventCalendar, IEventCategory, IEventHome, IEventFilter, IEvent, ICreateEventOrder, IEventSubscribe, IEventSurvey, IEventAnswer } from '@/utils/types/Event'
import type { Option } from '@/utils/types/Option'
const eventApi = new EventApi()

interface StoreState {
  calendar: IEventCalendar
  eventHome: IEventHome
  recommends: Array<IEvent>
  event?: IEvent
  themeCategories: Array<IEventCategory>
  unitCategories: Array<IEventCategory>
  cost_plan: Array<Option>
  format: Array<Option>
  status: Array<Option>
  paymentType: Array<Option>
  eventOrder: ICreateEventOrder
  survey: IEventSurvey
}

export const useEventStore = defineStore('EventStore', {
  state: (): StoreState => {
    return {
      calendar: {} as IEventCalendar,
      eventHome: {
        top: [],
        list: []
      },
      event: undefined,
      eventOrder: {
        session: {
          id: '',
          subject: '',
          started_at: '',
          ended_at: '',
          tickets: [],
          _title: '',
          _text: '',
          _event_time: '',
          _d: '',
          _mmdd: '',
          purchase_limit: null
        },
        participants: [],
        payment_type: '',
        survey_response_id: '',
        invoice: {
          invoice_ubn: '',
          invoice_title: '',
          invoice_email: '',
          carrier_type: 'B2C',
          carrier_num: ''
        },
        amount: 0,
        total: 0,
        agree: false,
        answers: []
      },
      recommends: [],
      themeCategories: [],
      unitCategories: [],
      survey: {
        id: '',
        title: '',
        questions: []
      },
      cost_plan: [
        { id: 'free', name: '免費活動' },
        { id: 'paid', name: '付費活動' }
      ],
      format: [
        { id: 'online', name: '線上' },
        { id: 'offline', name: '線下' }
      ],
      status: [
        { id: 'true', name: '報名中' },
        { id: 'false', name: '已截止' }
      ],
      paymentType: [
        { id: 'ZERO', name: '免付費' },
        { id: '9104', name: '信用卡一次付款' },
        { id: '9101', name: '街口支付' },
        { id: '9111', name: 'LINE PAY' }
      ]
    }
  },
  actions: {
    async fetchEventCalendar(yearMonth: string) {
      try {
        const res = await eventApi.getEventCalendar(yearMonth)
        this.calendar = res.data
      } catch (error) {
        console.error('取得活動行事曆資料失敗:', error)
      }
    },
    async fetchEvents(f?: IEventFilter) {
      try {
        const res = await eventApi.getEvents(f)
        this.eventHome.top = res.data.top
        this.eventHome.list = res.data.list.data
        return res.data.list
      } catch (error) {
        console.error('取得活動列表資料失敗:', error)
      }
    },
    async fetchEvent(slug: string | number) {
      try {
        const res = await eventApi.getEvent(slug)
        this.event = res.data
      } catch (error) {
        console.log('取得活動資料失敗:', error)
      }
    },
    async fetchEventCategories() {
      const themeRes = await eventApi.getEventCategories('theme')
      const unitRes = await eventApi.getEventCategories('unit')
      this.themeCategories = themeRes.data
      this.unitCategories = unitRes.data
    },
    async fetchEventRecommends(slug: string | number) {
      const res = await eventApi.getEventRecommends(slug)
      this.recommends = res.data
    },
    async fetchEventSurvey(slug: string | number) {
      try {
        const res = await eventApi.getEventSurvey(slug)
        this.survey = res.data
      } catch (error) {
        console.log('取得活動問卷失敗:', error)
      }
    },
    async postEventSurvey(slug: string | number, params: Array<IEventAnswer>) {
      const res = await eventApi.submitEventSurvey(slug, params)
      return res.data
    },
    async postEventSubscribe(params: IEventSubscribe): Promise<boolean> {
      const res = await eventApi.submitSubscribeEvent(params)
      return res.code === 200
    },
    async clearEventSurvey() {
      this.survey.id = ''
      this.survey.title = ''
      this.survey.questions = []
    },
    async clearEventOrder() {
      this.eventOrder = {
        session: {
          id: '',
          subject: '',
          started_at: '',
          ended_at: '',
          tickets: [],
          purchase_limit: null,
          _title: '',
          _text: '',
          _event_time: '',
          _d: '',
          _mmdd: ''
        },
        participants: [],
        payment_type: '',
        survey_response_id: '',
        invoice: {
          invoice_ubn: '',
          invoice_title: '',
          invoice_email: '',
          carrier_type: 'B2C',
          carrier_num: ''
        },
        amount: 0,
        total: 0,
        agree: false,
        answers: []
      }
    }
  },
  getters: {
    getEventCategories: (
      state
    ): {
      themeCategories: Array<IEventCategory>
      unitCategories: Array<IEventCategory>
    } => {
      return {
        themeCategories: state.themeCategories,
        unitCategories: state.unitCategories
      }
    }
  }
})
