import Http from '@/utils/http'

export class PageApi {
  // 首頁資訊
  public getHomepage() {
    return Http.get(`/homepage`)
  }

  // 永續生活資訊
  public getEcoLifePage() {
    return Http.get(`/homepage/eco_life`)
  }

  // zonezone資訊
  public getZonezonePage() {
    return Http.get(`/homepage/zonezone`)
  }

  // hence, 雜誌資訊
  public getHencePage() {
    return Http.get(`/homepage/article`)
  }

  //搜尋
  public getSearch(f: any) {
    return Http.get(`/search`, f)
  }
}
