export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"恆隆行 hengstyle 線上服務平台 提供的串聯虛實的完整服務。顧客不僅能在 hengstyle 百貨專櫃體驗、諮詢及購買商品，也可透過 24小時 hengstyle 購物服務平台輕鬆享受無時差、零距離的購物服務，及找到最齊全的商品配件。"},{"name":"keywords","content":"恆隆行, 時尚家電, hengstyle, Dyson, bodum, Oster, Sodastream, Foodsaver, Braun, Oral-B, Omron, Ya-Man, no!no!, Panasonic, Coway, Twinbird, Honeywell, Corkcicle"},{"property":"og:title","content":"hengstyle：恆隆行"},{"property":"og:description","content":"恆隆行 hengstyle 線上服務平台 提供的串聯虛實的完整服務。顧客不僅能在 hengstyle 百貨專櫃體驗、諮詢及購買商品，也可透過 24小時 hengstyle 購物服務平台輕鬆享受無時差、零距離的購物服務，及找到最齊全的商品配件。"},{"property":"og:image","content":""}],"link":[],"style":[],"script":[{"src":"https://cdn.jsdelivr.net/npm/dcraw","defer":true},{"src":"https://cdn.jsdelivr.net/npm/tiff.js","defer":true}],"noscript":[],"htmlAttrs":{"lang":"zh-Hant"},"title":"hengstyle：恆隆行","charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'