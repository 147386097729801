import { useGlobalStore } from '@/stores'

export default defineNuxtRouteMiddleware(to => {
  const hasWhileList = ['brand-story', 'membership', 'sustainability']
  const hasNotFixedList = ['membership', 'brand-story', 'sustainability']
  const hasTransparentList = ['membership', 'sustainability']
  const globalStore = useGlobalStore()
  globalStore.isDark = to.name === 'zonezone'
  globalStore.isWhite1 = to.name?.toString().indexOf('hence') !== -1
  globalStore.isWhite = hasWhileList.indexOf(to.name?.toString() || '') !== -1
  globalStore.isHeaderTransparent = hasTransparentList.indexOf(to.name?.toString() || '') !== -1
  globalStore.isNotFixed = hasNotFixedList.indexOf(to.name?.toString() || '') !== -1
})
