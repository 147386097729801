import Http from '@/utils/http'
import type { IFilter, ISubscribeForm } from '../types'

export class SubscribeApi {
  // 訂閱電子報喜愛下拉
  public getSubscribeTopics(params: IFilter) {
    return Http.get('/subscribe_topics', params)
  }

  // 訂閱電子報
  public submitSubscribeTopic(params: ISubscribeForm) {
    return Http.post('/submit_subscribe_topic', params)
  }
}

export default new SubscribeApi()
