import { defineStore } from 'pinia'
import type { IRepairForm, IFastRepairForm, IDeepRepairForm, IWarrantyForm } from '@/utils/types'

interface StoreState {
  repairForm: IRepairForm
  fastRepairForm: IFastRepairForm
  deepRepairForm: IDeepRepairForm
  warrantyForm: IWarrantyForm
  fastRepairCalendar: {
    title: string
    content: string
    datetime: string
    address: string
  }
}

export const useFormStore = defineStore('formStore', {
  state: (): StoreState => {
    return {
      fastRepairCalendar: {
        title: 'hengstyle快速維修提醒',
        content: '',
        datetime: '',
        address: ''
      },
      repairForm: {
        type: 'general',
        items: [],
        carrier_type: 'B2C',
        carrier_num: '',
        invoice_title: '',
        invoice_ubn: '',
        invoice_email: '',
        send_name: '',
        send_sex: '',
        send_phone: '',
        send_city: '',
        send_address: '',
        send_town: '',
        send_zip_code: '',
        send_memo: '',
        receive_name: '',
        receive_sex: '',
        receive_phone: '',
        receive_city: '',
        receive_address: '',
        receive_memo: '',
        receive_zip_code: '',
        receive_town: '',
        google_recaptcha_token: ''
      },
      fastRepairForm: {
        type: 'fast',
        items: [],
        google_recaptcha_token: '',
        carrier_type: 'B2C',
        carrier_num: '',
        invoice_title: '',
        invoice_ubn: '',
        invoice_email: '',
        send_name: '',
        send_sex: '',
        send_phone: '',
        send_zip_code: '',
        send_city: '',
        send_town: '',
        send_address: '',
        send_memo: '無',
        book_date: '',
        book_memo: '',
        book_tmp_date: '',
        book_tmp_time: ''
      },
      deepRepairForm: {
        type: 'deep',
        items: [],
        google_recaptcha_token: '',
        carrier_type: 'B2C',
        carrier_num: '',
        invoice_title: '',
        invoice_ubn: '',
        invoice_email: '',
        send_name: '',
        send_sex: '',
        send_phone: '',
        send_zip_code: '',
        send_city: '',
        send_town: '',
        send_address: '',
        send_memo: '',
        receive_name: '',
        receive_sex: '',
        receive_phone: '',
        receive_zip_code: '',
        receive_city: '',
        receive_town: '',
        receive_address: '',
        receive_memo: ''
      },
      warrantyForm: {
        brand_id: '',
        model_id: '',
        serial: '',
        channel_id: '',
        medias: null,
        input_method: 'manual',
        order_number: '',
        invoice_date: '',
        scan_invoice_date: '',
        google_recaptcha_token: ''
      }
    }
  },
  actions: {
    clearRepairForm() {
      this.repairForm.type = 'general'
      this.repairForm.items = []
      this.repairForm.carrier_type = 'B2C'
      this.repairForm.carrier_num = ''
      this.repairForm.invoice_title = ''
      this.repairForm.invoice_ubn = ''
      this.repairForm.invoice_email = ''
      this.repairForm.send_name = ''
      this.repairForm.send_sex = ''
      this.repairForm.send_phone = ''
      this.repairForm.send_city = ''
      this.repairForm.send_address = ''
      this.repairForm.send_zip_code = ''
      this.repairForm.send_town = ''
      this.repairForm.send_memo = ''
      this.repairForm.receive_name = ''
      this.repairForm.receive_sex = ''
      this.repairForm.receive_phone = ''
      this.repairForm.receive_city = ''
      this.repairForm.receive_address = ''
      this.repairForm.receive_memo = ''
      this.repairForm.receive_zip_code = ''
      this.repairForm.receive_town = ''
      this.repairForm.google_recaptcha_token = ''
    },
    clearFastRepairForm() {
      this.fastRepairForm.type = 'fast'
      this.fastRepairForm.items = []
      this.fastRepairForm.carrier_type = 'B2C'
      this.fastRepairForm.carrier_num = ''
      this.fastRepairForm.invoice_title = ''
      this.fastRepairForm.invoice_ubn = ''
      this.fastRepairForm.invoice_email = ''
      this.fastRepairForm.send_name = ''
      this.fastRepairForm.send_sex = ''
      this.fastRepairForm.send_phone = ''
      this.fastRepairForm.send_city = ''
      this.fastRepairForm.send_address = ''
      this.fastRepairForm.send_zip_code = ''
      this.fastRepairForm.send_town = ''
      this.fastRepairForm.send_memo = '無'
      this.fastRepairForm.book_date = ''
      this.fastRepairForm.book_memo = ''
      this.fastRepairForm.book_tmp_date = ''
      this.fastRepairForm.book_tmp_time = ''
      this.fastRepairForm.google_recaptcha_token = ''
    },
    clearDeepRepairForm() {
      this.repairForm.type = 'deep'
      this.repairForm.items = []
      this.repairForm.carrier_type = 'B2C'
      this.repairForm.carrier_num = ''
      this.repairForm.invoice_title = ''
      this.repairForm.invoice_ubn = ''
      this.repairForm.invoice_email = ''
      this.repairForm.send_name = ''
      this.repairForm.send_sex = ''
      this.repairForm.send_phone = ''
      this.repairForm.send_city = ''
      this.repairForm.send_address = ''
      this.repairForm.send_zip_code = ''
      this.repairForm.send_town = ''
      this.repairForm.send_memo = ''
      this.repairForm.receive_name = ''
      this.repairForm.receive_sex = ''
      this.repairForm.receive_phone = ''
      this.repairForm.receive_city = ''
      this.repairForm.receive_address = ''
      this.repairForm.receive_memo = ''
      this.repairForm.receive_zip_code = ''
      this.repairForm.receive_town = ''
      this.repairForm.google_recaptcha_token = ''
    },
    clearWarrantyForm() {
      this.warrantyForm.brand_id = ''
      this.warrantyForm.model_id = ''
      this.warrantyForm.serial = ''
      this.warrantyForm.channel_id = ''
      this.warrantyForm.medias = null
      this.warrantyForm.input_method = 'manual'
      this.warrantyForm.order_number = ''
      this.warrantyForm.invoice_date = ''
      this.warrantyForm.scan_invoice_date = ''
      this.warrantyForm.google_recaptcha_token = ''
    }
  },
  getters: {}
})
