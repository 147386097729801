import validate from "/var/www/hengstyle-frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import dark_45global from "/var/www/hengstyle-frontend/middleware/dark.global.ts";
import normalize_45url_45global from "/var/www/hengstyle-frontend/middleware/normalize-url.global.ts";
import rewrite_45global from "/var/www/hengstyle-frontend/middleware/rewrite.global.ts";
import sso_45global from "/var/www/hengstyle-frontend/middleware/sso.global.ts";
import manifest_45route_45rule from "/var/www/hengstyle-frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  dark_45global,
  normalize_45url_45global,
  rewrite_45global,
  sso_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  utm: () => import("/var/www/hengstyle-frontend/middleware/utm.ts")
}