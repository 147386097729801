import { defineStore } from 'pinia'
import { LocationApi, OptionApi } from '@/utils/api'
import { type ILocationCategory, type ILocationClass, type ILocation, type Meta, type Option, type ILoactionFilter } from '@/utils/types'
const locationApi = new LocationApi()
const optionApi = new OptionApi()

interface StoreState {
  categories: Array<ILocationCategory>
  types: Array<ILocationClass>
  locations: Array<ILocation>
  location?: ILocation
  pageMeta: Meta | null
}

export const useLocationStore = defineStore('locationStore', {
  state: (): StoreState => {
    return {
      categories: [],
      types: [],
      locations: [],
      location: {} as ILocation,
      pageMeta: {
        last_page: 0,
        from: 0,
        per_page: 0,
        current_page: 0,
        to: 0,
        total: 0
      }
    }
  },
  actions: {
    async fetchLocationCategories() {
      try {
        const res = await locationApi.getLocationCategories()
        this.categories = res.data
      } catch (error) {
        console.log('取得櫃位類別列表失敗:', error)
      }
    },
    async fetchLocationClasses(categoryId?: string) {
      try {
        if (this.types.length === 0) {
          const res = await locationApi.getLocationClasses()
          this.types = res.data
        }
      } catch (error) {
        console.log('取得櫃位類型列表失敗:', error)
      }
    },
    async fetchLocations(params?: ILoactionFilter) {
      if (this.locations.length === 0) {
        try {
          const res = await locationApi.getLocations(params)
          this.locations = res.data
          this.pageMeta = res.meta
        } catch (error) {
          console.log('取得櫃位列表失敗:', error)
        }
      }
    },
    async fetchLocation(locationId: string) {
      try {
        const res = await locationApi.getLocation(locationId)
        this.location = res.data
        return res.data
      } catch (error) {
        console.log('取得櫃位資訊失敗:', error)
      }
    }
  },
  getters: {
    getLocationCategories: (state): Array<ILocationCategory> => state.categories,
    getLocationClasses: (state): Array<ILocationClass> => state.types,
    locationClassesOption: (state): Array<Option> => {
      let types: Option[] = []
      types.push({
        id: '',
        name: '所有櫃位類型'
      } as Option)
      state.types.forEach((o: ILocationClass) => {
        types.push({ id: o.id.toString(), name: o.name })
      })
      return types
    },
    getPageMeta: (state): Meta | null => state.pageMeta
  }
})
