import Http from '@/utils/http'
import type { IGiftEventFilter } from '../types'

export class GiftApi {
  public getGiftEvents(f = {} as IGiftEventFilter) {
    return Http.get(`/gift_events`, f)
  }

  public getGiftEvent(id: string | number) {
    return Http.get(`/gift_events/${id}`)
  }

  public submitGiftEvent(params: FormData) {
    return Http.post(`/submit_gift_event`, params)
  }
}
