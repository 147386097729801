import { defineStore } from 'pinia'
import { LocationApi, OptionApi } from '@/utils/api'
import { type IContactForm } from '@/utils/types'

interface StoreState {
  form: IContactForm
  showForm: string
}

export const useContactStore = defineStore('contactStore', {
  state: (): StoreState => {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        class_id: 1,
        google_recaptcha_token: '',
        content: '',
        others: {
          // company_name: '',
          brands: []
          // brand_name: '',
          // item_name: '',
          // category: '',
          // city: '',
          // town: '',
          // zip_code: '',
          // address: '',
          // order_number: '',
          // channel_id: '',
          // gift_event_name: '',
          // time: ''
        } as any
      },
      showForm: 'section1'
    }
  },
  actions: {
    setForm(data: IContactForm) {
      this.form = data
    },
    resetForm() {
      this.form = {
        name: '',
        email: '',
        phone: '',
        class_id: 1,
        google_recaptcha_token: '',
        content: '',
        others: {
          company_name: '',
          brand_name: '',
          brands: [],
          item_name: '',
          category: '',
          city: '',
          town: '',
          zip_code: '',
          address: '',
          order_number: '',
          channel_id: '',
          gift_event_name: '',
          time: ''
        }
      }
    },
    resetOthers() {
      this.form.others = {
        // company_name: '',
        // brand_name: '',
        brands: []
        // item_name: '',
        // category: '',
        // city: '',
        // town: '',
        // zip_code: '',
        // address: '',
        // order_number: '',
        // channel_id: '',
        // gift_event_name: '',
        // time: ''
      } as any
    },
    changeShowForm(id: string) {
      this.showForm = id
    }
  },
  getters: {}
})
