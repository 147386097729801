import { default as indexceqy1Qkq3gMeta } from "/var/www/hengstyle-frontend/pages/blog/[blogCategory]/[blogSubCategory]/index.vue?macro=true";
import { default as ClassLinkmts35vA1lqMeta } from "/var/www/hengstyle-frontend/pages/blog/[blogCategory]/components/ClassLink.vue?macro=true";
import { default as ListsJcu9gc61BCMeta } from "/var/www/hengstyle-frontend/pages/blog/[blogCategory]/components/Lists.vue?macro=true";
import { default as TopTnDRjhGli0Meta } from "/var/www/hengstyle-frontend/pages/blog/[blogCategory]/components/Top.vue?macro=true";
import { default as index3zTqrfUw9RMeta } from "/var/www/hengstyle-frontend/pages/blog/[blogCategory]/index.vue?macro=true";
import { default as seo10eh5MDvVWMeta } from "/var/www/hengstyle-frontend/pages/blog/[blogCategory]/seo.ts?macro=true";
import { default as ArticleRnBZBlTp64Meta } from "/var/www/hengstyle-frontend/pages/blog/article/[slug]/components/Article.vue?macro=true";
import { default as ClassLinkxCOthZug6ZMeta } from "/var/www/hengstyle-frontend/pages/blog/article/[slug]/components/ClassLink.vue?macro=true";
import { default as index6HOBdNbQa0Meta } from "/var/www/hengstyle-frontend/pages/blog/article/[slug]/index.vue?macro=true";
import { default as seoBDGZwWHEdBMeta } from "/var/www/hengstyle-frontend/pages/blog/article/[slug]/seo.ts?macro=true";
import { default as indexf7aFDjDisaMeta } from "/var/www/hengstyle-frontend/pages/blog/index.vue?macro=true";
import { default as seoCySapJrNIjMeta } from "/var/www/hengstyle-frontend/pages/blog/seo.ts?macro=true";
import { default as Banner6R8V6NrIeOMeta } from "/var/www/hengstyle-frontend/pages/brand-story/components/Banner.vue?macro=true";
import { default as BrandsmPlL6S1tC2Meta } from "/var/www/hengstyle-frontend/pages/brand-story/components/Brands.vue?macro=true";
import { default as CardBoxmgkZh1DuFuMeta } from "/var/www/hengstyle-frontend/pages/brand-story/components/CardBox.vue?macro=true";
import { default as Chairman8kz0azT26vMeta } from "/var/www/hengstyle-frontend/pages/brand-story/components/Chairman.vue?macro=true";
import { default as ContactXpxOqfNX1kMeta } from "/var/www/hengstyle-frontend/pages/brand-story/components/Contact.vue?macro=true";
import { default as ExploreJXAqUMNCyOMeta } from "/var/www/hengstyle-frontend/pages/brand-story/components/Explore.vue?macro=true";
import { default as MilestoneDsUVhDsgUDMeta } from "/var/www/hengstyle-frontend/pages/brand-story/components/Milestone.vue?macro=true";
import { default as ObserveIRd9lmCODcMeta } from "/var/www/hengstyle-frontend/pages/brand-story/components/Observe.vue?macro=true";
import { default as ServicekkzzsHZj9WMeta } from "/var/www/hengstyle-frontend/pages/brand-story/components/Service.vue?macro=true";
import { default as indexKHUeLD2s1SMeta } from "/var/www/hengstyle-frontend/pages/brand-story/index.vue?macro=true";
import { default as seoOarEqMQE8zMeta } from "/var/www/hengstyle-frontend/pages/brand-story/seo.ts?macro=true";
import { default as _91brandId_93At18asAQoYMeta } from "/var/www/hengstyle-frontend/pages/brand/[brandId].vue?macro=true";
import { default as BrandActiveoCDjpGKaAhMeta } from "/var/www/hengstyle-frontend/pages/brand/components/BrandActive.vue?macro=true";
import { default as BrandSlideA8Vr7M5saiMeta } from "/var/www/hengstyle-frontend/pages/brand/components/BrandSlide.vue?macro=true";
import { default as seonZbNNmJwLYMeta } from "/var/www/hengstyle-frontend/pages/brand/seo.ts?macro=true";
import { default as BrandCategory3tESwa7HsmMeta } from "/var/www/hengstyle-frontend/pages/brands/[brandCategory]/components/BrandCategory.vue?macro=true";
import { default as BrandList9ojry0aeyfMeta } from "/var/www/hengstyle-frontend/pages/brands/[brandCategory]/components/BrandList.vue?macro=true";
import { default as indexAxXCvTtwDFMeta } from "/var/www/hengstyle-frontend/pages/brands/[brandCategory]/index.vue?macro=true";
import { default as seo61E54g5rgAMeta } from "/var/www/hengstyle-frontend/pages/brands/[brandCategory]/seo.ts?macro=true";
import { default as index6G88uUMCNuMeta } from "/var/www/hengstyle-frontend/pages/brands/index.vue?macro=true";
import { default as complaints_45infooCIck91gYMMeta } from "/var/www/hengstyle-frontend/pages/complaints-info.vue?macro=true";
import { default as baseTvqPD1qUD7Meta } from "/var/www/hengstyle-frontend/pages/contact/base.vue?macro=true";
import { default as indexAg9Dkd3iOmMeta } from "/var/www/hengstyle-frontend/pages/contact/index.vue?macro=true";
import { default as seojMA4HTOXpVMeta } from "/var/www/hengstyle-frontend/pages/contact/seo.ts?macro=true";
import { default as step2Kk20g4FkRDMeta } from "/var/www/hengstyle-frontend/pages/contact/step2.vue?macro=true";
import { default as step32QWCiLmZusMeta } from "/var/www/hengstyle-frontend/pages/contact/step3.vue?macro=true";
import { default as ContentR0K2AOpsF4Meta } from "/var/www/hengstyle-frontend/pages/events/[eventId]/apply/components/Content.vue?macro=true";
import { default as RadioItem4FwDqmUtSJMeta } from "/var/www/hengstyle-frontend/pages/events/[eventId]/apply/components/RadioItem.vue?macro=true";
import { default as StepooFRSR0FwVMeta } from "/var/www/hengstyle-frontend/pages/events/[eventId]/apply/components/Step.vue?macro=true";
import { default as Step1ObdubmyjE9Meta } from "/var/www/hengstyle-frontend/pages/events/[eventId]/apply/components/Step1.vue?macro=true";
import { default as Step2IjujzpaRuiMeta } from "/var/www/hengstyle-frontend/pages/events/[eventId]/apply/components/Step2.vue?macro=true";
import { default as Step3roUGGzK6kUMeta } from "/var/www/hengstyle-frontend/pages/events/[eventId]/apply/components/Step3.vue?macro=true";
import { default as Step4lHBnCZMXraMeta } from "/var/www/hengstyle-frontend/pages/events/[eventId]/apply/components/Step4.vue?macro=true";
import { default as indexJGFUK1CcoqMeta } from "/var/www/hengstyle-frontend/pages/events/[eventId]/apply/index.vue?macro=true";
import { default as seoCyNw8h3vYdMeta } from "/var/www/hengstyle-frontend/pages/events/[eventId]/apply/seo.ts?macro=true";
import { default as ContentATd4ECOEdIMeta } from "/var/www/hengstyle-frontend/pages/events/[eventId]/components/Content.vue?macro=true";
import { default as FaqI40JoenyJAMeta } from "/var/www/hengstyle-frontend/pages/events/[eventId]/components/Faq.vue?macro=true";
import { default as SideONB4Emhw4QMeta } from "/var/www/hengstyle-frontend/pages/events/[eventId]/components/Side.vue?macro=true";
import { default as SideMobilerfNR8gEU2QMeta } from "/var/www/hengstyle-frontend/pages/events/[eventId]/components/SideMobile.vue?macro=true";
import { default as SlideWhxa80HtAjMeta } from "/var/www/hengstyle-frontend/pages/events/[eventId]/components/Slide.vue?macro=true";
import { default as indexKB56IFTxLCMeta } from "/var/www/hengstyle-frontend/pages/events/[eventId]/index.vue?macro=true";
import { default as seocZ4xNY6E15Meta } from "/var/www/hengstyle-frontend/pages/events/[eventId]/seo.ts?macro=true";
import { default as Dropy979ImYjpXMeta } from "/var/www/hengstyle-frontend/pages/events/components/Drop.vue?macro=true";
import { default as indexVMJiSJBICWMeta } from "/var/www/hengstyle-frontend/pages/events/index.vue?macro=true";
import { default as seo3Da4CfgXHPMeta } from "/var/www/hengstyle-frontend/pages/events/seo.ts?macro=true";
import { default as indexRGDB1IgcXIMeta } from "/var/www/hengstyle-frontend/pages/giftchoose/brand-hs/channel-[id]/index.vue?macro=true";
import { default as indexZmbFYidkrlMeta } from "/var/www/hengstyle-frontend/pages/giftchoose/brand-hs/index.vue?macro=true";
import { default as indexHzQq8EJIznMeta } from "/var/www/hengstyle-frontend/pages/giftchoose/brand-hs/menu-[id]/index.vue?macro=true";
import { default as applyQOGGV2sy6cMeta } from "/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/apply.vue?macro=true";
import { default as MobileBottom8rPfQc3MNVMeta } from "/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/MobileBottom.vue?macro=true";
import { default as StepqheTwxvGT4Meta } from "/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step.vue?macro=true";
import { default as Step1C1OzPqQgLfMeta } from "/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step1.vue?macro=true";
import { default as Step2bVDn8EvlpsMeta } from "/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step2.vue?macro=true";
import { default as Step36J1cSr1qzuMeta } from "/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step3.vue?macro=true";
import { default as Step4mWzlnBL3A7Meta } from "/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step4.vue?macro=true";
import { default as index2MnjJnYO0QMeta } from "/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/index.vue?macro=true";
import { default as seoTcNhuNG3fiMeta } from "/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/seo.ts?macro=true";
import { default as gsapGV4AGyY9BuMeta } from "/var/www/hengstyle-frontend/pages/gsap.vue?macro=true";
import { default as ListGR2vP4kYIDMeta } from "/var/www/hengstyle-frontend/pages/hence/[categoryId]/components/List.vue?macro=true";
import { default as indexHG6bGxrfFEMeta } from "/var/www/hengstyle-frontend/pages/hence/[categoryId]/index.vue?macro=true";
import { default as seohnO0SgEEcpMeta } from "/var/www/hengstyle-frontend/pages/hence/[categoryId]/seo.ts?macro=true";
import { default as _91id_930CPKPYjJXcMeta } from "/var/www/hengstyle-frontend/pages/hence/article/[id].vue?macro=true";
import { default as Content496mQrbBx1Meta } from "/var/www/hengstyle-frontend/pages/hence/article/components/Content.vue?macro=true";
import { default as ListEikr9oDDAtMeta } from "/var/www/hengstyle-frontend/pages/hence/article/components/List.vue?macro=true";
import { default as seocB4AwHlVWJMeta } from "/var/www/hengstyle-frontend/pages/hence/article/seo.ts?macro=true";
import { default as _91id_93fgz9yzC0jyMeta } from "/var/www/hengstyle-frontend/pages/hence/author/[id].vue?macro=true";
import { default as Authorzz75WKdRKqMeta } from "/var/www/hengstyle-frontend/pages/hence/author/components/Author.vue?macro=true";
import { default as ListT3Ip4sKiO4Meta } from "/var/www/hengstyle-frontend/pages/hence/author/components/List.vue?macro=true";
import { default as seohLgqWlPXndMeta } from "/var/www/hengstyle-frontend/pages/hence/author/seo.ts?macro=true";
import { default as AdXhdP95S6vWMeta } from "/var/www/hengstyle-frontend/pages/hence/components/Ad.vue?macro=true";
import { default as ArticlerORZJEZiSoMeta } from "/var/www/hengstyle-frontend/pages/hence/components/Article.vue?macro=true";
import { default as AuthorFZqhI63i8mMeta } from "/var/www/hengstyle-frontend/pages/hence/components/Author.vue?macro=true";
import { default as BannerR6okoGUoNBMeta } from "/var/www/hengstyle-frontend/pages/hence/components/Banner.vue?macro=true";
import { default as CardItemhLYKEY0LlHMeta } from "/var/www/hengstyle-frontend/pages/hence/components/CardItem.vue?macro=true";
import { default as Experienceu7G81WOyHOMeta } from "/var/www/hengstyle-frontend/pages/hence/components/Experience.vue?macro=true";
import { default as HenceTextNG0MqAWbpjMeta } from "/var/www/hengstyle-frontend/pages/hence/components/HenceText.vue?macro=true";
import { default as List137Fksk1j7WMeta } from "/var/www/hengstyle-frontend/pages/hence/components/List1.vue?macro=true";
import { default as List21dH5J9tOviMeta } from "/var/www/hengstyle-frontend/pages/hence/components/List2.vue?macro=true";
import { default as List3yWDhZjfOYmMeta } from "/var/www/hengstyle-frontend/pages/hence/components/List3.vue?macro=true";
import { default as NewsletteryIDPBAipHgMeta } from "/var/www/hengstyle-frontend/pages/hence/components/Newsletter.vue?macro=true";
import { default as PageLinkaqCWeWr6yNMeta } from "/var/www/hengstyle-frontend/pages/hence/components/PageLink.vue?macro=true";
import { default as PodcastvvSXkZEycEMeta } from "/var/www/hengstyle-frontend/pages/hence/components/Podcast.vue?macro=true";
import { default as Recipetm9kyWHKoDMeta } from "/var/www/hengstyle-frontend/pages/hence/components/Recipe.vue?macro=true";
import { default as Side1QMal8mSqaMeta } from "/var/www/hengstyle-frontend/pages/hence/components/Side.vue?macro=true";
import { default as indexKbhMtk7M6iMeta } from "/var/www/hengstyle-frontend/pages/hence/index.vue?macro=true";
import { default as index7gbfVgFTONMeta } from "/var/www/hengstyle-frontend/pages/hence/magazine/index.vue?macro=true";
import { default as seoc3uBgHTYGTMeta } from "/var/www/hengstyle-frontend/pages/hence/magazine/seo.ts?macro=true";
import { default as Contentbt9j9gRmDXMeta } from "/var/www/hengstyle-frontend/pages/hence/recipe/article/[id]/components/Content.vue?macro=true";
import { default as ListLdNIHBVkyTMeta } from "/var/www/hengstyle-frontend/pages/hence/recipe/article/[id]/components/List.vue?macro=true";
import { default as SlidehTqYTVTFyAMeta } from "/var/www/hengstyle-frontend/pages/hence/recipe/article/[id]/components/Slide.vue?macro=true";
import { default as indexRcJIlecGseMeta } from "/var/www/hengstyle-frontend/pages/hence/recipe/article/[id]/index.vue?macro=true";
import { default as seoVGcYGHiQNnMeta } from "/var/www/hengstyle-frontend/pages/hence/recipe/article/[id]/seo.ts?macro=true";
import { default as Filteru3UsCYmR39Meta } from "/var/www/hengstyle-frontend/pages/hence/recipe/components/Filter.vue?macro=true";
import { default as Listxts4C3dPrZMeta } from "/var/www/hengstyle-frontend/pages/hence/recipe/components/List.vue?macro=true";
import { default as index55DhTByqVYMeta } from "/var/www/hengstyle-frontend/pages/hence/recipe/index.vue?macro=true";
import { default as seojQzNVirMQJMeta } from "/var/www/hengstyle-frontend/pages/hence/recipe/seo.ts?macro=true";
import { default as seoSaIxBS0OyjMeta } from "/var/www/hengstyle-frontend/pages/hence/seo.ts?macro=true";
import { default as BannerkBigJH6VbpMeta } from "/var/www/hengstyle-frontend/pages/hence/topic/components/Banner.vue?macro=true";
import { default as ListU20sZ3rSvnMeta } from "/var/www/hengstyle-frontend/pages/hence/topic/components/List.vue?macro=true";
import { default as indexIKcZjWftooMeta } from "/var/www/hengstyle-frontend/pages/hence/topic/index.vue?macro=true";
import { default as seoittDbZ7f7LMeta } from "/var/www/hengstyle-frontend/pages/hence/topic/seo.ts?macro=true";
import { default as About2qidMQAOPPMeta } from "/var/www/hengstyle-frontend/pages/index/components/About.vue?macro=true";
import { default as BannerVideo8V00UqAs8WMeta } from "/var/www/hengstyle-frontend/pages/index/components/BannerVideo.vue?macro=true";
import { default as Section1IWdxm35uuQMeta } from "/var/www/hengstyle-frontend/pages/index/components/Section1.vue?macro=true";
import { default as Section2bzZTqBKIYRMeta } from "/var/www/hengstyle-frontend/pages/index/components/Section2.vue?macro=true";
import { default as Section2SlidezTG9ddTNE7Meta } from "/var/www/hengstyle-frontend/pages/index/components/Section2Slide.vue?macro=true";
import { default as Section3ROLfA6pSu4Meta } from "/var/www/hengstyle-frontend/pages/index/components/Section3.vue?macro=true";
import { default as Section4smTKQRcD8zMeta } from "/var/www/hengstyle-frontend/pages/index/components/Section4.vue?macro=true";
import { default as indexvchzpom23YMeta } from "/var/www/hengstyle-frontend/pages/index/index.vue?macro=true";
import { default as seoRui9GfuUCbMeta } from "/var/www/hengstyle-frontend/pages/index/seo.ts?macro=true";
import { default as indexwFTi3KgtxAMeta } from "/var/www/hengstyle-frontend/pages/locations/[locationId]/index.vue?macro=true";
import { default as LocationListNdyCvy6BR7Meta } from "/var/www/hengstyle-frontend/pages/locations/components/LocationList.vue?macro=true";
import { default as LocationPopAN32UztVPqMeta } from "/var/www/hengstyle-frontend/pages/locations/components/LocationPop.vue?macro=true";
import { default as indexZH8F3xGNlfMeta } from "/var/www/hengstyle-frontend/pages/locations/index.vue?macro=true";
import { default as seoIQZsV2yleUMeta } from "/var/www/hengstyle-frontend/pages/locations/seo.ts?macro=true";
import { default as Section1X93wmm0w89Meta } from "/var/www/hengstyle-frontend/pages/member/activity/components/Section1.vue?macro=true";
import { default as Section2dYChQjk1YvMeta } from "/var/www/hengstyle-frontend/pages/member/activity/components/Section2.vue?macro=true";
import { default as Section32IRERNlbs3Meta } from "/var/www/hengstyle-frontend/pages/member/activity/components/Section3.vue?macro=true";
import { default as _91no_93gU7w4aRxuYMeta } from "/var/www/hengstyle-frontend/pages/member/activity/credit-note/[no].vue?macro=true";
import { default as indexTfshPbtTDnMeta } from "/var/www/hengstyle-frontend/pages/member/activity/index.vue?macro=true";
import { default as seoJyofGm93X4Meta } from "/var/www/hengstyle-frontend/pages/member/activity/seo.ts?macro=true";
import { default as PopupContentR4elyF3VDhMeta } from "/var/www/hengstyle-frontend/pages/member/components/PopupContent.vue?macro=true";
import { default as SideMenuErebJt2euQMeta } from "/var/www/hengstyle-frontend/pages/member/components/SideMenu.vue?macro=true";
import { default as indexlLX7dTN43JMeta } from "/var/www/hengstyle-frontend/pages/member/edit/index.vue?macro=true";
import { default as seo9utql15Z73Meta } from "/var/www/hengstyle-frontend/pages/member/edit/seo.ts?macro=true";
import { default as indexqwVkoyAL9HMeta } from "/var/www/hengstyle-frontend/pages/member/index.vue?macro=true";
import { default as _91no_93CmtOJqabWuMeta } from "/var/www/hengstyle-frontend/pages/member/refund/[no].vue?macro=true";
import { default as Step1cga77DntUZMeta } from "/var/www/hengstyle-frontend/pages/member/refund/components/Step1.vue?macro=true";
import { default as Step25iyAcsmLSmMeta } from "/var/www/hengstyle-frontend/pages/member/refund/components/Step2.vue?macro=true";
import { default as Step32dlvJTbMqkMeta } from "/var/www/hengstyle-frontend/pages/member/refund/components/Step3.vue?macro=true";
import { default as seonH0CDmvsEEMeta } from "/var/www/hengstyle-frontend/pages/member/refund/seo.ts?macro=true";
import { default as seogadmiUY2eiMeta } from "/var/www/hengstyle-frontend/pages/member/seo.ts?macro=true";
import { default as Section1C1p2LozQP1Meta } from "/var/www/hengstyle-frontend/pages/member/service/components/Section1.vue?macro=true";
import { default as Section2HRZl6moUM4Meta } from "/var/www/hengstyle-frontend/pages/member/service/components/Section2.vue?macro=true";
import { default as Section3FROXoKxyVKMeta } from "/var/www/hengstyle-frontend/pages/member/service/components/Section3.vue?macro=true";
import { default as _91no_93DaeZlQwo6bMeta } from "/var/www/hengstyle-frontend/pages/member/service/credit-note/[no].vue?macro=true";
import { default as indexC3XjNqr0wsMeta } from "/var/www/hengstyle-frontend/pages/member/service/index.vue?macro=true";
import { default as seoEV0ayPT4RuMeta } from "/var/www/hengstyle-frontend/pages/member/service/seo.ts?macro=true";
import { default as membership_45termsRudAfvaEiyMeta } from "/var/www/hengstyle-frontend/pages/membership-terms.vue?macro=true";
import { default as Faqd4uthKGEZoMeta } from "/var/www/hengstyle-frontend/pages/membership/components/Faq.vue?macro=true";
import { default as Section1Lq1HQERpWEMeta } from "/var/www/hengstyle-frontend/pages/membership/components/Section1.vue?macro=true";
import { default as Section2fol5ezEIzDMeta } from "/var/www/hengstyle-frontend/pages/membership/components/Section2.vue?macro=true";
import { default as Section3AZuvmwzGyZMeta } from "/var/www/hengstyle-frontend/pages/membership/components/Section3.vue?macro=true";
import { default as Section3ListnnaZRCikKeMeta } from "/var/www/hengstyle-frontend/pages/membership/components/Section3List.vue?macro=true";
import { default as index1sewyAEwFfMeta } from "/var/www/hengstyle-frontend/pages/membership/index.vue?macro=true";
import { default as seoZKnS96TfO3Meta } from "/var/www/hengstyle-frontend/pages/membership/seo.ts?macro=true";
import { default as indexaodkDzQshmMeta } from "/var/www/hengstyle-frontend/pages/news/[slug]/index.vue?macro=true";
import { default as _91newsId_93t3vfxcj2x5Meta } from "/var/www/hengstyle-frontend/pages/news/article/[newsId].vue?macro=true";
import { default as seo3c6AHjfvHOMeta } from "/var/www/hengstyle-frontend/pages/news/article/seo.ts?macro=true";
import { default as ClassLinkoeYfybfApzMeta } from "/var/www/hengstyle-frontend/pages/news/components/ClassLink.vue?macro=true";
import { default as ListD12prYTsHDMeta } from "/var/www/hengstyle-frontend/pages/news/components/List.vue?macro=true";
import { default as NewsItemnFVSKOzwfzMeta } from "/var/www/hengstyle-frontend/pages/news/components/NewsItem.vue?macro=true";
import { default as NewsPinjo0IVZzyqHMeta } from "/var/www/hengstyle-frontend/pages/news/components/NewsPin.vue?macro=true";
import { default as indexcgjtff8joaMeta } from "/var/www/hengstyle-frontend/pages/news/index.vue?macro=true";
import { default as seoXSkXZRy4R3Meta } from "/var/www/hengstyle-frontend/pages/news/seo.ts?macro=true";
import { default as privacy_45policyZ9a1WZV4amMeta } from "/var/www/hengstyle-frontend/pages/privacy-policy.vue?macro=true";
import { default as qrcodervwpXNQA3zMeta } from "/var/www/hengstyle-frontend/pages/qrcode.vue?macro=true";
import { default as scrollZyFvX4t9ZCMeta } from "/var/www/hengstyle-frontend/pages/scroll.vue?macro=true";
import { default as searchdywSoAIugpMeta } from "/var/www/hengstyle-frontend/pages/search.vue?macro=true";
import { default as seo_searchO3N7bWPx2NMeta } from "/var/www/hengstyle-frontend/pages/seo_search.ts?macro=true";
import { default as Featuresq4EMjobWkQMeta } from "/var/www/hengstyle-frontend/pages/services/cleaning/components/Features.vue?macro=true";
import { default as Step1MvyyzRYS1hMeta } from "/var/www/hengstyle-frontend/pages/services/cleaning/components/Step1.vue?macro=true";
import { default as Step2cwTWlWTmRrMeta } from "/var/www/hengstyle-frontend/pages/services/cleaning/components/Step2.vue?macro=true";
import { default as Step3HRmRWK1yGtMeta } from "/var/www/hengstyle-frontend/pages/services/cleaning/components/Step3.vue?macro=true";
import { default as Step4q4kn4ZEo6WMeta } from "/var/www/hengstyle-frontend/pages/services/cleaning/components/Step4.vue?macro=true";
import { default as form_seoChRZ0S0OaYMeta } from "/var/www/hengstyle-frontend/pages/services/cleaning/form_seo.ts?macro=true";
import { default as form1FG8Vtf5H2Meta } from "/var/www/hengstyle-frontend/pages/services/cleaning/form.vue?macro=true";
import { default as indexv9SaWyvnzhMeta } from "/var/www/hengstyle-frontend/pages/services/cleaning/index.vue?macro=true";
import { default as seoJI3NdpTdUaMeta } from "/var/www/hengstyle-frontend/pages/services/cleaning/seo.ts?macro=true";
import { default as Bottomcm8xWTwnyJMeta } from "/var/www/hengstyle-frontend/pages/services/components/Bottom.vue?macro=true";
import { default as CategoryyQmlSDeSKtMeta } from "/var/www/hengstyle-frontend/pages/services/components/Category.vue?macro=true";
import { default as CategoryItem4LC1zQUHcwMeta } from "/var/www/hengstyle-frontend/pages/services/components/CategoryItem.vue?macro=true";
import { default as ContactInfo8sqdiDhOJWMeta } from "/var/www/hengstyle-frontend/pages/services/components/ContactInfo.vue?macro=true";
import { default as FaqTc3Knd3iEwMeta } from "/var/www/hengstyle-frontend/pages/services/components/Faq.vue?macro=true";
import { default as FeaturesIVxafSLwpHMeta } from "/var/www/hengstyle-frontend/pages/services/components/Features.vue?macro=true";
import { default as OtherLax5dWTImYMeta } from "/var/www/hengstyle-frontend/pages/services/components/Other.vue?macro=true";
import { default as ProcessgyczGALc2LMeta } from "/var/www/hengstyle-frontend/pages/services/components/Process.vue?macro=true";
import { default as ReviewAcFGzPsvCCMeta } from "/var/www/hengstyle-frontend/pages/services/components/Review.vue?macro=true";
import { default as Relatedz3xk4UOyMMMeta } from "/var/www/hengstyle-frontend/pages/services/faq/article/[id]/components/Related.vue?macro=true";
import { default as indexzcCWYMzv76Meta } from "/var/www/hengstyle-frontend/pages/services/faq/article/[id]/index.vue?macro=true";
import { default as seo2w1tCb3VT8Meta } from "/var/www/hengstyle-frontend/pages/services/faq/article/[id]/seo.ts?macro=true";
import { default as ContactJqDiavcS1HMeta } from "/var/www/hengstyle-frontend/pages/services/faq/components/Contact.vue?macro=true";
import { default as FaqItemB5r0fniWS2Meta } from "/var/www/hengstyle-frontend/pages/services/faq/components/FaqItem.vue?macro=true";
import { default as indexQvoWmH6j7AMeta } from "/var/www/hengstyle-frontend/pages/services/faq/index.vue?macro=true";
import { default as seocpqJObVU6sMeta } from "/var/www/hengstyle-frontend/pages/services/faq/seo.ts?macro=true";
import { default as ContactInfoXCUoU9eDEsMeta } from "/var/www/hengstyle-frontend/pages/services/fast-repair/components/ContactInfo.vue?macro=true";
import { default as Step1h4q1A8rgrqMeta } from "/var/www/hengstyle-frontend/pages/services/fast-repair/components/Step1.vue?macro=true";
import { default as Step21w9sEfd4pKMeta } from "/var/www/hengstyle-frontend/pages/services/fast-repair/components/Step2.vue?macro=true";
import { default as Step3ddbqsVWrxqMeta } from "/var/www/hengstyle-frontend/pages/services/fast-repair/components/Step3.vue?macro=true";
import { default as Step42coRyFNXROMeta } from "/var/www/hengstyle-frontend/pages/services/fast-repair/components/Step4.vue?macro=true";
import { default as form_seoCYVKqQ8UueMeta } from "/var/www/hengstyle-frontend/pages/services/fast-repair/form_seo.ts?macro=true";
import { default as formeoEfkXtxNIMeta } from "/var/www/hengstyle-frontend/pages/services/fast-repair/form.vue?macro=true";
import { default as indexqcPXZSKJ8BMeta } from "/var/www/hengstyle-frontend/pages/services/fast-repair/index.vue?macro=true";
import { default as seon28M5al2b6Meta } from "/var/www/hengstyle-frontend/pages/services/fast-repair/seo.ts?macro=true";
import { default as indexvB76gDmrhOMeta } from "/var/www/hengstyle-frontend/pages/services/index.vue?macro=true";
import { default as Step1LKbUaysTMPMeta } from "/var/www/hengstyle-frontend/pages/services/inhome/components/Step1.vue?macro=true";
import { default as Step2cOsIKw0F5JMeta } from "/var/www/hengstyle-frontend/pages/services/inhome/components/Step2.vue?macro=true";
import { default as form_seo2pJhdy541uMeta } from "/var/www/hengstyle-frontend/pages/services/inhome/form_seo.ts?macro=true";
import { default as formWgDhRg03JrMeta } from "/var/www/hengstyle-frontend/pages/services/inhome/form.vue?macro=true";
import { default as index5LnSr9ovH5Meta } from "/var/www/hengstyle-frontend/pages/services/inhome/index.vue?macro=true";
import { default as seo5VFfuBSbMgMeta } from "/var/www/hengstyle-frontend/pages/services/inhome/seo.ts?macro=true";
import { default as Fast4UDpPbT3uJMeta } from "/var/www/hengstyle-frontend/pages/services/repair/components/Fast.vue?macro=true";
import { default as Step1RMHE9dL9erMeta } from "/var/www/hengstyle-frontend/pages/services/repair/components/Step1.vue?macro=true";
import { default as Step2rezcZOQt7xMeta } from "/var/www/hengstyle-frontend/pages/services/repair/components/Step2.vue?macro=true";
import { default as Step34q7oVlbojEMeta } from "/var/www/hengstyle-frontend/pages/services/repair/components/Step3.vue?macro=true";
import { default as Step4JMBhpR5PhhMeta } from "/var/www/hengstyle-frontend/pages/services/repair/components/Step4.vue?macro=true";
import { default as form_seooGOYK55QRWMeta } from "/var/www/hengstyle-frontend/pages/services/repair/form_seo.ts?macro=true";
import { default as formy8aSmPhCxDMeta } from "/var/www/hengstyle-frontend/pages/services/repair/form.vue?macro=true";
import { default as indexXS5sWZLGvhMeta } from "/var/www/hengstyle-frontend/pages/services/repair/index.vue?macro=true";
import { default as seoOu4pNA8FR8Meta } from "/var/www/hengstyle-frontend/pages/services/repair/seo.ts?macro=true";
import { default as indexOfdxyedWBEMeta } from "/var/www/hengstyle-frontend/pages/services/select/index.vue?macro=true";
import { default as seoCEQbmzmr5lMeta } from "/var/www/hengstyle-frontend/pages/services/select/seo.ts?macro=true";
import { default as seoit4aK29HzXMeta } from "/var/www/hengstyle-frontend/pages/services/seo.ts?macro=true";
import { default as DirectStep1luINBr0vK6Meta } from "/var/www/hengstyle-frontend/pages/services/warranty/components/DirectStep1.vue?macro=true";
import { default as DirectStep2TnmVntb2K6Meta } from "/var/www/hengstyle-frontend/pages/services/warranty/components/DirectStep2.vue?macro=true";
import { default as Step1gm9MZdarsQMeta } from "/var/www/hengstyle-frontend/pages/services/warranty/components/Step1.vue?macro=true";
import { default as Step2MCSgqTq0itMeta } from "/var/www/hengstyle-frontend/pages/services/warranty/components/Step2.vue?macro=true";
import { default as Step3L7c6uv76lHMeta } from "/var/www/hengstyle-frontend/pages/services/warranty/components/Step3.vue?macro=true";
import { default as Step4aHXtxbLdPRMeta } from "/var/www/hengstyle-frontend/pages/services/warranty/components/Step4.vue?macro=true";
import { default as directQBZg2lGIQUMeta } from "/var/www/hengstyle-frontend/pages/services/warranty/direct.vue?macro=true";
import { default as form_seobc2sPHN978Meta } from "/var/www/hengstyle-frontend/pages/services/warranty/form_seo.ts?macro=true";
import { default as formWL93116c9GMeta } from "/var/www/hengstyle-frontend/pages/services/warranty/form.vue?macro=true";
import { default as indexGasTcpUO9SMeta } from "/var/www/hengstyle-frontend/pages/services/warranty/index.vue?macro=true";
import { default as seoXYl1W3mDGYMeta } from "/var/www/hengstyle-frontend/pages/services/warranty/seo.ts?macro=true";
import { default as sitemapCBojKsM0rVMeta } from "/var/www/hengstyle-frontend/pages/sitemap.vue?macro=true";
import { default as _91subId_937AhwtpR3XQMeta } from "/var/www/hengstyle-frontend/pages/sustainability/[mainId]/[subId].vue?macro=true";
import { default as BannerORzG2re4sAMeta } from "/var/www/hengstyle-frontend/pages/sustainability/[mainId]/components/Banner.vue?macro=true";
import { default as Lists2JXSYDp5KRMeta } from "/var/www/hengstyle-frontend/pages/sustainability/[mainId]/components/Lists.vue?macro=true";
import { default as indexVbKh45IHgtMeta } from "/var/www/hengstyle-frontend/pages/sustainability/[mainId]/index.vue?macro=true";
import { default as seoCFsvWCacBKMeta } from "/var/www/hengstyle-frontend/pages/sustainability/[mainId]/seo.ts?macro=true";
import { default as ListsfryCRpJOcpMeta } from "/var/www/hengstyle-frontend/pages/sustainability/article/[id]/components/Lists.vue?macro=true";
import { default as indexsC8e4Bj7sBMeta } from "/var/www/hengstyle-frontend/pages/sustainability/article/[id]/index.vue?macro=true";
import { default as seo2Vpnn5IotrMeta } from "/var/www/hengstyle-frontend/pages/sustainability/article/[id]/seo.ts?macro=true";
import { default as Section1tzaWhaPFfCMeta } from "/var/www/hengstyle-frontend/pages/sustainability/components/Section1.vue?macro=true";
import { default as Section2WKIwl7PId7Meta } from "/var/www/hengstyle-frontend/pages/sustainability/components/Section2.vue?macro=true";
import { default as Section3PX0TcOmT3BMeta } from "/var/www/hengstyle-frontend/pages/sustainability/components/Section3.vue?macro=true";
import { default as Section4OKremLvWHbMeta } from "/var/www/hengstyle-frontend/pages/sustainability/components/Section4.vue?macro=true";
import { default as Section5ZO4WW8ebd2Meta } from "/var/www/hengstyle-frontend/pages/sustainability/components/Section5.vue?macro=true";
import { default as Section6FiQDbqXqojMeta } from "/var/www/hengstyle-frontend/pages/sustainability/components/Section6.vue?macro=true";
import { default as Section7IOJdSoyA61Meta } from "/var/www/hengstyle-frontend/pages/sustainability/components/Section7.vue?macro=true";
import { default as Section83tg1ua4U7CMeta } from "/var/www/hengstyle-frontend/pages/sustainability/components/Section8.vue?macro=true";
import { default as Section9MSMx2ppElhMeta } from "/var/www/hengstyle-frontend/pages/sustainability/components/Section9.vue?macro=true";
import { default as SideZe7mJFdBKJMeta } from "/var/www/hengstyle-frontend/pages/sustainability/components/Side.vue?macro=true";
import { default as indexWF74AoOq7bMeta } from "/var/www/hengstyle-frontend/pages/sustainability/index.vue?macro=true";
import { default as seoz5kx108kNmMeta } from "/var/www/hengstyle-frontend/pages/sustainability/seo.ts?macro=true";
import { default as Section1vpYfZZyzT5Meta } from "/var/www/hengstyle-frontend/pages/talent-development/components/Section1.vue?macro=true";
import { default as Section2DSydfeWTqWMeta } from "/var/www/hengstyle-frontend/pages/talent-development/components/Section2.vue?macro=true";
import { default as Section3UYhAayr5uiMeta } from "/var/www/hengstyle-frontend/pages/talent-development/components/Section3.vue?macro=true";
import { default as Section44ABlnXGOqVMeta } from "/var/www/hengstyle-frontend/pages/talent-development/components/Section4.vue?macro=true";
import { default as Section52CYgEfnIpzMeta } from "/var/www/hengstyle-frontend/pages/talent-development/components/Section5.vue?macro=true";
import { default as indexhQeicdtd1WMeta } from "/var/www/hengstyle-frontend/pages/talent-development/index.vue?macro=true";
import { default as seoPvdjVhkceZMeta } from "/var/www/hengstyle-frontend/pages/talent-development/seo.ts?macro=true";
import { default as BreadcrumbL1t5S3qqTXMeta } from "/var/www/hengstyle-frontend/pages/zonezone/components/Breadcrumb.vue?macro=true";
import { default as Section1G1sZEgxvo6Meta } from "/var/www/hengstyle-frontend/pages/zonezone/components/Section1.vue?macro=true";
import { default as Section2w23S2IcBjBMeta } from "/var/www/hengstyle-frontend/pages/zonezone/components/Section2.vue?macro=true";
import { default as Section3quivwqCQKVMeta } from "/var/www/hengstyle-frontend/pages/zonezone/components/Section3.vue?macro=true";
import { default as Section4HchYs8Doe0Meta } from "/var/www/hengstyle-frontend/pages/zonezone/components/Section4.vue?macro=true";
import { default as Section5S9jLqsnXXzMeta } from "/var/www/hengstyle-frontend/pages/zonezone/components/Section5.vue?macro=true";
import { default as Section6KE6fUU4jU5Meta } from "/var/www/hengstyle-frontend/pages/zonezone/components/Section6.vue?macro=true";
import { default as indexNekgoKTKBvMeta } from "/var/www/hengstyle-frontend/pages/zonezone/index.vue?macro=true";
import { default as seopiSzr3ppPyMeta } from "/var/www/hengstyle-frontend/pages/zonezone/seo.ts?macro=true";
export default [
  {
    name: indexceqy1Qkq3gMeta?.name ?? "blog-blogCategory-blogSubCategory",
    path: indexceqy1Qkq3gMeta?.path ?? "/blog/:blogCategory()/:blogSubCategory()",
    meta: indexceqy1Qkq3gMeta || {},
    alias: indexceqy1Qkq3gMeta?.alias || [],
    redirect: indexceqy1Qkq3gMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/blog/[blogCategory]/[blogSubCategory]/index.vue").then(m => m.default || m)
  },
  {
    name: ClassLinkmts35vA1lqMeta?.name ?? "blog-blogCategory-components-ClassLink",
    path: ClassLinkmts35vA1lqMeta?.path ?? "/blog/:blogCategory()/components/ClassLink",
    meta: ClassLinkmts35vA1lqMeta || {},
    alias: ClassLinkmts35vA1lqMeta?.alias || [],
    redirect: ClassLinkmts35vA1lqMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/blog/[blogCategory]/components/ClassLink.vue").then(m => m.default || m)
  },
  {
    name: ListsJcu9gc61BCMeta?.name ?? "blog-blogCategory-components-Lists",
    path: ListsJcu9gc61BCMeta?.path ?? "/blog/:blogCategory()/components/Lists",
    meta: ListsJcu9gc61BCMeta || {},
    alias: ListsJcu9gc61BCMeta?.alias || [],
    redirect: ListsJcu9gc61BCMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/blog/[blogCategory]/components/Lists.vue").then(m => m.default || m)
  },
  {
    name: TopTnDRjhGli0Meta?.name ?? "blog-blogCategory-components-Top",
    path: TopTnDRjhGli0Meta?.path ?? "/blog/:blogCategory()/components/Top",
    meta: TopTnDRjhGli0Meta || {},
    alias: TopTnDRjhGli0Meta?.alias || [],
    redirect: TopTnDRjhGli0Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/blog/[blogCategory]/components/Top.vue").then(m => m.default || m)
  },
  {
    name: index3zTqrfUw9RMeta?.name ?? "blog-blogCategory",
    path: index3zTqrfUw9RMeta?.path ?? "/blog/:blogCategory()",
    meta: index3zTqrfUw9RMeta || {},
    alias: index3zTqrfUw9RMeta?.alias || [],
    redirect: index3zTqrfUw9RMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/blog/[blogCategory]/index.vue").then(m => m.default || m)
  },
  {
    name: seo10eh5MDvVWMeta?.name ?? "blog-blogCategory-seo",
    path: seo10eh5MDvVWMeta?.path ?? "/blog/:blogCategory()/seo",
    meta: seo10eh5MDvVWMeta || {},
    alias: seo10eh5MDvVWMeta?.alias || [],
    redirect: seo10eh5MDvVWMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/blog/[blogCategory]/seo.ts").then(m => m.default || m)
  },
  {
    name: ArticleRnBZBlTp64Meta?.name ?? "blog-article-slug-components-Article",
    path: ArticleRnBZBlTp64Meta?.path ?? "/blog/article/:slug()/components/Article",
    meta: ArticleRnBZBlTp64Meta || {},
    alias: ArticleRnBZBlTp64Meta?.alias || [],
    redirect: ArticleRnBZBlTp64Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/blog/article/[slug]/components/Article.vue").then(m => m.default || m)
  },
  {
    name: ClassLinkxCOthZug6ZMeta?.name ?? "blog-article-slug-components-ClassLink",
    path: ClassLinkxCOthZug6ZMeta?.path ?? "/blog/article/:slug()/components/ClassLink",
    meta: ClassLinkxCOthZug6ZMeta || {},
    alias: ClassLinkxCOthZug6ZMeta?.alias || [],
    redirect: ClassLinkxCOthZug6ZMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/blog/article/[slug]/components/ClassLink.vue").then(m => m.default || m)
  },
  {
    name: index6HOBdNbQa0Meta?.name ?? "blog-article-slug",
    path: index6HOBdNbQa0Meta?.path ?? "/blog/article/:slug()",
    meta: index6HOBdNbQa0Meta || {},
    alias: index6HOBdNbQa0Meta?.alias || [],
    redirect: index6HOBdNbQa0Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/blog/article/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: seoBDGZwWHEdBMeta?.name ?? "blog-article-slug-seo",
    path: seoBDGZwWHEdBMeta?.path ?? "/blog/article/:slug()/seo",
    meta: seoBDGZwWHEdBMeta || {},
    alias: seoBDGZwWHEdBMeta?.alias || [],
    redirect: seoBDGZwWHEdBMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/blog/article/[slug]/seo.ts").then(m => m.default || m)
  },
  {
    name: indexf7aFDjDisaMeta?.name ?? "blog",
    path: indexf7aFDjDisaMeta?.path ?? "/blog",
    meta: indexf7aFDjDisaMeta || {},
    alias: indexf7aFDjDisaMeta?.alias || [],
    redirect: indexf7aFDjDisaMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: seoCySapJrNIjMeta?.name ?? "blog-seo",
    path: seoCySapJrNIjMeta?.path ?? "/blog/seo",
    meta: seoCySapJrNIjMeta || {},
    alias: seoCySapJrNIjMeta?.alias || [],
    redirect: seoCySapJrNIjMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/blog/seo.ts").then(m => m.default || m)
  },
  {
    name: Banner6R8V6NrIeOMeta?.name ?? "brand-story-components-Banner",
    path: Banner6R8V6NrIeOMeta?.path ?? "/brand-story/components/Banner",
    meta: Banner6R8V6NrIeOMeta || {},
    alias: Banner6R8V6NrIeOMeta?.alias || [],
    redirect: Banner6R8V6NrIeOMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brand-story/components/Banner.vue").then(m => m.default || m)
  },
  {
    name: BrandsmPlL6S1tC2Meta?.name ?? "brand-story-components-Brands",
    path: BrandsmPlL6S1tC2Meta?.path ?? "/brand-story/components/Brands",
    meta: BrandsmPlL6S1tC2Meta || {},
    alias: BrandsmPlL6S1tC2Meta?.alias || [],
    redirect: BrandsmPlL6S1tC2Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brand-story/components/Brands.vue").then(m => m.default || m)
  },
  {
    name: CardBoxmgkZh1DuFuMeta?.name ?? "brand-story-components-CardBox",
    path: CardBoxmgkZh1DuFuMeta?.path ?? "/brand-story/components/CardBox",
    meta: CardBoxmgkZh1DuFuMeta || {},
    alias: CardBoxmgkZh1DuFuMeta?.alias || [],
    redirect: CardBoxmgkZh1DuFuMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brand-story/components/CardBox.vue").then(m => m.default || m)
  },
  {
    name: Chairman8kz0azT26vMeta?.name ?? "brand-story-components-Chairman",
    path: Chairman8kz0azT26vMeta?.path ?? "/brand-story/components/Chairman",
    meta: Chairman8kz0azT26vMeta || {},
    alias: Chairman8kz0azT26vMeta?.alias || [],
    redirect: Chairman8kz0azT26vMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brand-story/components/Chairman.vue").then(m => m.default || m)
  },
  {
    name: ContactXpxOqfNX1kMeta?.name ?? "brand-story-components-Contact",
    path: ContactXpxOqfNX1kMeta?.path ?? "/brand-story/components/Contact",
    meta: ContactXpxOqfNX1kMeta || {},
    alias: ContactXpxOqfNX1kMeta?.alias || [],
    redirect: ContactXpxOqfNX1kMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brand-story/components/Contact.vue").then(m => m.default || m)
  },
  {
    name: ExploreJXAqUMNCyOMeta?.name ?? "brand-story-components-Explore",
    path: ExploreJXAqUMNCyOMeta?.path ?? "/brand-story/components/Explore",
    meta: ExploreJXAqUMNCyOMeta || {},
    alias: ExploreJXAqUMNCyOMeta?.alias || [],
    redirect: ExploreJXAqUMNCyOMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brand-story/components/Explore.vue").then(m => m.default || m)
  },
  {
    name: MilestoneDsUVhDsgUDMeta?.name ?? "brand-story-components-Milestone",
    path: MilestoneDsUVhDsgUDMeta?.path ?? "/brand-story/components/Milestone",
    meta: MilestoneDsUVhDsgUDMeta || {},
    alias: MilestoneDsUVhDsgUDMeta?.alias || [],
    redirect: MilestoneDsUVhDsgUDMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brand-story/components/Milestone.vue").then(m => m.default || m)
  },
  {
    name: ObserveIRd9lmCODcMeta?.name ?? "brand-story-components-Observe",
    path: ObserveIRd9lmCODcMeta?.path ?? "/brand-story/components/Observe",
    meta: ObserveIRd9lmCODcMeta || {},
    alias: ObserveIRd9lmCODcMeta?.alias || [],
    redirect: ObserveIRd9lmCODcMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brand-story/components/Observe.vue").then(m => m.default || m)
  },
  {
    name: ServicekkzzsHZj9WMeta?.name ?? "brand-story-components-Service",
    path: ServicekkzzsHZj9WMeta?.path ?? "/brand-story/components/Service",
    meta: ServicekkzzsHZj9WMeta || {},
    alias: ServicekkzzsHZj9WMeta?.alias || [],
    redirect: ServicekkzzsHZj9WMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brand-story/components/Service.vue").then(m => m.default || m)
  },
  {
    name: indexKHUeLD2s1SMeta?.name ?? "brand-story",
    path: indexKHUeLD2s1SMeta?.path ?? "/brand-story",
    meta: indexKHUeLD2s1SMeta || {},
    alias: indexKHUeLD2s1SMeta?.alias || [],
    redirect: indexKHUeLD2s1SMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brand-story/index.vue").then(m => m.default || m)
  },
  {
    name: seoOarEqMQE8zMeta?.name ?? "brand-story-seo",
    path: seoOarEqMQE8zMeta?.path ?? "/brand-story/seo",
    meta: seoOarEqMQE8zMeta || {},
    alias: seoOarEqMQE8zMeta?.alias || [],
    redirect: seoOarEqMQE8zMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brand-story/seo.ts").then(m => m.default || m)
  },
  {
    name: _91brandId_93At18asAQoYMeta?.name ?? "brand-brandId",
    path: _91brandId_93At18asAQoYMeta?.path ?? "/brand/:brandId()",
    meta: _91brandId_93At18asAQoYMeta || {},
    alias: _91brandId_93At18asAQoYMeta?.alias || [],
    redirect: _91brandId_93At18asAQoYMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brand/[brandId].vue").then(m => m.default || m)
  },
  {
    name: BrandActiveoCDjpGKaAhMeta?.name ?? "brand-components-BrandActive",
    path: BrandActiveoCDjpGKaAhMeta?.path ?? "/brand/components/BrandActive",
    meta: BrandActiveoCDjpGKaAhMeta || {},
    alias: BrandActiveoCDjpGKaAhMeta?.alias || [],
    redirect: BrandActiveoCDjpGKaAhMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brand/components/BrandActive.vue").then(m => m.default || m)
  },
  {
    name: BrandSlideA8Vr7M5saiMeta?.name ?? "brand-components-BrandSlide",
    path: BrandSlideA8Vr7M5saiMeta?.path ?? "/brand/components/BrandSlide",
    meta: BrandSlideA8Vr7M5saiMeta || {},
    alias: BrandSlideA8Vr7M5saiMeta?.alias || [],
    redirect: BrandSlideA8Vr7M5saiMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brand/components/BrandSlide.vue").then(m => m.default || m)
  },
  {
    name: seonZbNNmJwLYMeta?.name ?? "brand-seo",
    path: seonZbNNmJwLYMeta?.path ?? "/brand/seo",
    meta: seonZbNNmJwLYMeta || {},
    alias: seonZbNNmJwLYMeta?.alias || [],
    redirect: seonZbNNmJwLYMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brand/seo.ts").then(m => m.default || m)
  },
  {
    name: BrandCategory3tESwa7HsmMeta?.name ?? "brands-brandCategory-components-BrandCategory",
    path: BrandCategory3tESwa7HsmMeta?.path ?? "/brands/:brandCategory()/components/BrandCategory",
    meta: BrandCategory3tESwa7HsmMeta || {},
    alias: BrandCategory3tESwa7HsmMeta?.alias || [],
    redirect: BrandCategory3tESwa7HsmMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brands/[brandCategory]/components/BrandCategory.vue").then(m => m.default || m)
  },
  {
    name: BrandList9ojry0aeyfMeta?.name ?? "brands-brandCategory-components-BrandList",
    path: BrandList9ojry0aeyfMeta?.path ?? "/brands/:brandCategory()/components/BrandList",
    meta: BrandList9ojry0aeyfMeta || {},
    alias: BrandList9ojry0aeyfMeta?.alias || [],
    redirect: BrandList9ojry0aeyfMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brands/[brandCategory]/components/BrandList.vue").then(m => m.default || m)
  },
  {
    name: indexAxXCvTtwDFMeta?.name ?? "brands-brandCategory",
    path: indexAxXCvTtwDFMeta?.path ?? "/brands/:brandCategory()",
    meta: indexAxXCvTtwDFMeta || {},
    alias: indexAxXCvTtwDFMeta?.alias || [],
    redirect: indexAxXCvTtwDFMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brands/[brandCategory]/index.vue").then(m => m.default || m)
  },
  {
    name: seo61E54g5rgAMeta?.name ?? "brands-brandCategory-seo",
    path: seo61E54g5rgAMeta?.path ?? "/brands/:brandCategory()/seo",
    meta: seo61E54g5rgAMeta || {},
    alias: seo61E54g5rgAMeta?.alias || [],
    redirect: seo61E54g5rgAMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brands/[brandCategory]/seo.ts").then(m => m.default || m)
  },
  {
    name: index6G88uUMCNuMeta?.name ?? "brands",
    path: index6G88uUMCNuMeta?.path ?? "/brands",
    meta: index6G88uUMCNuMeta || {},
    alias: index6G88uUMCNuMeta?.alias || [],
    redirect: index6G88uUMCNuMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: complaints_45infooCIck91gYMMeta?.name ?? "complaints-info",
    path: complaints_45infooCIck91gYMMeta?.path ?? "/complaints-info",
    meta: complaints_45infooCIck91gYMMeta || {},
    alias: complaints_45infooCIck91gYMMeta?.alias || [],
    redirect: complaints_45infooCIck91gYMMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/complaints-info.vue").then(m => m.default || m)
  },
  {
    name: baseTvqPD1qUD7Meta?.name ?? "contact-base",
    path: baseTvqPD1qUD7Meta?.path ?? "/contact/base",
    meta: baseTvqPD1qUD7Meta || {},
    alias: baseTvqPD1qUD7Meta?.alias || [],
    redirect: baseTvqPD1qUD7Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/contact/base.vue").then(m => m.default || m)
  },
  {
    name: indexAg9Dkd3iOmMeta?.name ?? "contact",
    path: indexAg9Dkd3iOmMeta?.path ?? "/contact",
    meta: indexAg9Dkd3iOmMeta || {},
    alias: indexAg9Dkd3iOmMeta?.alias || [],
    redirect: indexAg9Dkd3iOmMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: seojMA4HTOXpVMeta?.name ?? "contact-seo",
    path: seojMA4HTOXpVMeta?.path ?? "/contact/seo",
    meta: seojMA4HTOXpVMeta || {},
    alias: seojMA4HTOXpVMeta?.alias || [],
    redirect: seojMA4HTOXpVMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/contact/seo.ts").then(m => m.default || m)
  },
  {
    name: step2Kk20g4FkRDMeta?.name ?? "contact-step2",
    path: step2Kk20g4FkRDMeta?.path ?? "/contact/step2",
    meta: step2Kk20g4FkRDMeta || {},
    alias: step2Kk20g4FkRDMeta?.alias || [],
    redirect: step2Kk20g4FkRDMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/contact/step2.vue").then(m => m.default || m)
  },
  {
    name: step32QWCiLmZusMeta?.name ?? "contact-step3",
    path: step32QWCiLmZusMeta?.path ?? "/contact/step3",
    meta: step32QWCiLmZusMeta || {},
    alias: step32QWCiLmZusMeta?.alias || [],
    redirect: step32QWCiLmZusMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/contact/step3.vue").then(m => m.default || m)
  },
  {
    name: ContentR0K2AOpsF4Meta?.name ?? "events-eventId-apply-components-Content",
    path: ContentR0K2AOpsF4Meta?.path ?? "/events/:eventId()/apply/components/Content",
    meta: ContentR0K2AOpsF4Meta || {},
    alias: ContentR0K2AOpsF4Meta?.alias || [],
    redirect: ContentR0K2AOpsF4Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/[eventId]/apply/components/Content.vue").then(m => m.default || m)
  },
  {
    name: RadioItem4FwDqmUtSJMeta?.name ?? "events-eventId-apply-components-RadioItem",
    path: RadioItem4FwDqmUtSJMeta?.path ?? "/events/:eventId()/apply/components/RadioItem",
    meta: RadioItem4FwDqmUtSJMeta || {},
    alias: RadioItem4FwDqmUtSJMeta?.alias || [],
    redirect: RadioItem4FwDqmUtSJMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/[eventId]/apply/components/RadioItem.vue").then(m => m.default || m)
  },
  {
    name: StepooFRSR0FwVMeta?.name ?? "events-eventId-apply-components-Step",
    path: StepooFRSR0FwVMeta?.path ?? "/events/:eventId()/apply/components/Step",
    meta: StepooFRSR0FwVMeta || {},
    alias: StepooFRSR0FwVMeta?.alias || [],
    redirect: StepooFRSR0FwVMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/[eventId]/apply/components/Step.vue").then(m => m.default || m)
  },
  {
    name: Step1ObdubmyjE9Meta?.name ?? "events-eventId-apply-components-Step1",
    path: Step1ObdubmyjE9Meta?.path ?? "/events/:eventId()/apply/components/Step1",
    meta: Step1ObdubmyjE9Meta || {},
    alias: Step1ObdubmyjE9Meta?.alias || [],
    redirect: Step1ObdubmyjE9Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/[eventId]/apply/components/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step2IjujzpaRuiMeta?.name ?? "events-eventId-apply-components-Step2",
    path: Step2IjujzpaRuiMeta?.path ?? "/events/:eventId()/apply/components/Step2",
    meta: Step2IjujzpaRuiMeta || {},
    alias: Step2IjujzpaRuiMeta?.alias || [],
    redirect: Step2IjujzpaRuiMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/[eventId]/apply/components/Step2.vue").then(m => m.default || m)
  },
  {
    name: Step3roUGGzK6kUMeta?.name ?? "events-eventId-apply-components-Step3",
    path: Step3roUGGzK6kUMeta?.path ?? "/events/:eventId()/apply/components/Step3",
    meta: Step3roUGGzK6kUMeta || {},
    alias: Step3roUGGzK6kUMeta?.alias || [],
    redirect: Step3roUGGzK6kUMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/[eventId]/apply/components/Step3.vue").then(m => m.default || m)
  },
  {
    name: Step4lHBnCZMXraMeta?.name ?? "events-eventId-apply-components-Step4",
    path: Step4lHBnCZMXraMeta?.path ?? "/events/:eventId()/apply/components/Step4",
    meta: Step4lHBnCZMXraMeta || {},
    alias: Step4lHBnCZMXraMeta?.alias || [],
    redirect: Step4lHBnCZMXraMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/[eventId]/apply/components/Step4.vue").then(m => m.default || m)
  },
  {
    name: indexJGFUK1CcoqMeta?.name ?? "events-eventId-apply",
    path: indexJGFUK1CcoqMeta?.path ?? "/events/:eventId()/apply",
    meta: indexJGFUK1CcoqMeta || {},
    alias: indexJGFUK1CcoqMeta?.alias || [],
    redirect: indexJGFUK1CcoqMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/[eventId]/apply/index.vue").then(m => m.default || m)
  },
  {
    name: seoCyNw8h3vYdMeta?.name ?? "events-eventId-apply-seo",
    path: seoCyNw8h3vYdMeta?.path ?? "/events/:eventId()/apply/seo",
    meta: seoCyNw8h3vYdMeta || {},
    alias: seoCyNw8h3vYdMeta?.alias || [],
    redirect: seoCyNw8h3vYdMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/[eventId]/apply/seo.ts").then(m => m.default || m)
  },
  {
    name: ContentATd4ECOEdIMeta?.name ?? "events-eventId-components-Content",
    path: ContentATd4ECOEdIMeta?.path ?? "/events/:eventId()/components/Content",
    meta: ContentATd4ECOEdIMeta || {},
    alias: ContentATd4ECOEdIMeta?.alias || [],
    redirect: ContentATd4ECOEdIMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/[eventId]/components/Content.vue").then(m => m.default || m)
  },
  {
    name: FaqI40JoenyJAMeta?.name ?? "events-eventId-components-Faq",
    path: FaqI40JoenyJAMeta?.path ?? "/events/:eventId()/components/Faq",
    meta: FaqI40JoenyJAMeta || {},
    alias: FaqI40JoenyJAMeta?.alias || [],
    redirect: FaqI40JoenyJAMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/[eventId]/components/Faq.vue").then(m => m.default || m)
  },
  {
    name: SideONB4Emhw4QMeta?.name ?? "events-eventId-components-Side",
    path: SideONB4Emhw4QMeta?.path ?? "/events/:eventId()/components/Side",
    meta: SideONB4Emhw4QMeta || {},
    alias: SideONB4Emhw4QMeta?.alias || [],
    redirect: SideONB4Emhw4QMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/[eventId]/components/Side.vue").then(m => m.default || m)
  },
  {
    name: SideMobilerfNR8gEU2QMeta?.name ?? "events-eventId-components-SideMobile",
    path: SideMobilerfNR8gEU2QMeta?.path ?? "/events/:eventId()/components/SideMobile",
    meta: SideMobilerfNR8gEU2QMeta || {},
    alias: SideMobilerfNR8gEU2QMeta?.alias || [],
    redirect: SideMobilerfNR8gEU2QMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/[eventId]/components/SideMobile.vue").then(m => m.default || m)
  },
  {
    name: SlideWhxa80HtAjMeta?.name ?? "events-eventId-components-Slide",
    path: SlideWhxa80HtAjMeta?.path ?? "/events/:eventId()/components/Slide",
    meta: SlideWhxa80HtAjMeta || {},
    alias: SlideWhxa80HtAjMeta?.alias || [],
    redirect: SlideWhxa80HtAjMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/[eventId]/components/Slide.vue").then(m => m.default || m)
  },
  {
    name: indexKB56IFTxLCMeta?.name ?? "events-eventId",
    path: indexKB56IFTxLCMeta?.path ?? "/events/:eventId()",
    meta: indexKB56IFTxLCMeta || {},
    alias: indexKB56IFTxLCMeta?.alias || [],
    redirect: indexKB56IFTxLCMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/[eventId]/index.vue").then(m => m.default || m)
  },
  {
    name: seocZ4xNY6E15Meta?.name ?? "events-eventId-seo",
    path: seocZ4xNY6E15Meta?.path ?? "/events/:eventId()/seo",
    meta: seocZ4xNY6E15Meta || {},
    alias: seocZ4xNY6E15Meta?.alias || [],
    redirect: seocZ4xNY6E15Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/[eventId]/seo.ts").then(m => m.default || m)
  },
  {
    name: Dropy979ImYjpXMeta?.name ?? "events-components-Drop",
    path: Dropy979ImYjpXMeta?.path ?? "/events/components/Drop",
    meta: Dropy979ImYjpXMeta || {},
    alias: Dropy979ImYjpXMeta?.alias || [],
    redirect: Dropy979ImYjpXMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/components/Drop.vue").then(m => m.default || m)
  },
  {
    name: indexVMJiSJBICWMeta?.name ?? "events",
    path: indexVMJiSJBICWMeta?.path ?? "/events",
    meta: indexVMJiSJBICWMeta || {},
    alias: indexVMJiSJBICWMeta?.alias || [],
    redirect: indexVMJiSJBICWMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: seo3Da4CfgXHPMeta?.name ?? "events-seo",
    path: seo3Da4CfgXHPMeta?.path ?? "/events/seo",
    meta: seo3Da4CfgXHPMeta || {},
    alias: seo3Da4CfgXHPMeta?.alias || [],
    redirect: seo3Da4CfgXHPMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/events/seo.ts").then(m => m.default || m)
  },
  {
    name: indexRGDB1IgcXIMeta?.name ?? "giftchoose-brand-hs-channel-id",
    path: indexRGDB1IgcXIMeta?.path ?? "/giftchoose/brand-hs/channel-:id()",
    meta: indexRGDB1IgcXIMeta || {},
    alias: indexRGDB1IgcXIMeta?.alias || [],
    redirect: indexRGDB1IgcXIMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/giftchoose/brand-hs/channel-[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZmbFYidkrlMeta?.name ?? "giftchoose-brand-hs",
    path: indexZmbFYidkrlMeta?.path ?? "/giftchoose/brand-hs",
    meta: indexZmbFYidkrlMeta || {},
    alias: indexZmbFYidkrlMeta?.alias || [],
    redirect: indexZmbFYidkrlMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/giftchoose/brand-hs/index.vue").then(m => m.default || m)
  },
  {
    name: indexHzQq8EJIznMeta?.name ?? "giftchoose-brand-hs-menu-id",
    path: indexHzQq8EJIznMeta?.path ?? "/giftchoose/brand-hs/menu-:id()",
    meta: indexHzQq8EJIznMeta || {},
    alias: indexHzQq8EJIznMeta?.alias || [],
    redirect: indexHzQq8EJIznMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/giftchoose/brand-hs/menu-[id]/index.vue").then(m => m.default || m)
  },
  {
    name: applyQOGGV2sy6cMeta?.name ?? "giftmain-brand-hs-giftid-id-apply",
    path: applyQOGGV2sy6cMeta?.path ?? "/giftmain/brand-hs/giftid-:id()/apply",
    meta: applyQOGGV2sy6cMeta || {},
    alias: applyQOGGV2sy6cMeta?.alias || [],
    redirect: applyQOGGV2sy6cMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/apply.vue").then(m => m.default || m)
  },
  {
    name: MobileBottom8rPfQc3MNVMeta?.name ?? "giftmain-brand-hs-giftid-id-components-MobileBottom",
    path: MobileBottom8rPfQc3MNVMeta?.path ?? "/giftmain/brand-hs/giftid-:id()/components/MobileBottom",
    meta: MobileBottom8rPfQc3MNVMeta || {},
    alias: MobileBottom8rPfQc3MNVMeta?.alias || [],
    redirect: MobileBottom8rPfQc3MNVMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/MobileBottom.vue").then(m => m.default || m)
  },
  {
    name: StepqheTwxvGT4Meta?.name ?? "giftmain-brand-hs-giftid-id-components-Step",
    path: StepqheTwxvGT4Meta?.path ?? "/giftmain/brand-hs/giftid-:id()/components/Step",
    meta: StepqheTwxvGT4Meta || {},
    alias: StepqheTwxvGT4Meta?.alias || [],
    redirect: StepqheTwxvGT4Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step.vue").then(m => m.default || m)
  },
  {
    name: Step1C1OzPqQgLfMeta?.name ?? "giftmain-brand-hs-giftid-id-components-Step1",
    path: Step1C1OzPqQgLfMeta?.path ?? "/giftmain/brand-hs/giftid-:id()/components/Step1",
    meta: Step1C1OzPqQgLfMeta || {},
    alias: Step1C1OzPqQgLfMeta?.alias || [],
    redirect: Step1C1OzPqQgLfMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step2bVDn8EvlpsMeta?.name ?? "giftmain-brand-hs-giftid-id-components-Step2",
    path: Step2bVDn8EvlpsMeta?.path ?? "/giftmain/brand-hs/giftid-:id()/components/Step2",
    meta: Step2bVDn8EvlpsMeta || {},
    alias: Step2bVDn8EvlpsMeta?.alias || [],
    redirect: Step2bVDn8EvlpsMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step2.vue").then(m => m.default || m)
  },
  {
    name: Step36J1cSr1qzuMeta?.name ?? "giftmain-brand-hs-giftid-id-components-Step3",
    path: Step36J1cSr1qzuMeta?.path ?? "/giftmain/brand-hs/giftid-:id()/components/Step3",
    meta: Step36J1cSr1qzuMeta || {},
    alias: Step36J1cSr1qzuMeta?.alias || [],
    redirect: Step36J1cSr1qzuMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step3.vue").then(m => m.default || m)
  },
  {
    name: Step4mWzlnBL3A7Meta?.name ?? "giftmain-brand-hs-giftid-id-components-Step4",
    path: Step4mWzlnBL3A7Meta?.path ?? "/giftmain/brand-hs/giftid-:id()/components/Step4",
    meta: Step4mWzlnBL3A7Meta || {},
    alias: Step4mWzlnBL3A7Meta?.alias || [],
    redirect: Step4mWzlnBL3A7Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/components/Step4.vue").then(m => m.default || m)
  },
  {
    name: index2MnjJnYO0QMeta?.name ?? "giftmain-brand-hs-giftid-id",
    path: index2MnjJnYO0QMeta?.path ?? "/giftmain/brand-hs/giftid-:id()",
    meta: index2MnjJnYO0QMeta || {},
    alias: index2MnjJnYO0QMeta?.alias || [],
    redirect: index2MnjJnYO0QMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/index.vue").then(m => m.default || m)
  },
  {
    name: seoTcNhuNG3fiMeta?.name ?? "giftmain-brand-hs-giftid-id-seo",
    path: seoTcNhuNG3fiMeta?.path ?? "/giftmain/brand-hs/giftid-:id()/seo",
    meta: seoTcNhuNG3fiMeta || {},
    alias: seoTcNhuNG3fiMeta?.alias || [],
    redirect: seoTcNhuNG3fiMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/giftmain/brand-hs/giftid-[id]/seo.ts").then(m => m.default || m)
  },
  {
    name: gsapGV4AGyY9BuMeta?.name ?? "gsap",
    path: gsapGV4AGyY9BuMeta?.path ?? "/gsap",
    meta: gsapGV4AGyY9BuMeta || {},
    alias: gsapGV4AGyY9BuMeta?.alias || [],
    redirect: gsapGV4AGyY9BuMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/gsap.vue").then(m => m.default || m)
  },
  {
    name: ListGR2vP4kYIDMeta?.name ?? "hence-categoryId-components-List",
    path: ListGR2vP4kYIDMeta?.path ?? "/hence/:categoryId()/components/List",
    meta: ListGR2vP4kYIDMeta || {},
    alias: ListGR2vP4kYIDMeta?.alias || [],
    redirect: ListGR2vP4kYIDMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/[categoryId]/components/List.vue").then(m => m.default || m)
  },
  {
    name: indexHG6bGxrfFEMeta?.name ?? "hence-categoryId",
    path: indexHG6bGxrfFEMeta?.path ?? "/hence/:categoryId()",
    meta: indexHG6bGxrfFEMeta || {},
    alias: indexHG6bGxrfFEMeta?.alias || [],
    redirect: indexHG6bGxrfFEMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/[categoryId]/index.vue").then(m => m.default || m)
  },
  {
    name: seohnO0SgEEcpMeta?.name ?? "hence-categoryId-seo",
    path: seohnO0SgEEcpMeta?.path ?? "/hence/:categoryId()/seo",
    meta: seohnO0SgEEcpMeta || {},
    alias: seohnO0SgEEcpMeta?.alias || [],
    redirect: seohnO0SgEEcpMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/[categoryId]/seo.ts").then(m => m.default || m)
  },
  {
    name: _91id_930CPKPYjJXcMeta?.name ?? "hence-article-id",
    path: _91id_930CPKPYjJXcMeta?.path ?? "/hence/article/:id()",
    meta: _91id_930CPKPYjJXcMeta || {},
    alias: _91id_930CPKPYjJXcMeta?.alias || [],
    redirect: _91id_930CPKPYjJXcMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/article/[id].vue").then(m => m.default || m)
  },
  {
    name: Content496mQrbBx1Meta?.name ?? "hence-article-components-Content",
    path: Content496mQrbBx1Meta?.path ?? "/hence/article/components/Content",
    meta: Content496mQrbBx1Meta || {},
    alias: Content496mQrbBx1Meta?.alias || [],
    redirect: Content496mQrbBx1Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/article/components/Content.vue").then(m => m.default || m)
  },
  {
    name: ListEikr9oDDAtMeta?.name ?? "hence-article-components-List",
    path: ListEikr9oDDAtMeta?.path ?? "/hence/article/components/List",
    meta: ListEikr9oDDAtMeta || {},
    alias: ListEikr9oDDAtMeta?.alias || [],
    redirect: ListEikr9oDDAtMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/article/components/List.vue").then(m => m.default || m)
  },
  {
    name: seocB4AwHlVWJMeta?.name ?? "hence-article-seo",
    path: seocB4AwHlVWJMeta?.path ?? "/hence/article/seo",
    meta: seocB4AwHlVWJMeta || {},
    alias: seocB4AwHlVWJMeta?.alias || [],
    redirect: seocB4AwHlVWJMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/article/seo.ts").then(m => m.default || m)
  },
  {
    name: _91id_93fgz9yzC0jyMeta?.name ?? "hence-author-id",
    path: _91id_93fgz9yzC0jyMeta?.path ?? "/hence/author/:id()",
    meta: _91id_93fgz9yzC0jyMeta || {},
    alias: _91id_93fgz9yzC0jyMeta?.alias || [],
    redirect: _91id_93fgz9yzC0jyMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/author/[id].vue").then(m => m.default || m)
  },
  {
    name: Authorzz75WKdRKqMeta?.name ?? "hence-author-components-Author",
    path: Authorzz75WKdRKqMeta?.path ?? "/hence/author/components/Author",
    meta: Authorzz75WKdRKqMeta || {},
    alias: Authorzz75WKdRKqMeta?.alias || [],
    redirect: Authorzz75WKdRKqMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/author/components/Author.vue").then(m => m.default || m)
  },
  {
    name: ListT3Ip4sKiO4Meta?.name ?? "hence-author-components-List",
    path: ListT3Ip4sKiO4Meta?.path ?? "/hence/author/components/List",
    meta: ListT3Ip4sKiO4Meta || {},
    alias: ListT3Ip4sKiO4Meta?.alias || [],
    redirect: ListT3Ip4sKiO4Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/author/components/List.vue").then(m => m.default || m)
  },
  {
    name: seohLgqWlPXndMeta?.name ?? "hence-author-seo",
    path: seohLgqWlPXndMeta?.path ?? "/hence/author/seo",
    meta: seohLgqWlPXndMeta || {},
    alias: seohLgqWlPXndMeta?.alias || [],
    redirect: seohLgqWlPXndMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/author/seo.ts").then(m => m.default || m)
  },
  {
    name: AdXhdP95S6vWMeta?.name ?? "hence-components-Ad",
    path: AdXhdP95S6vWMeta?.path ?? "/hence/components/Ad",
    meta: AdXhdP95S6vWMeta || {},
    alias: AdXhdP95S6vWMeta?.alias || [],
    redirect: AdXhdP95S6vWMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/components/Ad.vue").then(m => m.default || m)
  },
  {
    name: ArticlerORZJEZiSoMeta?.name ?? "hence-components-Article",
    path: ArticlerORZJEZiSoMeta?.path ?? "/hence/components/Article",
    meta: ArticlerORZJEZiSoMeta || {},
    alias: ArticlerORZJEZiSoMeta?.alias || [],
    redirect: ArticlerORZJEZiSoMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/components/Article.vue").then(m => m.default || m)
  },
  {
    name: AuthorFZqhI63i8mMeta?.name ?? "hence-components-Author",
    path: AuthorFZqhI63i8mMeta?.path ?? "/hence/components/Author",
    meta: AuthorFZqhI63i8mMeta || {},
    alias: AuthorFZqhI63i8mMeta?.alias || [],
    redirect: AuthorFZqhI63i8mMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/components/Author.vue").then(m => m.default || m)
  },
  {
    name: BannerR6okoGUoNBMeta?.name ?? "hence-components-Banner",
    path: BannerR6okoGUoNBMeta?.path ?? "/hence/components/Banner",
    meta: BannerR6okoGUoNBMeta || {},
    alias: BannerR6okoGUoNBMeta?.alias || [],
    redirect: BannerR6okoGUoNBMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/components/Banner.vue").then(m => m.default || m)
  },
  {
    name: CardItemhLYKEY0LlHMeta?.name ?? "hence-components-CardItem",
    path: CardItemhLYKEY0LlHMeta?.path ?? "/hence/components/CardItem",
    meta: CardItemhLYKEY0LlHMeta || {},
    alias: CardItemhLYKEY0LlHMeta?.alias || [],
    redirect: CardItemhLYKEY0LlHMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/components/CardItem.vue").then(m => m.default || m)
  },
  {
    name: Experienceu7G81WOyHOMeta?.name ?? "hence-components-Experience",
    path: Experienceu7G81WOyHOMeta?.path ?? "/hence/components/Experience",
    meta: Experienceu7G81WOyHOMeta || {},
    alias: Experienceu7G81WOyHOMeta?.alias || [],
    redirect: Experienceu7G81WOyHOMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/components/Experience.vue").then(m => m.default || m)
  },
  {
    name: HenceTextNG0MqAWbpjMeta?.name ?? "hence-components-HenceText",
    path: HenceTextNG0MqAWbpjMeta?.path ?? "/hence/components/HenceText",
    meta: HenceTextNG0MqAWbpjMeta || {},
    alias: HenceTextNG0MqAWbpjMeta?.alias || [],
    redirect: HenceTextNG0MqAWbpjMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/components/HenceText.vue").then(m => m.default || m)
  },
  {
    name: List137Fksk1j7WMeta?.name ?? "hence-components-List1",
    path: List137Fksk1j7WMeta?.path ?? "/hence/components/List1",
    meta: List137Fksk1j7WMeta || {},
    alias: List137Fksk1j7WMeta?.alias || [],
    redirect: List137Fksk1j7WMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/components/List1.vue").then(m => m.default || m)
  },
  {
    name: List21dH5J9tOviMeta?.name ?? "hence-components-List2",
    path: List21dH5J9tOviMeta?.path ?? "/hence/components/List2",
    meta: List21dH5J9tOviMeta || {},
    alias: List21dH5J9tOviMeta?.alias || [],
    redirect: List21dH5J9tOviMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/components/List2.vue").then(m => m.default || m)
  },
  {
    name: List3yWDhZjfOYmMeta?.name ?? "hence-components-List3",
    path: List3yWDhZjfOYmMeta?.path ?? "/hence/components/List3",
    meta: List3yWDhZjfOYmMeta || {},
    alias: List3yWDhZjfOYmMeta?.alias || [],
    redirect: List3yWDhZjfOYmMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/components/List3.vue").then(m => m.default || m)
  },
  {
    name: NewsletteryIDPBAipHgMeta?.name ?? "hence-components-Newsletter",
    path: NewsletteryIDPBAipHgMeta?.path ?? "/hence/components/Newsletter",
    meta: NewsletteryIDPBAipHgMeta || {},
    alias: NewsletteryIDPBAipHgMeta?.alias || [],
    redirect: NewsletteryIDPBAipHgMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/components/Newsletter.vue").then(m => m.default || m)
  },
  {
    name: PageLinkaqCWeWr6yNMeta?.name ?? "hence-components-PageLink",
    path: PageLinkaqCWeWr6yNMeta?.path ?? "/hence/components/PageLink",
    meta: PageLinkaqCWeWr6yNMeta || {},
    alias: PageLinkaqCWeWr6yNMeta?.alias || [],
    redirect: PageLinkaqCWeWr6yNMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/components/PageLink.vue").then(m => m.default || m)
  },
  {
    name: PodcastvvSXkZEycEMeta?.name ?? "hence-components-Podcast",
    path: PodcastvvSXkZEycEMeta?.path ?? "/hence/components/Podcast",
    meta: PodcastvvSXkZEycEMeta || {},
    alias: PodcastvvSXkZEycEMeta?.alias || [],
    redirect: PodcastvvSXkZEycEMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/components/Podcast.vue").then(m => m.default || m)
  },
  {
    name: Recipetm9kyWHKoDMeta?.name ?? "hence-components-Recipe",
    path: Recipetm9kyWHKoDMeta?.path ?? "/hence/components/Recipe",
    meta: Recipetm9kyWHKoDMeta || {},
    alias: Recipetm9kyWHKoDMeta?.alias || [],
    redirect: Recipetm9kyWHKoDMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/components/Recipe.vue").then(m => m.default || m)
  },
  {
    name: Side1QMal8mSqaMeta?.name ?? "hence-components-Side",
    path: Side1QMal8mSqaMeta?.path ?? "/hence/components/Side",
    meta: Side1QMal8mSqaMeta || {},
    alias: Side1QMal8mSqaMeta?.alias || [],
    redirect: Side1QMal8mSqaMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/components/Side.vue").then(m => m.default || m)
  },
  {
    name: indexKbhMtk7M6iMeta?.name ?? "hence",
    path: indexKbhMtk7M6iMeta?.path ?? "/hence",
    meta: indexKbhMtk7M6iMeta || {},
    alias: indexKbhMtk7M6iMeta?.alias || [],
    redirect: indexKbhMtk7M6iMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/index.vue").then(m => m.default || m)
  },
  {
    name: index7gbfVgFTONMeta?.name ?? "hence-magazine",
    path: index7gbfVgFTONMeta?.path ?? "/hence/magazine",
    meta: index7gbfVgFTONMeta || {},
    alias: index7gbfVgFTONMeta?.alias || [],
    redirect: index7gbfVgFTONMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/magazine/index.vue").then(m => m.default || m)
  },
  {
    name: seoc3uBgHTYGTMeta?.name ?? "hence-magazine-seo",
    path: seoc3uBgHTYGTMeta?.path ?? "/hence/magazine/seo",
    meta: seoc3uBgHTYGTMeta || {},
    alias: seoc3uBgHTYGTMeta?.alias || [],
    redirect: seoc3uBgHTYGTMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/magazine/seo.ts").then(m => m.default || m)
  },
  {
    name: Contentbt9j9gRmDXMeta?.name ?? "hence-recipe-article-id-components-Content",
    path: Contentbt9j9gRmDXMeta?.path ?? "/hence/recipe/article/:id()/components/Content",
    meta: Contentbt9j9gRmDXMeta || {},
    alias: Contentbt9j9gRmDXMeta?.alias || [],
    redirect: Contentbt9j9gRmDXMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/recipe/article/[id]/components/Content.vue").then(m => m.default || m)
  },
  {
    name: ListLdNIHBVkyTMeta?.name ?? "hence-recipe-article-id-components-List",
    path: ListLdNIHBVkyTMeta?.path ?? "/hence/recipe/article/:id()/components/List",
    meta: ListLdNIHBVkyTMeta || {},
    alias: ListLdNIHBVkyTMeta?.alias || [],
    redirect: ListLdNIHBVkyTMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/recipe/article/[id]/components/List.vue").then(m => m.default || m)
  },
  {
    name: SlidehTqYTVTFyAMeta?.name ?? "hence-recipe-article-id-components-Slide",
    path: SlidehTqYTVTFyAMeta?.path ?? "/hence/recipe/article/:id()/components/Slide",
    meta: SlidehTqYTVTFyAMeta || {},
    alias: SlidehTqYTVTFyAMeta?.alias || [],
    redirect: SlidehTqYTVTFyAMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/recipe/article/[id]/components/Slide.vue").then(m => m.default || m)
  },
  {
    name: indexRcJIlecGseMeta?.name ?? "hence-recipe-article-id",
    path: indexRcJIlecGseMeta?.path ?? "/hence/recipe/article/:id()",
    meta: indexRcJIlecGseMeta || {},
    alias: indexRcJIlecGseMeta?.alias || [],
    redirect: indexRcJIlecGseMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/recipe/article/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: seoVGcYGHiQNnMeta?.name ?? "hence-recipe-article-id-seo",
    path: seoVGcYGHiQNnMeta?.path ?? "/hence/recipe/article/:id()/seo",
    meta: seoVGcYGHiQNnMeta || {},
    alias: seoVGcYGHiQNnMeta?.alias || [],
    redirect: seoVGcYGHiQNnMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/recipe/article/[id]/seo.ts").then(m => m.default || m)
  },
  {
    name: Filteru3UsCYmR39Meta?.name ?? "hence-recipe-components-Filter",
    path: Filteru3UsCYmR39Meta?.path ?? "/hence/recipe/components/Filter",
    meta: Filteru3UsCYmR39Meta || {},
    alias: Filteru3UsCYmR39Meta?.alias || [],
    redirect: Filteru3UsCYmR39Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/recipe/components/Filter.vue").then(m => m.default || m)
  },
  {
    name: Listxts4C3dPrZMeta?.name ?? "hence-recipe-components-List",
    path: Listxts4C3dPrZMeta?.path ?? "/hence/recipe/components/List",
    meta: Listxts4C3dPrZMeta || {},
    alias: Listxts4C3dPrZMeta?.alias || [],
    redirect: Listxts4C3dPrZMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/recipe/components/List.vue").then(m => m.default || m)
  },
  {
    name: index55DhTByqVYMeta?.name ?? "hence-recipe",
    path: index55DhTByqVYMeta?.path ?? "/hence/recipe",
    meta: index55DhTByqVYMeta || {},
    alias: index55DhTByqVYMeta?.alias || [],
    redirect: index55DhTByqVYMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/recipe/index.vue").then(m => m.default || m)
  },
  {
    name: seojQzNVirMQJMeta?.name ?? "hence-recipe-seo",
    path: seojQzNVirMQJMeta?.path ?? "/hence/recipe/seo",
    meta: seojQzNVirMQJMeta || {},
    alias: seojQzNVirMQJMeta?.alias || [],
    redirect: seojQzNVirMQJMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/recipe/seo.ts").then(m => m.default || m)
  },
  {
    name: seoSaIxBS0OyjMeta?.name ?? "hence-seo",
    path: seoSaIxBS0OyjMeta?.path ?? "/hence/seo",
    meta: seoSaIxBS0OyjMeta || {},
    alias: seoSaIxBS0OyjMeta?.alias || [],
    redirect: seoSaIxBS0OyjMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/seo.ts").then(m => m.default || m)
  },
  {
    name: BannerkBigJH6VbpMeta?.name ?? "hence-topic-components-Banner",
    path: BannerkBigJH6VbpMeta?.path ?? "/hence/topic/components/Banner",
    meta: BannerkBigJH6VbpMeta || {},
    alias: BannerkBigJH6VbpMeta?.alias || [],
    redirect: BannerkBigJH6VbpMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/topic/components/Banner.vue").then(m => m.default || m)
  },
  {
    name: ListU20sZ3rSvnMeta?.name ?? "hence-topic-components-List",
    path: ListU20sZ3rSvnMeta?.path ?? "/hence/topic/components/List",
    meta: ListU20sZ3rSvnMeta || {},
    alias: ListU20sZ3rSvnMeta?.alias || [],
    redirect: ListU20sZ3rSvnMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/topic/components/List.vue").then(m => m.default || m)
  },
  {
    name: indexIKcZjWftooMeta?.name ?? "hence-topic",
    path: indexIKcZjWftooMeta?.path ?? "/hence/topic",
    meta: indexIKcZjWftooMeta || {},
    alias: indexIKcZjWftooMeta?.alias || [],
    redirect: indexIKcZjWftooMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/topic/index.vue").then(m => m.default || m)
  },
  {
    name: seoittDbZ7f7LMeta?.name ?? "hence-topic-seo",
    path: seoittDbZ7f7LMeta?.path ?? "/hence/topic/seo",
    meta: seoittDbZ7f7LMeta || {},
    alias: seoittDbZ7f7LMeta?.alias || [],
    redirect: seoittDbZ7f7LMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/hence/topic/seo.ts").then(m => m.default || m)
  },
  {
    name: About2qidMQAOPPMeta?.name ?? "index-components-About",
    path: About2qidMQAOPPMeta?.path ?? "//components/About",
    meta: About2qidMQAOPPMeta || {},
    alias: About2qidMQAOPPMeta?.alias || [],
    redirect: About2qidMQAOPPMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/index/components/About.vue").then(m => m.default || m)
  },
  {
    name: BannerVideo8V00UqAs8WMeta?.name ?? "index-components-BannerVideo",
    path: BannerVideo8V00UqAs8WMeta?.path ?? "//components/BannerVideo",
    meta: BannerVideo8V00UqAs8WMeta || {},
    alias: BannerVideo8V00UqAs8WMeta?.alias || [],
    redirect: BannerVideo8V00UqAs8WMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/index/components/BannerVideo.vue").then(m => m.default || m)
  },
  {
    name: Section1IWdxm35uuQMeta?.name ?? "index-components-Section1",
    path: Section1IWdxm35uuQMeta?.path ?? "//components/Section1",
    meta: Section1IWdxm35uuQMeta || {},
    alias: Section1IWdxm35uuQMeta?.alias || [],
    redirect: Section1IWdxm35uuQMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/index/components/Section1.vue").then(m => m.default || m)
  },
  {
    name: Section2bzZTqBKIYRMeta?.name ?? "index-components-Section2",
    path: Section2bzZTqBKIYRMeta?.path ?? "//components/Section2",
    meta: Section2bzZTqBKIYRMeta || {},
    alias: Section2bzZTqBKIYRMeta?.alias || [],
    redirect: Section2bzZTqBKIYRMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/index/components/Section2.vue").then(m => m.default || m)
  },
  {
    name: Section2SlidezTG9ddTNE7Meta?.name ?? "index-components-Section2Slide",
    path: Section2SlidezTG9ddTNE7Meta?.path ?? "//components/Section2Slide",
    meta: Section2SlidezTG9ddTNE7Meta || {},
    alias: Section2SlidezTG9ddTNE7Meta?.alias || [],
    redirect: Section2SlidezTG9ddTNE7Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/index/components/Section2Slide.vue").then(m => m.default || m)
  },
  {
    name: Section3ROLfA6pSu4Meta?.name ?? "index-components-Section3",
    path: Section3ROLfA6pSu4Meta?.path ?? "//components/Section3",
    meta: Section3ROLfA6pSu4Meta || {},
    alias: Section3ROLfA6pSu4Meta?.alias || [],
    redirect: Section3ROLfA6pSu4Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/index/components/Section3.vue").then(m => m.default || m)
  },
  {
    name: Section4smTKQRcD8zMeta?.name ?? "index-components-Section4",
    path: Section4smTKQRcD8zMeta?.path ?? "//components/Section4",
    meta: Section4smTKQRcD8zMeta || {},
    alias: Section4smTKQRcD8zMeta?.alias || [],
    redirect: Section4smTKQRcD8zMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/index/components/Section4.vue").then(m => m.default || m)
  },
  {
    name: indexvchzpom23YMeta?.name ?? "index",
    path: indexvchzpom23YMeta?.path ?? "/",
    meta: indexvchzpom23YMeta || {},
    alias: indexvchzpom23YMeta?.alias || [],
    redirect: indexvchzpom23YMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: seoRui9GfuUCbMeta?.name ?? "index-seo",
    path: seoRui9GfuUCbMeta?.path ?? "//seo",
    meta: seoRui9GfuUCbMeta || {},
    alias: seoRui9GfuUCbMeta?.alias || [],
    redirect: seoRui9GfuUCbMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/index/seo.ts").then(m => m.default || m)
  },
  {
    name: indexwFTi3KgtxAMeta?.name ?? "locations-locationId",
    path: indexwFTi3KgtxAMeta?.path ?? "/locations/:locationId()",
    meta: indexwFTi3KgtxAMeta || {},
    alias: indexwFTi3KgtxAMeta?.alias || [],
    redirect: indexwFTi3KgtxAMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/locations/[locationId]/index.vue").then(m => m.default || m)
  },
  {
    name: LocationListNdyCvy6BR7Meta?.name ?? "locations-components-LocationList",
    path: LocationListNdyCvy6BR7Meta?.path ?? "/locations/components/LocationList",
    meta: LocationListNdyCvy6BR7Meta || {},
    alias: LocationListNdyCvy6BR7Meta?.alias || [],
    redirect: LocationListNdyCvy6BR7Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/locations/components/LocationList.vue").then(m => m.default || m)
  },
  {
    name: LocationPopAN32UztVPqMeta?.name ?? "locations-components-LocationPop",
    path: LocationPopAN32UztVPqMeta?.path ?? "/locations/components/LocationPop",
    meta: LocationPopAN32UztVPqMeta || {},
    alias: LocationPopAN32UztVPqMeta?.alias || [],
    redirect: LocationPopAN32UztVPqMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/locations/components/LocationPop.vue").then(m => m.default || m)
  },
  {
    name: indexZH8F3xGNlfMeta?.name ?? "locations",
    path: indexZH8F3xGNlfMeta?.path ?? "/locations",
    meta: indexZH8F3xGNlfMeta || {},
    alias: indexZH8F3xGNlfMeta?.alias || [],
    redirect: indexZH8F3xGNlfMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/locations/index.vue").then(m => m.default || m)
  },
  {
    name: seoIQZsV2yleUMeta?.name ?? "locations-seo",
    path: seoIQZsV2yleUMeta?.path ?? "/locations/seo",
    meta: seoIQZsV2yleUMeta || {},
    alias: seoIQZsV2yleUMeta?.alias || [],
    redirect: seoIQZsV2yleUMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/locations/seo.ts").then(m => m.default || m)
  },
  {
    name: Section1X93wmm0w89Meta?.name ?? "member-activity-components-Section1",
    path: Section1X93wmm0w89Meta?.path ?? "/member/activity/components/Section1",
    meta: Section1X93wmm0w89Meta || {},
    alias: Section1X93wmm0w89Meta?.alias || [],
    redirect: Section1X93wmm0w89Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/activity/components/Section1.vue").then(m => m.default || m)
  },
  {
    name: Section2dYChQjk1YvMeta?.name ?? "member-activity-components-Section2",
    path: Section2dYChQjk1YvMeta?.path ?? "/member/activity/components/Section2",
    meta: Section2dYChQjk1YvMeta || {},
    alias: Section2dYChQjk1YvMeta?.alias || [],
    redirect: Section2dYChQjk1YvMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/activity/components/Section2.vue").then(m => m.default || m)
  },
  {
    name: Section32IRERNlbs3Meta?.name ?? "member-activity-components-Section3",
    path: Section32IRERNlbs3Meta?.path ?? "/member/activity/components/Section3",
    meta: Section32IRERNlbs3Meta || {},
    alias: Section32IRERNlbs3Meta?.alias || [],
    redirect: Section32IRERNlbs3Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/activity/components/Section3.vue").then(m => m.default || m)
  },
  {
    name: _91no_93gU7w4aRxuYMeta?.name ?? "member-activity-credit-note-no",
    path: _91no_93gU7w4aRxuYMeta?.path ?? "/member/activity/credit-note/:no()",
    meta: _91no_93gU7w4aRxuYMeta || {},
    alias: _91no_93gU7w4aRxuYMeta?.alias || [],
    redirect: _91no_93gU7w4aRxuYMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/activity/credit-note/[no].vue").then(m => m.default || m)
  },
  {
    name: indexTfshPbtTDnMeta?.name ?? "member-activity",
    path: indexTfshPbtTDnMeta?.path ?? "/member/activity",
    meta: indexTfshPbtTDnMeta || {},
    alias: indexTfshPbtTDnMeta?.alias || [],
    redirect: indexTfshPbtTDnMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/activity/index.vue").then(m => m.default || m)
  },
  {
    name: seoJyofGm93X4Meta?.name ?? "member-activity-seo",
    path: seoJyofGm93X4Meta?.path ?? "/member/activity/seo",
    meta: seoJyofGm93X4Meta || {},
    alias: seoJyofGm93X4Meta?.alias || [],
    redirect: seoJyofGm93X4Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/activity/seo.ts").then(m => m.default || m)
  },
  {
    name: PopupContentR4elyF3VDhMeta?.name ?? "member-components-PopupContent",
    path: PopupContentR4elyF3VDhMeta?.path ?? "/member/components/PopupContent",
    meta: PopupContentR4elyF3VDhMeta || {},
    alias: PopupContentR4elyF3VDhMeta?.alias || [],
    redirect: PopupContentR4elyF3VDhMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/components/PopupContent.vue").then(m => m.default || m)
  },
  {
    name: SideMenuErebJt2euQMeta?.name ?? "member-components-SideMenu",
    path: SideMenuErebJt2euQMeta?.path ?? "/member/components/SideMenu",
    meta: SideMenuErebJt2euQMeta || {},
    alias: SideMenuErebJt2euQMeta?.alias || [],
    redirect: SideMenuErebJt2euQMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/components/SideMenu.vue").then(m => m.default || m)
  },
  {
    name: indexlLX7dTN43JMeta?.name ?? "member-edit",
    path: indexlLX7dTN43JMeta?.path ?? "/member/edit",
    meta: indexlLX7dTN43JMeta || {},
    alias: indexlLX7dTN43JMeta?.alias || [],
    redirect: indexlLX7dTN43JMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/edit/index.vue").then(m => m.default || m)
  },
  {
    name: seo9utql15Z73Meta?.name ?? "member-edit-seo",
    path: seo9utql15Z73Meta?.path ?? "/member/edit/seo",
    meta: seo9utql15Z73Meta || {},
    alias: seo9utql15Z73Meta?.alias || [],
    redirect: seo9utql15Z73Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/edit/seo.ts").then(m => m.default || m)
  },
  {
    name: indexqwVkoyAL9HMeta?.name ?? "member",
    path: indexqwVkoyAL9HMeta?.path ?? "/member",
    meta: indexqwVkoyAL9HMeta || {},
    alias: indexqwVkoyAL9HMeta?.alias || [],
    redirect: indexqwVkoyAL9HMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/index.vue").then(m => m.default || m)
  },
  {
    name: _91no_93CmtOJqabWuMeta?.name ?? "member-refund-no",
    path: _91no_93CmtOJqabWuMeta?.path ?? "/member/refund/:no()",
    meta: _91no_93CmtOJqabWuMeta || {},
    alias: _91no_93CmtOJqabWuMeta?.alias || [],
    redirect: _91no_93CmtOJqabWuMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/refund/[no].vue").then(m => m.default || m)
  },
  {
    name: Step1cga77DntUZMeta?.name ?? "member-refund-components-Step1",
    path: Step1cga77DntUZMeta?.path ?? "/member/refund/components/Step1",
    meta: Step1cga77DntUZMeta || {},
    alias: Step1cga77DntUZMeta?.alias || [],
    redirect: Step1cga77DntUZMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/refund/components/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step25iyAcsmLSmMeta?.name ?? "member-refund-components-Step2",
    path: Step25iyAcsmLSmMeta?.path ?? "/member/refund/components/Step2",
    meta: Step25iyAcsmLSmMeta || {},
    alias: Step25iyAcsmLSmMeta?.alias || [],
    redirect: Step25iyAcsmLSmMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/refund/components/Step2.vue").then(m => m.default || m)
  },
  {
    name: Step32dlvJTbMqkMeta?.name ?? "member-refund-components-Step3",
    path: Step32dlvJTbMqkMeta?.path ?? "/member/refund/components/Step3",
    meta: Step32dlvJTbMqkMeta || {},
    alias: Step32dlvJTbMqkMeta?.alias || [],
    redirect: Step32dlvJTbMqkMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/refund/components/Step3.vue").then(m => m.default || m)
  },
  {
    name: seonH0CDmvsEEMeta?.name ?? "member-refund-seo",
    path: seonH0CDmvsEEMeta?.path ?? "/member/refund/seo",
    meta: seonH0CDmvsEEMeta || {},
    alias: seonH0CDmvsEEMeta?.alias || [],
    redirect: seonH0CDmvsEEMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/refund/seo.ts").then(m => m.default || m)
  },
  {
    name: seogadmiUY2eiMeta?.name ?? "member-seo",
    path: seogadmiUY2eiMeta?.path ?? "/member/seo",
    meta: seogadmiUY2eiMeta || {},
    alias: seogadmiUY2eiMeta?.alias || [],
    redirect: seogadmiUY2eiMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/seo.ts").then(m => m.default || m)
  },
  {
    name: Section1C1p2LozQP1Meta?.name ?? "member-service-components-Section1",
    path: Section1C1p2LozQP1Meta?.path ?? "/member/service/components/Section1",
    meta: Section1C1p2LozQP1Meta || {},
    alias: Section1C1p2LozQP1Meta?.alias || [],
    redirect: Section1C1p2LozQP1Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/service/components/Section1.vue").then(m => m.default || m)
  },
  {
    name: Section2HRZl6moUM4Meta?.name ?? "member-service-components-Section2",
    path: Section2HRZl6moUM4Meta?.path ?? "/member/service/components/Section2",
    meta: Section2HRZl6moUM4Meta || {},
    alias: Section2HRZl6moUM4Meta?.alias || [],
    redirect: Section2HRZl6moUM4Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/service/components/Section2.vue").then(m => m.default || m)
  },
  {
    name: Section3FROXoKxyVKMeta?.name ?? "member-service-components-Section3",
    path: Section3FROXoKxyVKMeta?.path ?? "/member/service/components/Section3",
    meta: Section3FROXoKxyVKMeta || {},
    alias: Section3FROXoKxyVKMeta?.alias || [],
    redirect: Section3FROXoKxyVKMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/service/components/Section3.vue").then(m => m.default || m)
  },
  {
    name: _91no_93DaeZlQwo6bMeta?.name ?? "member-service-credit-note-no",
    path: _91no_93DaeZlQwo6bMeta?.path ?? "/member/service/credit-note/:no()",
    meta: _91no_93DaeZlQwo6bMeta || {},
    alias: _91no_93DaeZlQwo6bMeta?.alias || [],
    redirect: _91no_93DaeZlQwo6bMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/service/credit-note/[no].vue").then(m => m.default || m)
  },
  {
    name: indexC3XjNqr0wsMeta?.name ?? "member-service",
    path: indexC3XjNqr0wsMeta?.path ?? "/member/service",
    meta: indexC3XjNqr0wsMeta || {},
    alias: indexC3XjNqr0wsMeta?.alias || [],
    redirect: indexC3XjNqr0wsMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/service/index.vue").then(m => m.default || m)
  },
  {
    name: seoEV0ayPT4RuMeta?.name ?? "member-service-seo",
    path: seoEV0ayPT4RuMeta?.path ?? "/member/service/seo",
    meta: seoEV0ayPT4RuMeta || {},
    alias: seoEV0ayPT4RuMeta?.alias || [],
    redirect: seoEV0ayPT4RuMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/member/service/seo.ts").then(m => m.default || m)
  },
  {
    name: membership_45termsRudAfvaEiyMeta?.name ?? "membership-terms",
    path: membership_45termsRudAfvaEiyMeta?.path ?? "/membership-terms",
    meta: membership_45termsRudAfvaEiyMeta || {},
    alias: membership_45termsRudAfvaEiyMeta?.alias || [],
    redirect: membership_45termsRudAfvaEiyMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/membership-terms.vue").then(m => m.default || m)
  },
  {
    name: Faqd4uthKGEZoMeta?.name ?? "membership-components-Faq",
    path: Faqd4uthKGEZoMeta?.path ?? "/membership/components/Faq",
    meta: Faqd4uthKGEZoMeta || {},
    alias: Faqd4uthKGEZoMeta?.alias || [],
    redirect: Faqd4uthKGEZoMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/membership/components/Faq.vue").then(m => m.default || m)
  },
  {
    name: Section1Lq1HQERpWEMeta?.name ?? "membership-components-Section1",
    path: Section1Lq1HQERpWEMeta?.path ?? "/membership/components/Section1",
    meta: Section1Lq1HQERpWEMeta || {},
    alias: Section1Lq1HQERpWEMeta?.alias || [],
    redirect: Section1Lq1HQERpWEMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/membership/components/Section1.vue").then(m => m.default || m)
  },
  {
    name: Section2fol5ezEIzDMeta?.name ?? "membership-components-Section2",
    path: Section2fol5ezEIzDMeta?.path ?? "/membership/components/Section2",
    meta: Section2fol5ezEIzDMeta || {},
    alias: Section2fol5ezEIzDMeta?.alias || [],
    redirect: Section2fol5ezEIzDMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/membership/components/Section2.vue").then(m => m.default || m)
  },
  {
    name: Section3AZuvmwzGyZMeta?.name ?? "membership-components-Section3",
    path: Section3AZuvmwzGyZMeta?.path ?? "/membership/components/Section3",
    meta: Section3AZuvmwzGyZMeta || {},
    alias: Section3AZuvmwzGyZMeta?.alias || [],
    redirect: Section3AZuvmwzGyZMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/membership/components/Section3.vue").then(m => m.default || m)
  },
  {
    name: Section3ListnnaZRCikKeMeta?.name ?? "membership-components-Section3List",
    path: Section3ListnnaZRCikKeMeta?.path ?? "/membership/components/Section3List",
    meta: Section3ListnnaZRCikKeMeta || {},
    alias: Section3ListnnaZRCikKeMeta?.alias || [],
    redirect: Section3ListnnaZRCikKeMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/membership/components/Section3List.vue").then(m => m.default || m)
  },
  {
    name: index1sewyAEwFfMeta?.name ?? "membership",
    path: index1sewyAEwFfMeta?.path ?? "/membership",
    meta: index1sewyAEwFfMeta || {},
    alias: index1sewyAEwFfMeta?.alias || [],
    redirect: index1sewyAEwFfMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/membership/index.vue").then(m => m.default || m)
  },
  {
    name: seoZKnS96TfO3Meta?.name ?? "membership-seo",
    path: seoZKnS96TfO3Meta?.path ?? "/membership/seo",
    meta: seoZKnS96TfO3Meta || {},
    alias: seoZKnS96TfO3Meta?.alias || [],
    redirect: seoZKnS96TfO3Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/membership/seo.ts").then(m => m.default || m)
  },
  {
    name: indexaodkDzQshmMeta?.name ?? "news-slug",
    path: indexaodkDzQshmMeta?.path ?? "/news/:slug()",
    meta: indexaodkDzQshmMeta || {},
    alias: indexaodkDzQshmMeta?.alias || [],
    redirect: indexaodkDzQshmMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/news/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91newsId_93t3vfxcj2x5Meta?.name ?? "news-article-newsId",
    path: _91newsId_93t3vfxcj2x5Meta?.path ?? "/news/article/:newsId()",
    meta: _91newsId_93t3vfxcj2x5Meta || {},
    alias: _91newsId_93t3vfxcj2x5Meta?.alias || [],
    redirect: _91newsId_93t3vfxcj2x5Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/news/article/[newsId].vue").then(m => m.default || m)
  },
  {
    name: seo3c6AHjfvHOMeta?.name ?? "news-article-seo",
    path: seo3c6AHjfvHOMeta?.path ?? "/news/article/seo",
    meta: seo3c6AHjfvHOMeta || {},
    alias: seo3c6AHjfvHOMeta?.alias || [],
    redirect: seo3c6AHjfvHOMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/news/article/seo.ts").then(m => m.default || m)
  },
  {
    name: ClassLinkoeYfybfApzMeta?.name ?? "news-components-ClassLink",
    path: ClassLinkoeYfybfApzMeta?.path ?? "/news/components/ClassLink",
    meta: ClassLinkoeYfybfApzMeta || {},
    alias: ClassLinkoeYfybfApzMeta?.alias || [],
    redirect: ClassLinkoeYfybfApzMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/news/components/ClassLink.vue").then(m => m.default || m)
  },
  {
    name: ListD12prYTsHDMeta?.name ?? "news-components-List",
    path: ListD12prYTsHDMeta?.path ?? "/news/components/List",
    meta: ListD12prYTsHDMeta || {},
    alias: ListD12prYTsHDMeta?.alias || [],
    redirect: ListD12prYTsHDMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/news/components/List.vue").then(m => m.default || m)
  },
  {
    name: NewsItemnFVSKOzwfzMeta?.name ?? "news-components-NewsItem",
    path: NewsItemnFVSKOzwfzMeta?.path ?? "/news/components/NewsItem",
    meta: NewsItemnFVSKOzwfzMeta || {},
    alias: NewsItemnFVSKOzwfzMeta?.alias || [],
    redirect: NewsItemnFVSKOzwfzMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/news/components/NewsItem.vue").then(m => m.default || m)
  },
  {
    name: NewsPinjo0IVZzyqHMeta?.name ?? "news-components-NewsPin",
    path: NewsPinjo0IVZzyqHMeta?.path ?? "/news/components/NewsPin",
    meta: NewsPinjo0IVZzyqHMeta || {},
    alias: NewsPinjo0IVZzyqHMeta?.alias || [],
    redirect: NewsPinjo0IVZzyqHMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/news/components/NewsPin.vue").then(m => m.default || m)
  },
  {
    name: indexcgjtff8joaMeta?.name ?? "news",
    path: indexcgjtff8joaMeta?.path ?? "/news",
    meta: indexcgjtff8joaMeta || {},
    alias: indexcgjtff8joaMeta?.alias || [],
    redirect: indexcgjtff8joaMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: seoXSkXZRy4R3Meta?.name ?? "news-seo",
    path: seoXSkXZRy4R3Meta?.path ?? "/news/seo",
    meta: seoXSkXZRy4R3Meta || {},
    alias: seoXSkXZRy4R3Meta?.alias || [],
    redirect: seoXSkXZRy4R3Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/news/seo.ts").then(m => m.default || m)
  },
  {
    name: privacy_45policyZ9a1WZV4amMeta?.name ?? "privacy-policy",
    path: privacy_45policyZ9a1WZV4amMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyZ9a1WZV4amMeta || {},
    alias: privacy_45policyZ9a1WZV4amMeta?.alias || [],
    redirect: privacy_45policyZ9a1WZV4amMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: qrcodervwpXNQA3zMeta?.name ?? "qrcode",
    path: qrcodervwpXNQA3zMeta?.path ?? "/qrcode",
    meta: qrcodervwpXNQA3zMeta || {},
    alias: qrcodervwpXNQA3zMeta?.alias || [],
    redirect: qrcodervwpXNQA3zMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/qrcode.vue").then(m => m.default || m)
  },
  {
    name: scrollZyFvX4t9ZCMeta?.name ?? "scroll",
    path: scrollZyFvX4t9ZCMeta?.path ?? "/scroll",
    meta: scrollZyFvX4t9ZCMeta || {},
    alias: scrollZyFvX4t9ZCMeta?.alias || [],
    redirect: scrollZyFvX4t9ZCMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/scroll.vue").then(m => m.default || m)
  },
  {
    name: searchdywSoAIugpMeta?.name ?? "search",
    path: searchdywSoAIugpMeta?.path ?? "/search",
    meta: searchdywSoAIugpMeta || {},
    alias: searchdywSoAIugpMeta?.alias || [],
    redirect: searchdywSoAIugpMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: seo_searchO3N7bWPx2NMeta?.name ?? "seo_search",
    path: seo_searchO3N7bWPx2NMeta?.path ?? "/seo_search",
    meta: seo_searchO3N7bWPx2NMeta || {},
    alias: seo_searchO3N7bWPx2NMeta?.alias || [],
    redirect: seo_searchO3N7bWPx2NMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/seo_search.ts").then(m => m.default || m)
  },
  {
    name: Featuresq4EMjobWkQMeta?.name ?? "services-cleaning-components-Features",
    path: Featuresq4EMjobWkQMeta?.path ?? "/services/cleaning/components/Features",
    meta: Featuresq4EMjobWkQMeta || {},
    alias: Featuresq4EMjobWkQMeta?.alias || [],
    redirect: Featuresq4EMjobWkQMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/cleaning/components/Features.vue").then(m => m.default || m)
  },
  {
    name: Step1MvyyzRYS1hMeta?.name ?? "services-cleaning-components-Step1",
    path: Step1MvyyzRYS1hMeta?.path ?? "/services/cleaning/components/Step1",
    meta: Step1MvyyzRYS1hMeta || {},
    alias: Step1MvyyzRYS1hMeta?.alias || [],
    redirect: Step1MvyyzRYS1hMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/cleaning/components/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step2cwTWlWTmRrMeta?.name ?? "services-cleaning-components-Step2",
    path: Step2cwTWlWTmRrMeta?.path ?? "/services/cleaning/components/Step2",
    meta: Step2cwTWlWTmRrMeta || {},
    alias: Step2cwTWlWTmRrMeta?.alias || [],
    redirect: Step2cwTWlWTmRrMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/cleaning/components/Step2.vue").then(m => m.default || m)
  },
  {
    name: Step3HRmRWK1yGtMeta?.name ?? "services-cleaning-components-Step3",
    path: Step3HRmRWK1yGtMeta?.path ?? "/services/cleaning/components/Step3",
    meta: Step3HRmRWK1yGtMeta || {},
    alias: Step3HRmRWK1yGtMeta?.alias || [],
    redirect: Step3HRmRWK1yGtMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/cleaning/components/Step3.vue").then(m => m.default || m)
  },
  {
    name: Step4q4kn4ZEo6WMeta?.name ?? "services-cleaning-components-Step4",
    path: Step4q4kn4ZEo6WMeta?.path ?? "/services/cleaning/components/Step4",
    meta: Step4q4kn4ZEo6WMeta || {},
    alias: Step4q4kn4ZEo6WMeta?.alias || [],
    redirect: Step4q4kn4ZEo6WMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/cleaning/components/Step4.vue").then(m => m.default || m)
  },
  {
    name: form_seoChRZ0S0OaYMeta?.name ?? "services-cleaning-form_seo",
    path: form_seoChRZ0S0OaYMeta?.path ?? "/services/cleaning/form_seo",
    meta: form_seoChRZ0S0OaYMeta || {},
    alias: form_seoChRZ0S0OaYMeta?.alias || [],
    redirect: form_seoChRZ0S0OaYMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/cleaning/form_seo.ts").then(m => m.default || m)
  },
  {
    name: form1FG8Vtf5H2Meta?.name ?? "services-cleaning-form",
    path: form1FG8Vtf5H2Meta?.path ?? "/services/cleaning/form",
    meta: form1FG8Vtf5H2Meta || {},
    alias: form1FG8Vtf5H2Meta?.alias || [],
    redirect: form1FG8Vtf5H2Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/cleaning/form.vue").then(m => m.default || m)
  },
  {
    name: indexv9SaWyvnzhMeta?.name ?? "services-cleaning",
    path: indexv9SaWyvnzhMeta?.path ?? "/services/cleaning",
    meta: indexv9SaWyvnzhMeta || {},
    alias: indexv9SaWyvnzhMeta?.alias || [],
    redirect: indexv9SaWyvnzhMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/cleaning/index.vue").then(m => m.default || m)
  },
  {
    name: seoJI3NdpTdUaMeta?.name ?? "services-cleaning-seo",
    path: seoJI3NdpTdUaMeta?.path ?? "/services/cleaning/seo",
    meta: seoJI3NdpTdUaMeta || {},
    alias: seoJI3NdpTdUaMeta?.alias || [],
    redirect: seoJI3NdpTdUaMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/cleaning/seo.ts").then(m => m.default || m)
  },
  {
    name: Bottomcm8xWTwnyJMeta?.name ?? "services-components-Bottom",
    path: Bottomcm8xWTwnyJMeta?.path ?? "/services/components/Bottom",
    meta: Bottomcm8xWTwnyJMeta || {},
    alias: Bottomcm8xWTwnyJMeta?.alias || [],
    redirect: Bottomcm8xWTwnyJMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/components/Bottom.vue").then(m => m.default || m)
  },
  {
    name: CategoryyQmlSDeSKtMeta?.name ?? "services-components-Category",
    path: CategoryyQmlSDeSKtMeta?.path ?? "/services/components/Category",
    meta: CategoryyQmlSDeSKtMeta || {},
    alias: CategoryyQmlSDeSKtMeta?.alias || [],
    redirect: CategoryyQmlSDeSKtMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/components/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryItem4LC1zQUHcwMeta?.name ?? "services-components-CategoryItem",
    path: CategoryItem4LC1zQUHcwMeta?.path ?? "/services/components/CategoryItem",
    meta: CategoryItem4LC1zQUHcwMeta || {},
    alias: CategoryItem4LC1zQUHcwMeta?.alias || [],
    redirect: CategoryItem4LC1zQUHcwMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/components/CategoryItem.vue").then(m => m.default || m)
  },
  {
    name: ContactInfo8sqdiDhOJWMeta?.name ?? "services-components-ContactInfo",
    path: ContactInfo8sqdiDhOJWMeta?.path ?? "/services/components/ContactInfo",
    meta: ContactInfo8sqdiDhOJWMeta || {},
    alias: ContactInfo8sqdiDhOJWMeta?.alias || [],
    redirect: ContactInfo8sqdiDhOJWMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/components/ContactInfo.vue").then(m => m.default || m)
  },
  {
    name: FaqTc3Knd3iEwMeta?.name ?? "services-components-Faq",
    path: FaqTc3Knd3iEwMeta?.path ?? "/services/components/Faq",
    meta: FaqTc3Knd3iEwMeta || {},
    alias: FaqTc3Knd3iEwMeta?.alias || [],
    redirect: FaqTc3Knd3iEwMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/components/Faq.vue").then(m => m.default || m)
  },
  {
    name: FeaturesIVxafSLwpHMeta?.name ?? "services-components-Features",
    path: FeaturesIVxafSLwpHMeta?.path ?? "/services/components/Features",
    meta: FeaturesIVxafSLwpHMeta || {},
    alias: FeaturesIVxafSLwpHMeta?.alias || [],
    redirect: FeaturesIVxafSLwpHMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/components/Features.vue").then(m => m.default || m)
  },
  {
    name: OtherLax5dWTImYMeta?.name ?? "services-components-Other",
    path: OtherLax5dWTImYMeta?.path ?? "/services/components/Other",
    meta: OtherLax5dWTImYMeta || {},
    alias: OtherLax5dWTImYMeta?.alias || [],
    redirect: OtherLax5dWTImYMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/components/Other.vue").then(m => m.default || m)
  },
  {
    name: ProcessgyczGALc2LMeta?.name ?? "services-components-Process",
    path: ProcessgyczGALc2LMeta?.path ?? "/services/components/Process",
    meta: ProcessgyczGALc2LMeta || {},
    alias: ProcessgyczGALc2LMeta?.alias || [],
    redirect: ProcessgyczGALc2LMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/components/Process.vue").then(m => m.default || m)
  },
  {
    name: ReviewAcFGzPsvCCMeta?.name ?? "services-components-Review",
    path: ReviewAcFGzPsvCCMeta?.path ?? "/services/components/Review",
    meta: ReviewAcFGzPsvCCMeta || {},
    alias: ReviewAcFGzPsvCCMeta?.alias || [],
    redirect: ReviewAcFGzPsvCCMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/components/Review.vue").then(m => m.default || m)
  },
  {
    name: Relatedz3xk4UOyMMMeta?.name ?? "services-faq-article-id-components-Related",
    path: Relatedz3xk4UOyMMMeta?.path ?? "/services/faq/article/:id()/components/Related",
    meta: Relatedz3xk4UOyMMMeta || {},
    alias: Relatedz3xk4UOyMMMeta?.alias || [],
    redirect: Relatedz3xk4UOyMMMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/faq/article/[id]/components/Related.vue").then(m => m.default || m)
  },
  {
    name: indexzcCWYMzv76Meta?.name ?? "services-faq-article-id",
    path: indexzcCWYMzv76Meta?.path ?? "/services/faq/article/:id()",
    meta: indexzcCWYMzv76Meta || {},
    alias: indexzcCWYMzv76Meta?.alias || [],
    redirect: indexzcCWYMzv76Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/faq/article/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: seo2w1tCb3VT8Meta?.name ?? "services-faq-article-id-seo",
    path: seo2w1tCb3VT8Meta?.path ?? "/services/faq/article/:id()/seo",
    meta: seo2w1tCb3VT8Meta || {},
    alias: seo2w1tCb3VT8Meta?.alias || [],
    redirect: seo2w1tCb3VT8Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/faq/article/[id]/seo.ts").then(m => m.default || m)
  },
  {
    name: ContactJqDiavcS1HMeta?.name ?? "services-faq-components-Contact",
    path: ContactJqDiavcS1HMeta?.path ?? "/services/faq/components/Contact",
    meta: ContactJqDiavcS1HMeta || {},
    alias: ContactJqDiavcS1HMeta?.alias || [],
    redirect: ContactJqDiavcS1HMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/faq/components/Contact.vue").then(m => m.default || m)
  },
  {
    name: FaqItemB5r0fniWS2Meta?.name ?? "services-faq-components-FaqItem",
    path: FaqItemB5r0fniWS2Meta?.path ?? "/services/faq/components/FaqItem",
    meta: FaqItemB5r0fniWS2Meta || {},
    alias: FaqItemB5r0fniWS2Meta?.alias || [],
    redirect: FaqItemB5r0fniWS2Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/faq/components/FaqItem.vue").then(m => m.default || m)
  },
  {
    name: indexQvoWmH6j7AMeta?.name ?? "services-faq",
    path: indexQvoWmH6j7AMeta?.path ?? "/services/faq",
    meta: indexQvoWmH6j7AMeta || {},
    alias: indexQvoWmH6j7AMeta?.alias || [],
    redirect: indexQvoWmH6j7AMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/faq/index.vue").then(m => m.default || m)
  },
  {
    name: seocpqJObVU6sMeta?.name ?? "services-faq-seo",
    path: seocpqJObVU6sMeta?.path ?? "/services/faq/seo",
    meta: seocpqJObVU6sMeta || {},
    alias: seocpqJObVU6sMeta?.alias || [],
    redirect: seocpqJObVU6sMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/faq/seo.ts").then(m => m.default || m)
  },
  {
    name: ContactInfoXCUoU9eDEsMeta?.name ?? "services-fast-repair-components-ContactInfo",
    path: ContactInfoXCUoU9eDEsMeta?.path ?? "/services/fast-repair/components/ContactInfo",
    meta: ContactInfoXCUoU9eDEsMeta || {},
    alias: ContactInfoXCUoU9eDEsMeta?.alias || [],
    redirect: ContactInfoXCUoU9eDEsMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/fast-repair/components/ContactInfo.vue").then(m => m.default || m)
  },
  {
    name: Step1h4q1A8rgrqMeta?.name ?? "services-fast-repair-components-Step1",
    path: Step1h4q1A8rgrqMeta?.path ?? "/services/fast-repair/components/Step1",
    meta: Step1h4q1A8rgrqMeta || {},
    alias: Step1h4q1A8rgrqMeta?.alias || [],
    redirect: Step1h4q1A8rgrqMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/fast-repair/components/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step21w9sEfd4pKMeta?.name ?? "services-fast-repair-components-Step2",
    path: Step21w9sEfd4pKMeta?.path ?? "/services/fast-repair/components/Step2",
    meta: Step21w9sEfd4pKMeta || {},
    alias: Step21w9sEfd4pKMeta?.alias || [],
    redirect: Step21w9sEfd4pKMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/fast-repair/components/Step2.vue").then(m => m.default || m)
  },
  {
    name: Step3ddbqsVWrxqMeta?.name ?? "services-fast-repair-components-Step3",
    path: Step3ddbqsVWrxqMeta?.path ?? "/services/fast-repair/components/Step3",
    meta: Step3ddbqsVWrxqMeta || {},
    alias: Step3ddbqsVWrxqMeta?.alias || [],
    redirect: Step3ddbqsVWrxqMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/fast-repair/components/Step3.vue").then(m => m.default || m)
  },
  {
    name: Step42coRyFNXROMeta?.name ?? "services-fast-repair-components-Step4",
    path: Step42coRyFNXROMeta?.path ?? "/services/fast-repair/components/Step4",
    meta: Step42coRyFNXROMeta || {},
    alias: Step42coRyFNXROMeta?.alias || [],
    redirect: Step42coRyFNXROMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/fast-repair/components/Step4.vue").then(m => m.default || m)
  },
  {
    name: form_seoCYVKqQ8UueMeta?.name ?? "services-fast-repair-form_seo",
    path: form_seoCYVKqQ8UueMeta?.path ?? "/services/fast-repair/form_seo",
    meta: form_seoCYVKqQ8UueMeta || {},
    alias: form_seoCYVKqQ8UueMeta?.alias || [],
    redirect: form_seoCYVKqQ8UueMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/fast-repair/form_seo.ts").then(m => m.default || m)
  },
  {
    name: formeoEfkXtxNIMeta?.name ?? "services-fast-repair-form",
    path: formeoEfkXtxNIMeta?.path ?? "/services/fast-repair/form",
    meta: formeoEfkXtxNIMeta || {},
    alias: formeoEfkXtxNIMeta?.alias || [],
    redirect: formeoEfkXtxNIMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/fast-repair/form.vue").then(m => m.default || m)
  },
  {
    name: indexqcPXZSKJ8BMeta?.name ?? "services-fast-repair",
    path: indexqcPXZSKJ8BMeta?.path ?? "/services/fast-repair",
    meta: indexqcPXZSKJ8BMeta || {},
    alias: indexqcPXZSKJ8BMeta?.alias || [],
    redirect: indexqcPXZSKJ8BMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/fast-repair/index.vue").then(m => m.default || m)
  },
  {
    name: seon28M5al2b6Meta?.name ?? "services-fast-repair-seo",
    path: seon28M5al2b6Meta?.path ?? "/services/fast-repair/seo",
    meta: seon28M5al2b6Meta || {},
    alias: seon28M5al2b6Meta?.alias || [],
    redirect: seon28M5al2b6Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/fast-repair/seo.ts").then(m => m.default || m)
  },
  {
    name: indexvB76gDmrhOMeta?.name ?? "services",
    path: indexvB76gDmrhOMeta?.path ?? "/services",
    meta: indexvB76gDmrhOMeta || {},
    alias: indexvB76gDmrhOMeta?.alias || [],
    redirect: indexvB76gDmrhOMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: Step1LKbUaysTMPMeta?.name ?? "services-inhome-components-Step1",
    path: Step1LKbUaysTMPMeta?.path ?? "/services/inhome/components/Step1",
    meta: Step1LKbUaysTMPMeta || {},
    alias: Step1LKbUaysTMPMeta?.alias || [],
    redirect: Step1LKbUaysTMPMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/inhome/components/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step2cOsIKw0F5JMeta?.name ?? "services-inhome-components-Step2",
    path: Step2cOsIKw0F5JMeta?.path ?? "/services/inhome/components/Step2",
    meta: Step2cOsIKw0F5JMeta || {},
    alias: Step2cOsIKw0F5JMeta?.alias || [],
    redirect: Step2cOsIKw0F5JMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/inhome/components/Step2.vue").then(m => m.default || m)
  },
  {
    name: form_seo2pJhdy541uMeta?.name ?? "services-inhome-form_seo",
    path: form_seo2pJhdy541uMeta?.path ?? "/services/inhome/form_seo",
    meta: form_seo2pJhdy541uMeta || {},
    alias: form_seo2pJhdy541uMeta?.alias || [],
    redirect: form_seo2pJhdy541uMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/inhome/form_seo.ts").then(m => m.default || m)
  },
  {
    name: formWgDhRg03JrMeta?.name ?? "services-inhome-form",
    path: formWgDhRg03JrMeta?.path ?? "/services/inhome/form",
    meta: formWgDhRg03JrMeta || {},
    alias: formWgDhRg03JrMeta?.alias || [],
    redirect: formWgDhRg03JrMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/inhome/form.vue").then(m => m.default || m)
  },
  {
    name: index5LnSr9ovH5Meta?.name ?? "services-inhome",
    path: index5LnSr9ovH5Meta?.path ?? "/services/inhome",
    meta: index5LnSr9ovH5Meta || {},
    alias: index5LnSr9ovH5Meta?.alias || [],
    redirect: index5LnSr9ovH5Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/inhome/index.vue").then(m => m.default || m)
  },
  {
    name: seo5VFfuBSbMgMeta?.name ?? "services-inhome-seo",
    path: seo5VFfuBSbMgMeta?.path ?? "/services/inhome/seo",
    meta: seo5VFfuBSbMgMeta || {},
    alias: seo5VFfuBSbMgMeta?.alias || [],
    redirect: seo5VFfuBSbMgMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/inhome/seo.ts").then(m => m.default || m)
  },
  {
    name: Fast4UDpPbT3uJMeta?.name ?? "services-repair-components-Fast",
    path: Fast4UDpPbT3uJMeta?.path ?? "/services/repair/components/Fast",
    meta: Fast4UDpPbT3uJMeta || {},
    alias: Fast4UDpPbT3uJMeta?.alias || [],
    redirect: Fast4UDpPbT3uJMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/repair/components/Fast.vue").then(m => m.default || m)
  },
  {
    name: Step1RMHE9dL9erMeta?.name ?? "services-repair-components-Step1",
    path: Step1RMHE9dL9erMeta?.path ?? "/services/repair/components/Step1",
    meta: Step1RMHE9dL9erMeta || {},
    alias: Step1RMHE9dL9erMeta?.alias || [],
    redirect: Step1RMHE9dL9erMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/repair/components/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step2rezcZOQt7xMeta?.name ?? "services-repair-components-Step2",
    path: Step2rezcZOQt7xMeta?.path ?? "/services/repair/components/Step2",
    meta: Step2rezcZOQt7xMeta || {},
    alias: Step2rezcZOQt7xMeta?.alias || [],
    redirect: Step2rezcZOQt7xMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/repair/components/Step2.vue").then(m => m.default || m)
  },
  {
    name: Step34q7oVlbojEMeta?.name ?? "services-repair-components-Step3",
    path: Step34q7oVlbojEMeta?.path ?? "/services/repair/components/Step3",
    meta: Step34q7oVlbojEMeta || {},
    alias: Step34q7oVlbojEMeta?.alias || [],
    redirect: Step34q7oVlbojEMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/repair/components/Step3.vue").then(m => m.default || m)
  },
  {
    name: Step4JMBhpR5PhhMeta?.name ?? "services-repair-components-Step4",
    path: Step4JMBhpR5PhhMeta?.path ?? "/services/repair/components/Step4",
    meta: Step4JMBhpR5PhhMeta || {},
    alias: Step4JMBhpR5PhhMeta?.alias || [],
    redirect: Step4JMBhpR5PhhMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/repair/components/Step4.vue").then(m => m.default || m)
  },
  {
    name: form_seooGOYK55QRWMeta?.name ?? "services-repair-form_seo",
    path: form_seooGOYK55QRWMeta?.path ?? "/services/repair/form_seo",
    meta: form_seooGOYK55QRWMeta || {},
    alias: form_seooGOYK55QRWMeta?.alias || [],
    redirect: form_seooGOYK55QRWMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/repair/form_seo.ts").then(m => m.default || m)
  },
  {
    name: formy8aSmPhCxDMeta?.name ?? "services-repair-form",
    path: formy8aSmPhCxDMeta?.path ?? "/services/repair/form",
    meta: formy8aSmPhCxDMeta || {},
    alias: formy8aSmPhCxDMeta?.alias || [],
    redirect: formy8aSmPhCxDMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/repair/form.vue").then(m => m.default || m)
  },
  {
    name: indexXS5sWZLGvhMeta?.name ?? "services-repair",
    path: indexXS5sWZLGvhMeta?.path ?? "/services/repair",
    meta: indexXS5sWZLGvhMeta || {},
    alias: indexXS5sWZLGvhMeta?.alias || [],
    redirect: indexXS5sWZLGvhMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/repair/index.vue").then(m => m.default || m)
  },
  {
    name: seoOu4pNA8FR8Meta?.name ?? "services-repair-seo",
    path: seoOu4pNA8FR8Meta?.path ?? "/services/repair/seo",
    meta: seoOu4pNA8FR8Meta || {},
    alias: seoOu4pNA8FR8Meta?.alias || [],
    redirect: seoOu4pNA8FR8Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/repair/seo.ts").then(m => m.default || m)
  },
  {
    name: indexOfdxyedWBEMeta?.name ?? "services-select",
    path: indexOfdxyedWBEMeta?.path ?? "/services/select",
    meta: indexOfdxyedWBEMeta || {},
    alias: indexOfdxyedWBEMeta?.alias || [],
    redirect: indexOfdxyedWBEMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/select/index.vue").then(m => m.default || m)
  },
  {
    name: seoCEQbmzmr5lMeta?.name ?? "services-select-seo",
    path: seoCEQbmzmr5lMeta?.path ?? "/services/select/seo",
    meta: seoCEQbmzmr5lMeta || {},
    alias: seoCEQbmzmr5lMeta?.alias || [],
    redirect: seoCEQbmzmr5lMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/select/seo.ts").then(m => m.default || m)
  },
  {
    name: seoit4aK29HzXMeta?.name ?? "services-seo",
    path: seoit4aK29HzXMeta?.path ?? "/services/seo",
    meta: seoit4aK29HzXMeta || {},
    alias: seoit4aK29HzXMeta?.alias || [],
    redirect: seoit4aK29HzXMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/seo.ts").then(m => m.default || m)
  },
  {
    name: DirectStep1luINBr0vK6Meta?.name ?? "services-warranty-components-DirectStep1",
    path: DirectStep1luINBr0vK6Meta?.path ?? "/services/warranty/components/DirectStep1",
    meta: DirectStep1luINBr0vK6Meta || {},
    alias: DirectStep1luINBr0vK6Meta?.alias || [],
    redirect: DirectStep1luINBr0vK6Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/warranty/components/DirectStep1.vue").then(m => m.default || m)
  },
  {
    name: DirectStep2TnmVntb2K6Meta?.name ?? "services-warranty-components-DirectStep2",
    path: DirectStep2TnmVntb2K6Meta?.path ?? "/services/warranty/components/DirectStep2",
    meta: DirectStep2TnmVntb2K6Meta || {},
    alias: DirectStep2TnmVntb2K6Meta?.alias || [],
    redirect: DirectStep2TnmVntb2K6Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/warranty/components/DirectStep2.vue").then(m => m.default || m)
  },
  {
    name: Step1gm9MZdarsQMeta?.name ?? "services-warranty-components-Step1",
    path: Step1gm9MZdarsQMeta?.path ?? "/services/warranty/components/Step1",
    meta: Step1gm9MZdarsQMeta || {},
    alias: Step1gm9MZdarsQMeta?.alias || [],
    redirect: Step1gm9MZdarsQMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/warranty/components/Step1.vue").then(m => m.default || m)
  },
  {
    name: Step2MCSgqTq0itMeta?.name ?? "services-warranty-components-Step2",
    path: Step2MCSgqTq0itMeta?.path ?? "/services/warranty/components/Step2",
    meta: Step2MCSgqTq0itMeta || {},
    alias: Step2MCSgqTq0itMeta?.alias || [],
    redirect: Step2MCSgqTq0itMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/warranty/components/Step2.vue").then(m => m.default || m)
  },
  {
    name: Step3L7c6uv76lHMeta?.name ?? "services-warranty-components-Step3",
    path: Step3L7c6uv76lHMeta?.path ?? "/services/warranty/components/Step3",
    meta: Step3L7c6uv76lHMeta || {},
    alias: Step3L7c6uv76lHMeta?.alias || [],
    redirect: Step3L7c6uv76lHMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/warranty/components/Step3.vue").then(m => m.default || m)
  },
  {
    name: Step4aHXtxbLdPRMeta?.name ?? "services-warranty-components-Step4",
    path: Step4aHXtxbLdPRMeta?.path ?? "/services/warranty/components/Step4",
    meta: Step4aHXtxbLdPRMeta || {},
    alias: Step4aHXtxbLdPRMeta?.alias || [],
    redirect: Step4aHXtxbLdPRMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/warranty/components/Step4.vue").then(m => m.default || m)
  },
  {
    name: directQBZg2lGIQUMeta?.name ?? "services-warranty-direct",
    path: directQBZg2lGIQUMeta?.path ?? "/services/warranty/direct",
    meta: directQBZg2lGIQUMeta || {},
    alias: directQBZg2lGIQUMeta?.alias || [],
    redirect: directQBZg2lGIQUMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/warranty/direct.vue").then(m => m.default || m)
  },
  {
    name: form_seobc2sPHN978Meta?.name ?? "services-warranty-form_seo",
    path: form_seobc2sPHN978Meta?.path ?? "/services/warranty/form_seo",
    meta: form_seobc2sPHN978Meta || {},
    alias: form_seobc2sPHN978Meta?.alias || [],
    redirect: form_seobc2sPHN978Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/warranty/form_seo.ts").then(m => m.default || m)
  },
  {
    name: formWL93116c9GMeta?.name ?? "services-warranty-form",
    path: formWL93116c9GMeta?.path ?? "/services/warranty/form",
    meta: formWL93116c9GMeta || {},
    alias: formWL93116c9GMeta?.alias || [],
    redirect: formWL93116c9GMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/warranty/form.vue").then(m => m.default || m)
  },
  {
    name: indexGasTcpUO9SMeta?.name ?? "services-warranty",
    path: indexGasTcpUO9SMeta?.path ?? "/services/warranty",
    meta: indexGasTcpUO9SMeta || {},
    alias: indexGasTcpUO9SMeta?.alias || [],
    redirect: indexGasTcpUO9SMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/warranty/index.vue").then(m => m.default || m)
  },
  {
    name: seoXYl1W3mDGYMeta?.name ?? "services-warranty-seo",
    path: seoXYl1W3mDGYMeta?.path ?? "/services/warranty/seo",
    meta: seoXYl1W3mDGYMeta || {},
    alias: seoXYl1W3mDGYMeta?.alias || [],
    redirect: seoXYl1W3mDGYMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/services/warranty/seo.ts").then(m => m.default || m)
  },
  {
    name: sitemapCBojKsM0rVMeta?.name ?? "sitemap",
    path: sitemapCBojKsM0rVMeta?.path ?? "/sitemap",
    meta: sitemapCBojKsM0rVMeta || {},
    alias: sitemapCBojKsM0rVMeta?.alias || [],
    redirect: sitemapCBojKsM0rVMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: _91subId_937AhwtpR3XQMeta?.name ?? "sustainability-mainId-subId",
    path: _91subId_937AhwtpR3XQMeta?.path ?? "/sustainability/:mainId()/:subId()",
    meta: _91subId_937AhwtpR3XQMeta || {},
    alias: _91subId_937AhwtpR3XQMeta?.alias || [],
    redirect: _91subId_937AhwtpR3XQMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/[mainId]/[subId].vue").then(m => m.default || m)
  },
  {
    name: BannerORzG2re4sAMeta?.name ?? "sustainability-mainId-components-Banner",
    path: BannerORzG2re4sAMeta?.path ?? "/sustainability/:mainId()/components/Banner",
    meta: BannerORzG2re4sAMeta || {},
    alias: BannerORzG2re4sAMeta?.alias || [],
    redirect: BannerORzG2re4sAMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/[mainId]/components/Banner.vue").then(m => m.default || m)
  },
  {
    name: Lists2JXSYDp5KRMeta?.name ?? "sustainability-mainId-components-Lists",
    path: Lists2JXSYDp5KRMeta?.path ?? "/sustainability/:mainId()/components/Lists",
    meta: Lists2JXSYDp5KRMeta || {},
    alias: Lists2JXSYDp5KRMeta?.alias || [],
    redirect: Lists2JXSYDp5KRMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/[mainId]/components/Lists.vue").then(m => m.default || m)
  },
  {
    name: indexVbKh45IHgtMeta?.name ?? "sustainability-mainId",
    path: indexVbKh45IHgtMeta?.path ?? "/sustainability/:mainId()",
    meta: indexVbKh45IHgtMeta || {},
    alias: indexVbKh45IHgtMeta?.alias || [],
    redirect: indexVbKh45IHgtMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/[mainId]/index.vue").then(m => m.default || m)
  },
  {
    name: seoCFsvWCacBKMeta?.name ?? "sustainability-mainId-seo",
    path: seoCFsvWCacBKMeta?.path ?? "/sustainability/:mainId()/seo",
    meta: seoCFsvWCacBKMeta || {},
    alias: seoCFsvWCacBKMeta?.alias || [],
    redirect: seoCFsvWCacBKMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/[mainId]/seo.ts").then(m => m.default || m)
  },
  {
    name: ListsfryCRpJOcpMeta?.name ?? "sustainability-article-id-components-Lists",
    path: ListsfryCRpJOcpMeta?.path ?? "/sustainability/article/:id()/components/Lists",
    meta: ListsfryCRpJOcpMeta || {},
    alias: ListsfryCRpJOcpMeta?.alias || [],
    redirect: ListsfryCRpJOcpMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/article/[id]/components/Lists.vue").then(m => m.default || m)
  },
  {
    name: indexsC8e4Bj7sBMeta?.name ?? "sustainability-article-id",
    path: indexsC8e4Bj7sBMeta?.path ?? "/sustainability/article/:id()",
    meta: indexsC8e4Bj7sBMeta || {},
    alias: indexsC8e4Bj7sBMeta?.alias || [],
    redirect: indexsC8e4Bj7sBMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/article/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: seo2Vpnn5IotrMeta?.name ?? "sustainability-article-id-seo",
    path: seo2Vpnn5IotrMeta?.path ?? "/sustainability/article/:id()/seo",
    meta: seo2Vpnn5IotrMeta || {},
    alias: seo2Vpnn5IotrMeta?.alias || [],
    redirect: seo2Vpnn5IotrMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/article/[id]/seo.ts").then(m => m.default || m)
  },
  {
    name: Section1tzaWhaPFfCMeta?.name ?? "sustainability-components-Section1",
    path: Section1tzaWhaPFfCMeta?.path ?? "/sustainability/components/Section1",
    meta: Section1tzaWhaPFfCMeta || {},
    alias: Section1tzaWhaPFfCMeta?.alias || [],
    redirect: Section1tzaWhaPFfCMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/components/Section1.vue").then(m => m.default || m)
  },
  {
    name: Section2WKIwl7PId7Meta?.name ?? "sustainability-components-Section2",
    path: Section2WKIwl7PId7Meta?.path ?? "/sustainability/components/Section2",
    meta: Section2WKIwl7PId7Meta || {},
    alias: Section2WKIwl7PId7Meta?.alias || [],
    redirect: Section2WKIwl7PId7Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/components/Section2.vue").then(m => m.default || m)
  },
  {
    name: Section3PX0TcOmT3BMeta?.name ?? "sustainability-components-Section3",
    path: Section3PX0TcOmT3BMeta?.path ?? "/sustainability/components/Section3",
    meta: Section3PX0TcOmT3BMeta || {},
    alias: Section3PX0TcOmT3BMeta?.alias || [],
    redirect: Section3PX0TcOmT3BMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/components/Section3.vue").then(m => m.default || m)
  },
  {
    name: Section4OKremLvWHbMeta?.name ?? "sustainability-components-Section4",
    path: Section4OKremLvWHbMeta?.path ?? "/sustainability/components/Section4",
    meta: Section4OKremLvWHbMeta || {},
    alias: Section4OKremLvWHbMeta?.alias || [],
    redirect: Section4OKremLvWHbMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/components/Section4.vue").then(m => m.default || m)
  },
  {
    name: Section5ZO4WW8ebd2Meta?.name ?? "sustainability-components-Section5",
    path: Section5ZO4WW8ebd2Meta?.path ?? "/sustainability/components/Section5",
    meta: Section5ZO4WW8ebd2Meta || {},
    alias: Section5ZO4WW8ebd2Meta?.alias || [],
    redirect: Section5ZO4WW8ebd2Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/components/Section5.vue").then(m => m.default || m)
  },
  {
    name: Section6FiQDbqXqojMeta?.name ?? "sustainability-components-Section6",
    path: Section6FiQDbqXqojMeta?.path ?? "/sustainability/components/Section6",
    meta: Section6FiQDbqXqojMeta || {},
    alias: Section6FiQDbqXqojMeta?.alias || [],
    redirect: Section6FiQDbqXqojMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/components/Section6.vue").then(m => m.default || m)
  },
  {
    name: Section7IOJdSoyA61Meta?.name ?? "sustainability-components-Section7",
    path: Section7IOJdSoyA61Meta?.path ?? "/sustainability/components/Section7",
    meta: Section7IOJdSoyA61Meta || {},
    alias: Section7IOJdSoyA61Meta?.alias || [],
    redirect: Section7IOJdSoyA61Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/components/Section7.vue").then(m => m.default || m)
  },
  {
    name: Section83tg1ua4U7CMeta?.name ?? "sustainability-components-Section8",
    path: Section83tg1ua4U7CMeta?.path ?? "/sustainability/components/Section8",
    meta: Section83tg1ua4U7CMeta || {},
    alias: Section83tg1ua4U7CMeta?.alias || [],
    redirect: Section83tg1ua4U7CMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/components/Section8.vue").then(m => m.default || m)
  },
  {
    name: Section9MSMx2ppElhMeta?.name ?? "sustainability-components-Section9",
    path: Section9MSMx2ppElhMeta?.path ?? "/sustainability/components/Section9",
    meta: Section9MSMx2ppElhMeta || {},
    alias: Section9MSMx2ppElhMeta?.alias || [],
    redirect: Section9MSMx2ppElhMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/components/Section9.vue").then(m => m.default || m)
  },
  {
    name: SideZe7mJFdBKJMeta?.name ?? "sustainability-components-Side",
    path: SideZe7mJFdBKJMeta?.path ?? "/sustainability/components/Side",
    meta: SideZe7mJFdBKJMeta || {},
    alias: SideZe7mJFdBKJMeta?.alias || [],
    redirect: SideZe7mJFdBKJMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/components/Side.vue").then(m => m.default || m)
  },
  {
    name: indexWF74AoOq7bMeta?.name ?? "sustainability",
    path: indexWF74AoOq7bMeta?.path ?? "/sustainability",
    meta: indexWF74AoOq7bMeta || {},
    alias: indexWF74AoOq7bMeta?.alias || [],
    redirect: indexWF74AoOq7bMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/index.vue").then(m => m.default || m)
  },
  {
    name: seoz5kx108kNmMeta?.name ?? "sustainability-seo",
    path: seoz5kx108kNmMeta?.path ?? "/sustainability/seo",
    meta: seoz5kx108kNmMeta || {},
    alias: seoz5kx108kNmMeta?.alias || [],
    redirect: seoz5kx108kNmMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/sustainability/seo.ts").then(m => m.default || m)
  },
  {
    name: Section1vpYfZZyzT5Meta?.name ?? "talent-development-components-Section1",
    path: Section1vpYfZZyzT5Meta?.path ?? "/talent-development/components/Section1",
    meta: Section1vpYfZZyzT5Meta || {},
    alias: Section1vpYfZZyzT5Meta?.alias || [],
    redirect: Section1vpYfZZyzT5Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/talent-development/components/Section1.vue").then(m => m.default || m)
  },
  {
    name: Section2DSydfeWTqWMeta?.name ?? "talent-development-components-Section2",
    path: Section2DSydfeWTqWMeta?.path ?? "/talent-development/components/Section2",
    meta: Section2DSydfeWTqWMeta || {},
    alias: Section2DSydfeWTqWMeta?.alias || [],
    redirect: Section2DSydfeWTqWMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/talent-development/components/Section2.vue").then(m => m.default || m)
  },
  {
    name: Section3UYhAayr5uiMeta?.name ?? "talent-development-components-Section3",
    path: Section3UYhAayr5uiMeta?.path ?? "/talent-development/components/Section3",
    meta: Section3UYhAayr5uiMeta || {},
    alias: Section3UYhAayr5uiMeta?.alias || [],
    redirect: Section3UYhAayr5uiMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/talent-development/components/Section3.vue").then(m => m.default || m)
  },
  {
    name: Section44ABlnXGOqVMeta?.name ?? "talent-development-components-Section4",
    path: Section44ABlnXGOqVMeta?.path ?? "/talent-development/components/Section4",
    meta: Section44ABlnXGOqVMeta || {},
    alias: Section44ABlnXGOqVMeta?.alias || [],
    redirect: Section44ABlnXGOqVMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/talent-development/components/Section4.vue").then(m => m.default || m)
  },
  {
    name: Section52CYgEfnIpzMeta?.name ?? "talent-development-components-Section5",
    path: Section52CYgEfnIpzMeta?.path ?? "/talent-development/components/Section5",
    meta: Section52CYgEfnIpzMeta || {},
    alias: Section52CYgEfnIpzMeta?.alias || [],
    redirect: Section52CYgEfnIpzMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/talent-development/components/Section5.vue").then(m => m.default || m)
  },
  {
    name: indexhQeicdtd1WMeta?.name ?? "talent-development",
    path: indexhQeicdtd1WMeta?.path ?? "/talent-development",
    meta: indexhQeicdtd1WMeta || {},
    alias: indexhQeicdtd1WMeta?.alias || [],
    redirect: indexhQeicdtd1WMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/talent-development/index.vue").then(m => m.default || m)
  },
  {
    name: seoPvdjVhkceZMeta?.name ?? "talent-development-seo",
    path: seoPvdjVhkceZMeta?.path ?? "/talent-development/seo",
    meta: seoPvdjVhkceZMeta || {},
    alias: seoPvdjVhkceZMeta?.alias || [],
    redirect: seoPvdjVhkceZMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/talent-development/seo.ts").then(m => m.default || m)
  },
  {
    name: BreadcrumbL1t5S3qqTXMeta?.name ?? "zonezone-components-Breadcrumb",
    path: BreadcrumbL1t5S3qqTXMeta?.path ?? "/zonezone/components/Breadcrumb",
    meta: BreadcrumbL1t5S3qqTXMeta || {},
    alias: BreadcrumbL1t5S3qqTXMeta?.alias || [],
    redirect: BreadcrumbL1t5S3qqTXMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/zonezone/components/Breadcrumb.vue").then(m => m.default || m)
  },
  {
    name: Section1G1sZEgxvo6Meta?.name ?? "zonezone-components-Section1",
    path: Section1G1sZEgxvo6Meta?.path ?? "/zonezone/components/Section1",
    meta: Section1G1sZEgxvo6Meta || {},
    alias: Section1G1sZEgxvo6Meta?.alias || [],
    redirect: Section1G1sZEgxvo6Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/zonezone/components/Section1.vue").then(m => m.default || m)
  },
  {
    name: Section2w23S2IcBjBMeta?.name ?? "zonezone-components-Section2",
    path: Section2w23S2IcBjBMeta?.path ?? "/zonezone/components/Section2",
    meta: Section2w23S2IcBjBMeta || {},
    alias: Section2w23S2IcBjBMeta?.alias || [],
    redirect: Section2w23S2IcBjBMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/zonezone/components/Section2.vue").then(m => m.default || m)
  },
  {
    name: Section3quivwqCQKVMeta?.name ?? "zonezone-components-Section3",
    path: Section3quivwqCQKVMeta?.path ?? "/zonezone/components/Section3",
    meta: Section3quivwqCQKVMeta || {},
    alias: Section3quivwqCQKVMeta?.alias || [],
    redirect: Section3quivwqCQKVMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/zonezone/components/Section3.vue").then(m => m.default || m)
  },
  {
    name: Section4HchYs8Doe0Meta?.name ?? "zonezone-components-Section4",
    path: Section4HchYs8Doe0Meta?.path ?? "/zonezone/components/Section4",
    meta: Section4HchYs8Doe0Meta || {},
    alias: Section4HchYs8Doe0Meta?.alias || [],
    redirect: Section4HchYs8Doe0Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/zonezone/components/Section4.vue").then(m => m.default || m)
  },
  {
    name: Section5S9jLqsnXXzMeta?.name ?? "zonezone-components-Section5",
    path: Section5S9jLqsnXXzMeta?.path ?? "/zonezone/components/Section5",
    meta: Section5S9jLqsnXXzMeta || {},
    alias: Section5S9jLqsnXXzMeta?.alias || [],
    redirect: Section5S9jLqsnXXzMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/zonezone/components/Section5.vue").then(m => m.default || m)
  },
  {
    name: Section6KE6fUU4jU5Meta?.name ?? "zonezone-components-Section6",
    path: Section6KE6fUU4jU5Meta?.path ?? "/zonezone/components/Section6",
    meta: Section6KE6fUU4jU5Meta || {},
    alias: Section6KE6fUU4jU5Meta?.alias || [],
    redirect: Section6KE6fUU4jU5Meta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/zonezone/components/Section6.vue").then(m => m.default || m)
  },
  {
    name: indexNekgoKTKBvMeta?.name ?? "zonezone",
    path: indexNekgoKTKBvMeta?.path ?? "/zonezone",
    meta: indexNekgoKTKBvMeta || {},
    alias: indexNekgoKTKBvMeta?.alias || [],
    redirect: indexNekgoKTKBvMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/zonezone/index.vue").then(m => m.default || m)
  },
  {
    name: seopiSzr3ppPyMeta?.name ?? "zonezone-seo",
    path: seopiSzr3ppPyMeta?.path ?? "/zonezone/seo",
    meta: seopiSzr3ppPyMeta || {},
    alias: seopiSzr3ppPyMeta?.alias || [],
    redirect: seopiSzr3ppPyMeta?.redirect || undefined,
    component: () => import("/var/www/hengstyle-frontend/pages/zonezone/seo.ts").then(m => m.default || m)
  }
]