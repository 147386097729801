import routerOptions0 from "/var/www/hengstyle-frontend/app/router.options.ts";
import routerOptions1 from "/var/www/hengstyle-frontend/node_modules/nuxt/dist/pages/runtime/router.options";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions1,
...routerOptions0,
}