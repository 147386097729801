import { defineStore } from 'pinia'
import { ArticleApi } from '@/utils/api'
import type {
  ArticleCategoriesParams,
  ArticleCategory,
  ArticleBrand,
  ArticleNews,
  ArticleRecommend,
  ArticleBlog,
  IArticleFaq,
  IFaqFilter,
  IBlogFilter,
  Meta,
  IArticleFilter,
  IArticle,
  IArticleRecipeFilter,
  IArticleRecipe,
  IArticleTopicFilter,
  IArticleTopic
} from '@/utils/types'
import type { IArticleEcoResult, IArticleCategory } from '@/api/article'
const api = new ArticleApi()

interface StoreState {
  categoryClass: Array<string>
  brandCategories: Array<ArticleCategory>
  newsCategories: Array<ArticleCategory>
  projectCategories: Array<ArticleCategory>
  blogCategories: Array<ArticleCategory>
  faqCategories: Array<ArticleCategory>
  ecoCategories: IArticleCategory[]
  currentNewsCategory: ArticleCategory | null
  currentBlogCategory: ArticleCategory | null
  brands: Array<ArticleBrand>
  brand: ArticleBrand | null
  newsList: Array<ArticleNews>
  newsTops: Array<ArticleNews>
  news: ArticleNews | null
  blogs: Array<ArticleBlog>
  blogTops: Array<ArticleBlog>
  blog: ArticleBlog | null
  faqs: Array<IArticleFaq>
  faq: IArticleFaq
  hence: IArticle
  recipe: IArticleRecipe
  topic: IArticleTopic
  recommends: Array<ArticleRecommend>
  recipeCategories: Array<ArticleCategory>
  articleEcoResults: Array<IArticleEcoResult>
  pageMeta: Meta
}

export const useArticleStore = defineStore('articleStore', {
  state: (): StoreState => {
    return {
      categoryClass: [
        'articleProject', // (專題文章)
        'articleBlog', // (品牌部落格)
        'articleBrand', // (代理品牌)
        'articleNews', // (最新消息)
        'articleEcolife', // (永續生活)
        'articleFaq', // (常見問答)
        'articleReport', // (專題報導)
        'articleMagazine' // (歷期雜誌)
      ],
      projectCategories: [],
      brandCategories: [],
      newsCategories: [],
      blogCategories: [],
      faqCategories: [],
      ecoCategories: [],
      currentNewsCategory: null,
      currentBlogCategory: null,
      brands: [],
      brand: null,
      newsList: [],
      newsTops: [],
      news: null,
      blogs: [],
      blogTops: [],
      blog: null,
      faqs: [],
      faq: {} as IArticleFaq,
      hence: {} as IArticle,
      recipe: {} as IArticleRecipe,
      topic: {} as IArticleTopic,
      recommends: [],
      recipeCategories: [],
      articleEcoResults: [],
      pageMeta: {} as Meta
    }
  },
  actions: {
    async fetchArticleCategories(type: 'brandCategories' | 'newsCategories' | 'blogCategories' | 'faqCategories' | 'projectCategories', params: ArticleCategoriesParams) {
      try {
        let categories = []
        if (type) {
          categories = this[type]
        }
        if (categories.length === 0) {
          const res = await api.getArticleCategories(params)
          const data = res.data
          this[type] = data
        }
      } catch (error) {
        console.error(`取得${type}類別失敗:`, error)
      }
    },
    async fetchArticleBrands(categoryId?: string) {
      const res = await api.getArticleBrands(categoryId)
      const data = res.data
      this.brands = data
    },
    async fetchArticleBrand(brandId: string) {
      try {
        const res = await api.getArticleBrand(brandId)
        const data = res.data
        this.brand = data
        return data
      } catch (error) {
        console.error('取得品牌內頁失敗:', error)
      }
    },
    async fetchArticleNewsList(categoryId?: string, page: number = 1) {
      const res = await api.getArticleNewsList(categoryId, page)
      const data = res.data
      this.newsList = data.list.data
      this.newsTops = data.top
      this.pageMeta = data.list.meta
    },
    async fetchArticleNews(newsId: string) {
      const res = await api.getArticleNews(newsId)
      const data = res.data
      this.news = data
    },
    async fetchArticleBlogs(f: IBlogFilter) {
      try {
        const res = await api.getArticleBlogs(f)
        const data = res.data
        this.blogs = data.list.data
        this.blogTops = data.top
        this.pageMeta = data.list.meta
        return res
      } catch (error) {
        console.error('取得品牌部落格列表失敗:', error)
      }
    },
    async fetchArticleBlog(blogId: string) {
      try {
        const res = await api.getArticleBlog(blogId)
        this.blog = res.data
      } catch (error) {
        console.error('取得品牌部落格文章失敗:', error)
      }
    },
    async fetchArticleFaqs(f: IFaqFilter) {
      try {
        const res = await api.getArticleFaqs(f)
        this.faqs = res.data
        this.pageMeta = res.meta
      } catch (error) {
        console.log('取得FAQ列表失敗:', error)
      }
    },
    async fetchArticleFaq(id: string) {
      try {
        const res = await api.getArticleFaq(id)
        const data = res.data
        this.faq = data
      } catch (error) {
        console.log('取得FAQ內頁失敗:', error)
      }
    },
    async fetchArticleRecommends(articleSlug: string) {
      try {
        const res = await api.getArticleRecommends(articleSlug)
        this.recommends = res.data
      } catch (error) {
        console.error(`取得${articleSlug}推薦失敗:`, error)
      }
    },
    async fetchArticles(f: IArticleFilter) {
      try {
        const res = await api.getArticles(f)
        return res
      } catch (error) {
        console.log('取得專題文章列表失敗:', error)
      }
    },
    async fetchArticle(id: string) {
      try {
        const res = await api.getArticle(id)
        this.hence = res.data
        return res
      } catch (error) {
        console.log('取得專題文章內頁失敗:', error)
      }
    },
    async fetchArticleRecipes(f: IArticleRecipeFilter) {
      try {
        const res = await api.getArticleRecipes(f)
        return res
      } catch (error) {
        console.log('取得恆食堂文章列表失敗:', error)
      }
    },
    async fetchArticleRecipe(id: string) {
      try {
        const res = await api.getArticleRecipe(id)
        this.recipe = res.data
        return res
      } catch (error) {
        console.log('取得恆食堂文章內頁失敗:', error)
      }
    },
    async fetchArticleTopics(f: IArticleTopicFilter) {
      try {
        const res = await api.getArticleTopics(f)
        return res
      } catch (error) {
        console.log('取得專題報導文章列表失敗:', error)
      }
    },
    async fetchArticleTopic(id: string) {
      try {
        const res = await api.getArticleRecipe(id)
        this.topic = res.data
        return res
      } catch (error) {
        console.log('取得專題報導文章內頁失敗:', error)
      }
    },
    async fetchArticleMagazines() {
      try {
        const res = await api.getArticleMagazines()
        return res
      } catch (error) {
        console.log('取得歷期雜誌列表失敗:', error)
      }
    },
    async fetchRecipeItemCategories() {
      if (this.recipeCategories.length === 0) {
        try {
          const res = await api.getRecipeItemCategories()
          this.recipeCategories = res.data
          return res.data
        } catch (error) {
          console.log('取得恆食堂分類選單失敗:', error)
        }
      }
    },

    setCurrentNewsCategory(category: ArticleCategory | null) {
      this.currentNewsCategory = category
    },
    setCurrentBlogCategory(category: ArticleCategory | null) {
      this.currentBlogCategory = category
    },
    setArticleEcoResults(data: IArticleEcoResult[]) {
      this.articleEcoResults = data
    },
    setArticleCategories(type: string, data: IArticleCategory[]) {
      if (type === 'eco') {
        this.ecoCategories = data
      }
    }
  },
  getters: {
    getPageMeta: (state): Meta | null => state.pageMeta,
    getArticleCategoryClass: (state): Array<string> => state.categoryClass,
    getNewsCategories: (state): Array<ArticleCategory> => state.newsCategories,
    getCurrentNewsCategory: (state): ArticleCategory | null => state.currentNewsCategory,
    getCurrentBlogCategory: (state): ArticleCategory | null => state.currentBlogCategory,
    getBrands: (state): Array<ArticleBrand> => state.brands,
    getNewsList: (state): Array<ArticleNews> => state.newsList,
    getNewsTops: (state): Array<ArticleNews> => state.newsTops,
    getNews: (state): ArticleNews | null => state.news
  }
})
