export default defineNuxtPlugin(async nuxtApp => {
  if (process.client) {
    window.addEventListener('pageshow', event => {
      // If persisted then it's in the page cache, force a reload of the page.
      if (event.persisted) {
        window.location.reload()
      }
    })
  }
})
