import Http from '../http'
import { type ILoactionFilter } from '../types'

export class LocationApi {
  public getLocationCategories() {
    const queryParams = { page: 1, per_page: 20 }

    return Http.get('/location_categories', queryParams)
  }

  public getLocationClasses() {
    const queryParams = { page: 1, per_page: 20 }

    return Http.get('/location_classes', queryParams)
  }

  public getLocations(params = { page: 1, per_page: 200 } as ILoactionFilter) {
    return Http.get('/locations', params)
  }

  public getLocation(key: string) {
    return Http.get(`/locations/${key}`)
  }
}
