import Http from '@/utils/http'
import type { ArticleCategoriesParams, IArticleFilter, IBlogFilter, IFaqFilter, IArticleRecipeFilter, IArticleTopicFilter } from '../types'

export class ArticleApi {
  public getArticleCategories(params: ArticleCategoriesParams) {
    return Http.get(`/article_categories`, {
      class: params.className
    })
  }

  public getArticleBrands(categoryId?: string) {
    const queryParams = { category_id: categoryId, page: 1, per_page: 100 }

    return Http.get('/article_brands', queryParams)
  }

  public getArticleBrand(id: string) {
    return Http.get(`/article_brands/${id}`)
  }

  public getArticleNewsList(categoryId?: string, page?: number) {
    const queryParams = { category_id: categoryId, page: page, per_page: 5 }

    return Http.get('/article_news', queryParams)
  }

  public getArticleNews(id: string) {
    return Http.get(`/article_news/${id}`)
  }

  public getArticleRecommends(articleSlug?: string) {
    return Http.get(`/article_recommends/${articleSlug}`)
  }

  public getArticleBlogs(filter: IBlogFilter) {
    const queryParams = { per_page: 12, ...filter }

    return Http.get('/article_blogs', queryParams)
  }

  public getArticleBlog(id: string) {
    return Http.get(`/article_blogs/${id}`)
  }

  public getArticleFaqs(f: IFaqFilter) {
    const queryParams = {
      ...f,
      per_page: 12
    }

    return Http.get('/article_faqs', queryParams)
  }

  public getArticleAuthor(id: string) {
    return Http.get(`/article_authors/${id}`)
  }

  public getArticleFaq(id: string) {
    return Http.get(`/article_faqs/${id}`)
  }

  // 取得專題文章列表(hence, 雜誌)
  public getArticles(params = {} as IArticleFilter) {
    return Http.get(`/articles`, params)
  }

  // 取得專題文章(hence, 雜誌)
  public getArticle(id: string) {
    return Http.get(`/articles/${id}`)
  }

  // 取得恆食堂文章列表
  public getArticleRecipes(params = {} as IArticleRecipeFilter) {
    return Http.get(`/article_recipes`, params)
  }

  // 取得恆食堂文章
  public getArticleRecipe(id: string) {
    return Http.get(`/article_recipes/${id}`)
  }

  // 取得專題報導文章列表
  public getArticleTopics(params = {} as IArticleTopicFilter) {
    return Http.get(`/article_reports`, params)
  }

  // 取得專題報導文章
  public getArticleTopic(id: string) {
    return Http.get(`/article_reports/${id}`)
  }

  // 取得歷期雜誌列表
  public getArticleMagazines(params = {}) {
    return Http.get(`/article_magazines`, params)
  }

  // 取得恆食堂分類選單
  public getRecipeItemCategories() {
    return Http.get(`/recipe_item_categories`)
  }
}
