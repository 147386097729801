import { defineStore } from 'pinia'
import { PageApi } from '@/utils/api'
import type {
  SEO,
  IZonezonePage,
  IHomePage,
  IBannerMedia,
  IInfoFooterBlock,
  IInfoBlock,
  IHomeEvent,
  IInfoEventBlock,
  IInfoEcolifeBlock,
  IInfoServiceBlock,
  IHomeHence,
  IHomeArticle,
  IHomeHenceCalendar,
  IHomeHenceCard,
  IHomeHenceLifestyle
} from '@/utils/types'
const pageApi = new PageApi()

interface StoreState {
  isLoadData: boolean
  zonezone?: IZonezonePage
  home: IHomePage
  hence: IHomeHence
}

export const usePageStore = defineStore('homeStore', {
  state: (): StoreState => {
    return {
      isLoadData: false,
      zonezone: undefined,
      home: {} as IHomePage,
      hence: {} as IHomeHence
    }
  },
  actions: {
    async fetchGetHomepage() {
      try {
        const res = await pageApi.getHomepage()
        this.isLoadData = true
        this.home = res.data
        return res.data
      } catch (error) {
        console.error('取得homepage資料失敗:', error)
      }
    },
    async fetchGetZonezone() {
      try {
        const res = await pageApi.getZonezonePage()
        this.isLoadData = true
        this.zonezone = res.data

        return res.data
      } catch (error) {
        console.error('取得zonezone資料失敗:', error)
      }
    },
    async fetchGetHence() {
      try {
        const res = await pageApi.getHencePage()
        this.isLoadData = true
        this.hence = res.data

        return res.data
      } catch (error) {
        console.error('取得Hence資料失敗:', error)
      }
    }
  },
  getters: {
    getIsLoadData: (state): boolean => state.isLoadData,
    pcBanner: (state): Array<IBannerMedia> => state.home.pc_banner,
    mobileBanner: (state): Array<IBannerMedia> => state.home.mobile_banner,
    homeSection1: (state): Array<IInfoBlock> => state.home.infos.first_block,
    homeSection2: (state): IInfoEventBlock => state.home.infos.event_block,
    homeSection3: (state): IInfoEcolifeBlock => state.home.infos.ecolife_block,
    homeSection4: (state): IInfoServiceBlock => state.home.infos.services_block,
    homeHenceArticles: (state): Array<IHomeArticle> => state.home.articles,
    homeEvents: (state): Array<IHomeEvent> => state.home.events,
    footer: (state): IInfoFooterBlock => state.home.infos.footer_block,
    homeSeo: (state): SEO => state.home.seo,
    henceRecipe: (
      state
    ): {
      recipe: IHomeArticle[]
      calendar: IHomeHenceCalendar
    } => {
      return {
        recipe: state.hence.recipe,
        calendar: state.hence.solar_term_calendar
      }
    },
    henceBanners: (
      state
    ): Array<{
      id: string
      image: string
      category: string
      title: string
    }> => {
      return state.hence.banners?.map(item => {
        return {
          id: item.id,
          image: item.image?.link ?? '',
          category: item.categories.length > 0 ? item.categories[0].subject : '',
          title: item.subject
        }
      })
    },
    henceMustReads: (state): Array<IHomeHenceCard> => {
      return state.hence.must_reads?.map(item => {
        return {
          image: item.image,
          category: item.categories.length > 0 ? item.categories[0].subject : '',
          title: item.subject,
          link: `/hence/article/${item.id}`
        } as IHomeHenceCard
      })
    },
    henceArticles: (state): Array<IHomeHenceCard> => {
      return state.hence.articles?.map(item => {
        return {
          image: item.image,
          category: item.categories.length > 0 ? item.categories[0].subject : '',
          title: item.subject,
          link: `/hence/article/${item.id}`
        } as IHomeHenceCard
      })
    },
    henceLifestyles: (state): Array<IHomeHenceLifestyle> => state.hence.lifestyle
  }
})
